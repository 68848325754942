import { Row, Col, Card, Button, Input, Form, Skeleton, Divider } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Link } from "react-router-dom";
import languages from "../../config/language";
import SingleImageUpload from "../../components/SingleImageUpload";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

function Index() {

  const sectionName = "Language Manager";
  const routeName   = "language";

  const api = {
    addEdit     :   apiPath.addEditLanguage,
    view        :   apiPath.viewLanguage,
  };

  const [form]                  =   Form.useForm();
  const { request }             =   useRequest();
  const [loading, setLoading]   =   useState(false);
  const [image, setImage]       =   useState([]);
  const FileType                =   ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/svg", "image/gif"]

  const handleImage = (data) =>{
    setImage(data);
  }

  const fetchData = () => {
    setLoading(true);
    request({
      url: api.view,
      method: 'GET',
      onSuccess: async (response) => {
        const data = response.data;
        form.setFieldsValue(data);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onUpdate = (values) => {
    const payload = {
      ...values,
    };
    
    setLoading(true);
    request({
      url: api.addEdit,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, data.status ? Severty.SUCCESS : Severty.ERROR);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card title={sectionName}>
      {loading ? ([1, 2, 3, 4].map(item => <Skeleton active key={item} />)) : (
        <Form
          className="edit-page-wrap"
          form={form}
          onFinish={onUpdate}
          autoComplete="off"
          layout="vertical"
          name="app_home_form"
        >
        
          <Row gutter={[24, 0]}>
            
            <Form.List name="language_data">
              {(fields, { add, remove }) => (
                <>
                  <Row gutter={16}>
                    
                    {fields.map((field, index) => (
                      
                      <Col span={24} xs={24} sm={24} md={24}>
                        
                        <div key={field.key} align="baseline" className="gap-cls" style={{ display: 'flex' }}>
                            
                          <Col span={20} xs={20} sm={20} md={4}> 
                            <Form.Item
                              className="qty-cls"
                              name={[field.name, 'name']}
                              rules={[{ required: true, message: `Please enter name` }]}
                            >
                              <Input autoComplete="off" placeholder={`Enter Name`} />
                            </Form.Item>
                          </Col>

                          <Col span={20} xs={20} sm={20} md={4}> 
                            <Form.Item
                              className="qty-cls"
                              name={[field.name, 'language_name']}
                              rules={[{ required: true, message: `Please enter name` }]}
                            >
                              <Input autoComplete="off" placeholder={`Language Name`} />
                            </Form.Item>
                          </Col>

                          <Col span={20} xs={20} sm={20} md={3}> 
                            <Form.Item
                              className="qty-cls"
                              name={[field.name, 'language_code']}
                              rules={[{ required: true, message: `Please enter code` }]}
                            >
                              <Input autoComplete="off" placeholder={`Language Code`} onInput={(e) => {
                                e.target.value = e.target.value.toLowerCase();
                              }} />
                            </Form.Item>
                          </Col>

                          <Col span={20} xs={20} sm={20} md={4}> 
                            <Form.Item
                              className="qty-cls"
                              name={[field.name, 'color_code']}
                              rules={[{ required: true, message: `Please select color code` }]}
                            >
                              <Input type="color" autoComplete="off" placeholder={`Selectt Color Code`} />
                            </Form.Item>
                          </Col>

                          <Col span={24} xs={24} sm={24} md={4}>
                            <SingleImageUpload
                                value={image}
                                fileType={FileType}
                                imageType={'language'}
                                btnName={'Image'}
                                onChange={(data) => handleImage(data)}
                            />
                          </Col>

                          <Col span={12} xs={12} sm={12} md={2}> 
                            {index > 0 && (
                              <div className="minus-wrap" style={{ marginTop: '-10px', marginLeft: "10px" }}>
                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: '8px' }} />
                              </div>
                            )}
                          </Col>

                        </div>
                      </Col>
                    ))}

                  </Row>

                  <Col span={6}>
                    <Form.Item className="mt-2" style={{ marginLeft: '-3px', width: '60%' }}>
                      <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
         
          </Row>
      
          <Form.Item className="btn-row float-right">
            <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}>
              <i className="fas fa-arrow-left mr-5"></i> Back
            </Link>

            <Button type="primary" loading={loading} htmlType="submit">
              <i className="fas fa-check mr-5"></i> Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
}

export default Index;
