import { Row, Col, Card, Button, Skeleton, Avatar, Image, Tooltip, Table, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from 'moment';

function View() {

  const sectionName = "User";
  const routeName = "user";

  const params = useParams();
  const location = useLocation();
  const { request } = useRequest();
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = (id, otpVerification) => {
    setLoading(true);
    request({
      url: apiPath.viewUser + "/" + id + `?otp_verification=${otpVerification}`,
      method: 'GET',
      onSuccess: (response) => {
        setLoading(false);
        setList(response.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    const searchParams    = new URLSearchParams(location.search);
    const otpVerification = searchParams.get('otp_verification');
    
    fetchData(params.id, otpVerification)
  }, [])

  return (
    <>
      <Row gutter={16}>
        <Col span={12} xs={12} md={12}>
          <Card title={sectionName + " Details"}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                
                <div className="view-inner-cls">
                  <h5>Balance:</h5>
                  <h6>{list?.balance ?? '0.00'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Image:</h5>
                  <h6>
                    {list && !list?.image && list?.image == undefined ?
                      <Avatar className="cap" size={50}> {list?.name?.charAt(0)} </Avatar>
                      :
                      list?.image ?
                      <Image className="image-radius" src={apiPath.assetURL + list?.image} />
                      : 'Image Not Available'
                    }
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Name:</h5>
                  <h6><span className="cap">{list?.name ?? '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Email Address:</h5>
                  <h6>{list?.email ?? '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Phone Number:</h5>
                  <h6>
                    {list?.country_code ? `+${list.country_code}-` : '-'} 
                    {list?.mobile_number ? list.mobile_number : '-'}
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Location:</h5>
                  <h6 className="cap">
                    {list && list?.location?.address ? (
                      <>
                        <i className="fas fa-map-marker-alt text-danger"></i>{' '}
                        <Link target="_blank" to={`http://maps.google.co.uk/maps?q=${list.location?.latitude},${list.location?.longitude}`}>
                          {list.location?.address}
                        </Link>
                      </>
                    ) : (
                      'Location Not Available'
                    )}
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Country:</h5>
                  <h6>{list?.country_id?.name ?? '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>State:</h5>
                  <h6>{list?.state_id?.en_name ?? '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>City:</h5>
                  <h6>{list?.city_id?.en_name ?? '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Referral Code:</h5>
                  <h6>{list?.referral_code ?? '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{list?.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>OTP Verification:</h5>
                  <h6>{list?.is_otp_verify ? <Badge status="success" text="Verified" /> : <Badge status="error" text="Not Verified" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Approval:</h5>
                  <h6>{list?.is_approve ? <Badge status="success" text="Approved" /> : <Badge status="error" text="Rejected" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Approve / Reject On:</h5>
                  <h6>{list?.approve_reject_on ? moment(list?.approve_reject_on).format('DD-MMM-YYYY HH:mm A') : '-'}</h6>
                </div>
                
                <div className="view-inner-cls">
                  <h5>Reject Reason:</h5>
                  <h6>{list?.reject_reason ?? '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Registered On:</h5>
                  <h6>{list?.created_at ? moment(list?.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-primary" to={`/${routeName}`}>Back</Link>
                </div>

              </div>
            }

          </Card>
        </Col>
      </Row>
    </>
  );
}


export default View;
