import {Row, Col, Card, Button, Input, Form, Divider, Tabs, Select, Space, Skeleton} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import SingleImageUpload from "../../components/SingleImageUpload";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import DescriptionEditor from '../../components/DescriptionEditor'
import MultipleImageUpload from "../../components/MultipleImageUpload";
import languages from "../../config/language";
import { Link, useParams } from "react-router-dom";
import LocationMap from "../../components/LocationMap";
import { CategoryType, DefaultLatLng, INDIACOUNTRY } from "../../constants/conts";
const { Option } = Select;
const { TabPane } = Tabs;

function Edit() {

    const sectionName   =   "City";
    const routeName     =   "city";

    const api = {
        addEdit   :  apiPath.addEditCity,
        state     :  apiPath.commonState,
        edit      :  apiPath.editCity,
    }
    
    const [form]                            =   Form.useForm();
    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const params                            =   useParams();
    const navigate                          =   useNavigate();
    const [image, setImage]                 =   useState([]);
    const [latLong, setlatLong]             =   useState(DefaultLatLng);
    const [location, setLocation]           =   useState();
    const [stateList, setStateList]         =   useState([]);
    const [multipleImage, setMultipleImage] =   useState([]);
    const [cityData, setCityData]           =   useState();
    
    const FileType  =   ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/svg", "image/gif"]

    const handleMultipleImage = (data) =>{
        setMultipleImage(data);
    }

    const handleMultipleDeleteImage = (data) =>{
        setMultipleImage(prevFileList => prevFileList.filter(item => item.url !== data));
    }
  
    const handleLocationChange = (value) => {
        setLocation(value.address);
        setlatLong({ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) });
    }

    const getStateList = () => {
        request({
          url: api.state,
          method: 'POST',
          onSuccess: (response) => {
            setStateList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const onUpdate = (values) => {
        let payload = {
            ...values,
            images: multipleImage ? [...multipleImage?.map((item) => item.url)] : [],
        };

        setLoading(true)
        request({
            url: api.addEdit + "/" + params.id,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    const fetchData = (id) => {
        request({
            url: api.edit + "/" + id,
            method: 'GET',
            onSuccess: (response) => {
                const { data } = response;

                form.setFieldsValue({...data});

                setLoading(false);
                setCityData(data);
                setlatLong({ lat: parseFloat(data.location.latitude), lng: parseFloat(data.location.longitude) });
                setLocation(data.location);
                setMultipleImage(data.images && data.images.length > 0 && data.images.map(item => ({ url: item })))
            },
            onError: (error) => {
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    useEffect(() => {
        fetchData(params.id);
        setLoading(true)
    }, [])

    useEffect(() => {
        getStateList();
    }, [])
    
    return (
        <Card title={"Add " + sectionName}>
            {loading ? [1,2,3,4,5,6,7,8].map(item => <Skeleton active key={item} />) :
                <Form initialValues={{ country_id : INDIACOUNTRY }} className="edit-page-wrap" form={form} onFinish={onUpdate} autoComplete="off" layout="verticle" name="blog_form">
                    
                    <Row gutter={[24, 0]}>

                        <Col span={24} xs={24} sm={24} md={10}>
                            <Form.Item label="Upload Image (Multiple)" name="image" className="mb-1">
                                <div className="mb-1"></div>
                                    <MultipleImageUpload data={multipleImage && multipleImage.length > 0 ? [...multipleImage.map((item) => item.url)] : []} fileType={FileType} imageType={'city'} btnName={'Image'} onDelete={(data) => handleMultipleDeleteImage(data)} onChange={(data)=> handleMultipleImage(data)} />
                            </Form.Item>
                        </Col>

                        <Col span={24} xs={24} sm={24} md={14}>
                            <Form.Item name="location" label="Location (Google)" rules={[
                                { required: true, message: 'Please the location' },
                            ]}>
                                <LocationMap onChange={handleLocationChange} userData={cityData && cityData} type={'City'} />
                            </Form.Item>
                        </Col>

                        <Col span={12} xs={12} sm={12} md={8}>
                            <Form.Item className="banner-elt"
                                name="state_id"
                                label="Select State"
                                rules={[{ required: true, message: 'Missing State Selection' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select State"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {stateList && stateList.length > 0 ? stateList.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            <span className="cap">{item.en_name}</span>
                                        </Option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Divider orientation="left" orientationMargin={15} className="devider-color">{"About City"}</Divider>

                        <Col md={24}>
                            <Tabs defaultActiveKey={languages[0].short}>
                                {languages.map(lang => (
                                    <TabPane tab={lang.long} key={lang.short}>
                                        <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                                            rules={[
                                                ...(lang.short === 'en' ? [
                                                    { required: true, message: `Please enter the name in ${lang.long}!` }
                                                ] : []),
                                                { max: 200, message: "Name should not contain more than 200 characters!" },
                                                { min: 2, message: "Name should contain at least 2 characters!" },
                                            ]}
                                        >
                                            <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>
                                        </Form.Item>
                                    
                                        <Form.Item label={`Description (${lang.long})`} name={`${lang.short}_description`}>
                                            <DescriptionEditor placeholder={`Enter Description in ${lang.long}`} />
                                        </Form.Item>
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>

                        <Divider orientation="left" orientationMargin={15} className="devider-color">{"YouTube URLs"}</Divider>

                        <Col span={24} xs={24} sm={24} md={24} style={{ marginLeft : "-8px" }}>
                            <Form.List name="youtube_url">
                                {(fields, { add, remove }) => (
                                    <>
                                        <Row gutter={16}>
                                            {fields.map((field, index) => (
                                                <Col span={24} xs={24} sm={24} md={24}>
                                                    <div key={field.key} align="baseline" className="gap-cls" style={{ display: 'flex' }}>
                                                        
                                                        <Col span={20} xs={20} sm={20} md={20}> 
                                                            <Form.Item
                                                                className="qty-cls"
                                                                name={[field.name]}
                                                                rules={[{ required: true, message: `Please enter URL ${index + 1}` }]}
                                                            >
                                                                <Input autoComplete="off" placeholder={`Enter YouTube URL ${index + 1}`} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={2} xs={2} sm={2} md={2}> 
                                                            {index > 0 && (
                                                                <div className="minus-wrap" style={{ marginTop: '-10px', marginLeft: "10px" }}>
                                                                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: '8px' }} />
                                                                </div>
                                                            )}
                                                        </Col>

                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>

                                        <Col span={6}>
                                            <Form.Item className="mt-2" style={{ marginLeft: '-3px', width: '60%' }}>
                                            <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Form.List>
                        </Col>

                        <Divider orientation="left" orientationMargin={15} className="devider-color">{"FAQs"}</Divider>

                        <Col span={24} xs={24} sm={24} md={24} style={{ marginLeft : "-8px" }}>
                            <Form.List name="faq">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Space align="baseline" className="gap-cls">
                                                <Row>
                                                    <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                        {`Question - ${index + 1}`}
                                                    </Divider>

                                                    {languages.map((lang) => (
                                                        <Col span={6} key={`${field.key}_${lang.short}`}>
                                                        <Form.Item
                                                            className="qty-cls"
                                                            name={[field.name, `${lang.short}_question`]}
                                                            label={`Question (${lang.long})`}
                                                            rules={[
                                                                ...(lang.short === 'en' ? [
                                                                    { required: true, message: `Please enter the question in ${lang.long}!` }
                                                                ] : [])
                                                            ]}
                                                        >
                                                            <Input autoComplete="off" placeholder={`Enter Question in ${lang.long}`} />
                                                        </Form.Item>
                                                        </Col>
                                                    ))}

                                                    <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                        {`Answer - ${index + 1}`}
                                                    </Divider>

                                                    {languages.map((lang) => (
                                                        <Col span={12} key={`${field.key}_${lang.short}`}>
                                                            <Form.Item
                                                                className="qty-cls"
                                                                name={[field.name, `${lang.short}_answer`]}
                                                                label={`Answer (${lang.long})`}
                                                                rules={[
                                                                    ...(lang.short === 'en' ? [
                                                                        { required: true, message: `Please enter the answer in ${lang.long}!` }
                                                                    ] : [])
                                                                ]}
                                                            >
                                                                <Input autoComplete="off" placeholder={`Enter Answer in ${lang.long}`} />
                                                            </Form.Item>
                                                        </Col>
                                                    ))}

                                                    {index > 0 && (
                                                        <div className="minus-wrap" style={{ marginBottom: '8px', marginLeft: '10px' }}>
                                                            <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: '8px' }} />
                                                        </div>
                                                    )}
                                                </Row>
                                            </Space>
                                        ))}

                                        <Col span={6}>
                                            <Form.Item className="mt-2" style={{ marginLeft: '-3px', width: '60%' }}>
                                            <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                            
                    <Form.Item className="btn-row float-right">
                        <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                    </Form.Item>
                </Form>
            }
        </Card>
    )
}

export default Edit;