import {Row, Col, Card, Button, Divider, Input, Form, Tabs, Select, Badge, Skeleton} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import languages from "../../config/language";
import { ShowToast, Severty } from "../../helper/toast";
const { TabPane } = Tabs;

function Edit() {

    const sectionName   =   "Subscription";
    const routeName     =   "subscription";

    const api = {
        addEdit   :  apiPath.addEditSubscription,
        view      :  apiPath.viewSubscription,
    }

    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const navigate                          =   useNavigate();
    const params                            =   useParams();
    const [form]                            =   Form.useForm();
    const [validationStatus, setValidationStatus]   =   useState({});


    const handleFieldChange = (data, lang, type) => {

        const name  =   form.getFieldValue(`${lang}_name`);
        setValidationStatus(prevStatus => ({
            ...prevStatus, 
            [lang]: !!name
        }));
    }

    const fetchData = (id) => {
        request({
            url: api.view + "/" + id,
            method: 'GET',
            onSuccess: (data) => {
                setLoading(false);
                
                const response = data.data;

                // Flatten nested fields if necessary
                const flattenedData = {
                    ...response,
                    notification: response.description.notification
                };

                form.setFieldsValue(flattenedData);

                // Initialize validationStatus based on fetched data
                const fetchedValidationStatus = {};
                
                languages.forEach(lang => {
                    const name                  =   form.getFieldValue(`${lang.short}_name`);
                    fetchedValidationStatus[lang.short] = !!name;
                });

                setValidationStatus(fetchedValidationStatus);
            },
            onError: (error) => {
                console.error(error);
            }
        })
    }
    
    useEffect(() => {
        const initialValidationStatus = {};
        
        languages.forEach(lang => {
            initialValidationStatus[lang.short] = !!form.getFieldValue(`${lang.short}_name`);
        });

        setValidationStatus(initialValidationStatus);
        
        fetchData(params.id);

        setLoading(true);
        
    }, [])

    const validateFields = async () => {
        try {
            await form.validateFields();

            const englishTitle          =   form.getFieldValue('en_name');

            if (!englishTitle) {
                return ShowToast("Please update english name", Severty.ERROR);
            }

            onUpdate();

        } catch (error) {
            console.error(error);
        }
    };

    const onUpdate = () => {
        
        let payload = {
            ...form.getFieldsValue(),
            description: {
                notification: form.getFieldValue('notification'),
                no_of_like: form.getFieldValue('no_of_like')
            }
        };

        // Remove the original fields that have been nested inside 'description'
        delete payload.notification;

        setLoading(true)
        request({
            url     : api.addEdit + "/" + params.id,
            method  : 'POST',
            data    : payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    return (
        <Card title={"Update " + sectionName + " Details"}>

            {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :

                <Form className="edit-page-wrap" form={form} autoComplete="off" layout="verticle" name="blog_form">
                    
                    <Row gutter={[24, 0]}>

                        <Col md={24}>
                            <Tabs defaultActiveKey={languages[0].short}>
                                {languages.map(lang => (
                                    
                                    <TabPane tab={<span>{lang.long} {validationStatus[lang.short] === false && <Badge dot color="red" />}</span>} key={lang.short}>
                                        
                                        <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                                            rules={[
                                                ...(lang.short === 'en' ? [
                                                    { required: true, message: `Please enter the name in ${lang.long}!` }
                                                ] : []),
                                                { max: 100, message: "Name should not contain more than 100 characters!" },
                                                { min: 2, message: "Name should contain at least 2 characters!" },
                                            ]}
                                            initialValue={form.getFieldValue(`${lang.short}_name`)}
                                            onChange={(data) => handleFieldChange(data, lang.short, 'title')}
                                        >

                                            <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>

                                        </Form.Item>

                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>

                        <Col span={12} xs={12} sm={12} md={12}>
                            <Form.Item name="type" label="Select Subscription Type" 
                                rules={[
                                    { required: true, message: 'Please seelct the type' },
                                ]}
                            >
                                <Select placeholder="Select Subscription Type">
                                    <option key={'Monthly'} value={'Monthly'}>Monthly</option>
                                    <option key={'Yearly'} value={'Yearly'}>Yearly</option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12} xs={12} sm={12} md={12}>
                            <Form.Item name="price" label="Price (INR)" 
                                rules={[
                                    { required: true, message: 'Please enter the price' },
                                    { max: 10, message: "Price should not contain more than 10 digits!" },
                                    { min: 1, message: "Price should contain at least 1 digits!" },
                                    { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" }
                                ]}
                            >
                                <Input autoComplete="off" placeholder={`Enter Price (INR)`}/>
                            </Form.Item>
                        </Col>

                        <Divider orientation="left" orientationMargin={15} className="devider-color">{"Subscription Detail"}</Divider>

                        <Col span={12} xs={12} sm={12} md={6}>
                            <Form.Item name="notification" label="Notification" 
                                rules={[
                                    { required: true, message: 'Please enter the notification' },
                                    { max: 10, message: "Notification should not contain more than 10 digits!" },
                                    { min: 1, message: "Notification should contain at least 1 digits!" },
                                    { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" }
                                ]}
                            >
                                <Input autoComplete="off" placeholder={`Enter Notification`}/>
                            </Form.Item>
                        </Col>
                        
                    </Row>

                    <Form.Item className="btn-row float-right">
                        <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                        <Button type="primary" loading={loading} onClick={validateFields}>Submit</Button>
                    </Form.Item>
                </Form>
            }

        </Card>
    )
}

export default Edit;