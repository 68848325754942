import {Row, Col, Card, Button, Input, Form, Divider, Space, Skeleton} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import DescriptionEditor from '../../components/DescriptionEditor'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import languages from "../../config/language";

function Edit() {

    const sectionName   =   "Content";
    const routeName     =   "content";
    
    const api = {
        addEdit     :  apiPath.addEditContent,
        view        :  apiPath.viewContent,
    }
    
    const params                        =   useParams();
    const [form]                        =   Form.useForm();
    const { request }                   =   useRequest()
    const [loading, setLoading]         =   useState(false)
    const [slug, setSlug]               =   useState("")
    const navigate                      =   useNavigate();

    const [editoEnValue, setEditoEnValue] =   useState('');
    const [editoHiValue, setEditoHiValue] =   useState('');
    const [editoMaValue, setEditoMaValue] =   useState('');
    const [editoMlValue, setEditoMlValue] =   useState('');
    const [editoGjValue, setEditoGjValue] =   useState('');
    const [editoBeValue, setEditoBeValue] =   useState('');
    const [editoTaValue, setEditoTaValue] =   useState('');
    const [editoKaValue, setEditoKaValue] =   useState('');

    const handleEditorChangeByLang = (data, lang) => {
        switch (lang) {
            case 'hi':
                setEditoHiValue(data);
                break;
            case 'gj':
                setEditoGjValue(data);
                break;
            case 'ma':
                setEditoMaValue(data);
                break;
            case 'ml':
                setEditoMlValue(data);
                break;
            case 'ka':
                setEditoKaValue(data);
                break;
            case 'ta':
                setEditoTaValue(data);
                break;
            case 'be':
                setEditoBeValue(data);
                break;
            default:
                setEditoEnValue(data);
                break;
        }
    }


    const OnUpdate = (values) => {
        
        let payload = { ...values };
        
        if(slug != 'faq') {
            if (editoEnValue.trim() === '<p></p>'   || editoEnValue.trim() === "") return ShowToast('Please Enter Description in English', Severty.ERROR)
            
            languages.forEach(lang => {
                const titleKey = `${lang.short}_title`;
                const descriptionKey = `${lang.short}_description`;
    
                const tempPayload = {
                    ...values,
                    [`${lang.short}_title`]: values[titleKey] ? values[titleKey] : values.title,
                    [`${lang.short}_description`]: values[descriptionKey] ? values[descriptionKey] : editoEnValue
                };
                payload     =   tempPayload;
            });
        }

        setLoading(true)
        request({
            url: api.addEdit + "/" + params.slug,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    const fetchData = (slug) => {
        request({
            url: api.view + "/" + slug,
            method: 'GET',
                onSuccess: (data) => {
                setLoading(false);
                form.setFieldsValue(data.data)
                setSlug(data.data.slug);
                setEditoEnValue(data?.data?.en_description);
            },
            onError: (error) => {
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    useEffect(() => {
        fetchData(params.slug);
        setLoading(true)
    }, [])

    return (
        <Card title={"Update " + sectionName}>

            {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :

                <Form className="edit-page-wrap" form={form} onFinish={OnUpdate} autoComplete="off" layout="verticle" name="blog_form">
                    
                    <Row gutter={[24, 0]}>

                        {
                            slug !== 'faq' ?
                                <>
                                    {languages.map(lang => (
                                        <>
                                            <Divider orientation="left" orientationMargin={15} className="devider-color">{lang.long}</Divider>
            
                                            <Col md={24}>
                                                <Form.Item label={`Title`} name={`${lang.short}_title`} 
                                                    rules={[
                                                        ...(lang.short === 'en' ? [
                                                            { required: true, message: `Please enter the name in ${lang.long}!` }
                                                        ] : []),
                                                        { max: 100, message: "Title should not contain more than 100 characters!" },
                                                        { min: 2, message: "Title should contain at least 2 characters!" },
                                                    ]}
                                                >
                                                    <Input autoComplete="off" placeholder={`Enter title in ${lang.long}`}/>
                                                </Form.Item>
                                            </Col>
            
                                            <Col md={24}>
                                                <Form.Item label={`Description`} name={`${lang.short}_description`}>
                                                    <DescriptionEditor value={editoEnValue} placeholder={`Enter Description in ${lang.long}`} onChange={(data)=> handleEditorChangeByLang(data, lang.short)} />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    ))}
                                </>
                            :
                            <Col span={24} xs={24} sm={24} md={24} style={{ marginLeft : "-8px" }}>
                                <Form.List name="faq">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Space align="baseline" className="gap-cls">
                                                    <Row>
                                                        <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                            {`Question - ${index + 1}`}
                                                        </Divider>

                                                        {languages.map((lang) => (
                                                            <Col span={12} key={`${field.key}_${lang.short}`}>
                                                                <Form.Item
                                                                    className="qty-cls"
                                                                    name={[field.name, `${lang.short}_question`]}
                                                                    label={`Question (${lang.long})`}
                                                                    rules={[
                                                                        ...(lang.short === 'en' ? [
                                                                            { required: true, message: `Please enter the question in ${lang.long}!` }
                                                                        ] : [])
                                                                    ]}
                                                                >
                                                                    <Input autoComplete="off" placeholder={`Enter Question in ${lang.long}`} />
                                                                </Form.Item>
                                                            </Col>
                                                        ))}

                                                        <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                            {`Answer - ${index + 1}`}
                                                        </Divider>

                                                        {languages.map((lang) => (
                                                            <Col span={12} key={`${field.key}_${lang.short}`}>
                                                                <Form.Item
                                                                    className="qty-cls"
                                                                    name={[field.name, `${lang.short}_answer`]}
                                                                    label={`Answer (${lang.long})`}
                                                                    rules={[
                                                                        ...(lang.short === 'en' ? [
                                                                            { required: true, message: `Please enter the answer in ${lang.long}!` }
                                                                        ] : [])
                                                                    ]}
                                                                >
                                                                    <Input autoComplete="off" placeholder={`Enter Answer in ${lang.long}`} />
                                                                </Form.Item>
                                                            </Col>
                                                        ))}

                                                        {index > 0 && (
                                                            <div className="minus-wrap" style={{ marginBottom: '8px', marginLeft: '10px' }}>
                                                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: '8px' }} />
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Space>
                                            ))}

                                            <Col span={6}>
                                                <Form.Item className="mt-2" style={{ marginLeft: '-3px', width: '60%' }}>
                                                <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        }
                    </Row>

                    <Form.Item className="btn-row float-right">
                        <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                    </Form.Item>
                </Form>
            }
        </Card>
    )
}

export default Edit;
