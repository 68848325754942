import { Row, Col, Card, Button, Input, Form, Switch, Skeleton, Select, Divider } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { CloseOutlined, CheckOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { APPHOME_SECTION } from "../../constants/conts";

const { Option } = Select;

function Index() {

  const sectionName = "App Home";
  const routeName   = "app-home";

  const api = {
    addEdit     :   apiPath.addEditAppHome,
    view        :   apiPath.viewAppHome,
    category    :   apiPath.commonCategory,
    activity    :   apiPath.commonActivity,
    locationInsights: apiPath.commonLocationInsights,
    state       :  apiPath.commonState,
    city        :   apiPath.commonCity,
    blog        :   apiPath.commonBlog,
  };

  const [form]                          =   Form.useForm();
  const { request }                     =   useRequest();
  const [loading, setLoading]           =   useState(false);
  const [stateList, setStateList]       =   useState([]);
  const [cityList, setCityList]         =   useState([]);
  const [blogList, setBlogList]         =   useState([]);
  const [insightsMap, setInsightsMap]   =   useState({});
  const [activityMap, setActivityMap]   =   useState({});
  const [locationCategoryList, setLocationCategoryList] = useState([]);
  const [activityCategoryList, setActivityCategoryList] = useState([]);

  const handleCategoryChange = async (id, type, rowIndex) => {
    form.setFieldsValue({
      [type === 'location' ? 'locationInsights' : 'activities']: {
        [rowIndex]: {
          ...form.getFieldValue(type === 'location' ? 'locationInsights' : 'activities')[rowIndex],
          id: [],
        },
      },
    });

    if (type === "location") {
      fetchLocationInsights(id, rowIndex); 
    } else {
      fetchActivity(id, rowIndex); 
    }
  };

  const fetchLocationInsights = async(category_id,rowIndex) => {   
   await request({
      url: api.locationInsights + "?category_id=" + category_id,
      method: 'POST',
      onSuccess: (response) => {
        setInsightsMap((prev) => ({
          ...prev,
          [rowIndex]: response.data.data,
        }));
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  }

  const fetchActivity = (category_id, rowIndex) => {
    request({
      url: api.activity + "?category_id=" + category_id,
      method: 'POST',
      onSuccess: (response) => {
        setActivityMap((prev) => ({
          ...prev,
          [rowIndex]: response.data.data,
        }));
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  }

  const fetchState = () => {
    request({
      url: api.state,
      method: 'POST',
      onSuccess: (response) => {
        setStateList(response.data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchBlog = () => {
    request({
      url: api.blog,
      method: 'POST',
      onSuccess: (response) => {
        setBlogList(response.data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchCity = () => {
    request({
      url: api.city,
      method: 'POST',
      onSuccess: (response) => {
        setCityList(response.data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  
  const onUpdate = (values) => {
    const payload = {
      ...values,
      blog: {
        ...values.blog,
        id          : values.blog_id || [],
        is_active   : values.blog_is_active ?? false,
        sequence_no : values.blog_sequence_no ?? 0,
        heading     : values.blog_heading ?? '',
      },
      city: {
        ...values.city,
        id          : values.city_id || [],
        is_active   : values.city_is_active ?? false,
        sequence_no : values.city_sequence_no ?? 0,
        heading     : values.city_heading ?? '',
      },
      location_insights: {
        is_active   : values.location_insights_is_active ?? false,
        sequence_no : values.location_insights_sequence_no ?? 0,
        add_on      : values.locationInsights || [],
      },
      activity: {
        is_active   : values.activity_is_active ?? false,
        sequence_no : values.activity_sequence_no ?? 0,
        add_on      : values.activities || [],
      },
      activity_category: {
        ...values.activity_category,
        id          : values.activity_category_id || [],
        is_active   : values.activity_category_is_active ?? false,
        sequence_no : values.activity_category_sequence_no ?? 0,
        heading     : values.activity_category_heading ?? '',
      },
      location_insights_category: {
        ...values.location_insights_category,
        id          : values.location_insights_category_id || [],
        is_active   : values.location_insights_category_is_active ?? false,
        sequence_no : values.location_insights_category_sequence_no ?? 0,
        heading     : values.location_insights_category_heading ?? '',
      },
      state: {
        ...values.state,
        id          : values.state_id || [],
        is_active   : values.state_is_active ?? false,
        sequence_no : values.state_sequence_no ?? 0,
        heading     : values.state_heading ?? '',
      },
      stay: {
        ...values.Stay,
        is_active   : values.stay_is_active ?? false,
        sequence_no : values.stay_sequence_no ?? 0,
        heading     : values.stay_heading ?? '',
      },
    };

    setLoading(true);
    request({
      url: api.addEdit,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, data.status ? Severty.SUCCESS : Severty.ERROR);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const fetchData = () => {
    setLoading(true);
    request({
      url: api.view,
      method: 'GET',
      onSuccess: async (response) => {
        const data = response.data;
  
        const formattedValues = {
          activity_is_active    : data.activity.is_active,
          activity_heading      : data.activity.heading || '',
          activity_sequence_no  : data.activity.sequence_no || 0,
          activities : data.activity.add_on.map(item => ({
            heading     : item.heading,
            category_id : item.category_id,
            id          : item.id || [],
          })),

          location_insights_is_active   : data.location_insights.is_active,
          location_insights_heading     : data.location_insights.heading || '',
          location_insights_sequence_no : data.location_insights.sequence_no || 0,
          locationInsights : data.location_insights.add_on.map(item => ({
            heading     : item.heading,
            category_id : item.category_id,
            id          : item.id || [],
          })),

          city_is_active    : data.city.is_active,
          city_id           : data.city.id,
          city_heading      : data.city.heading || '',
          city_sequence_no  : data.city.sequence_no || 0,

          state_is_active   : data.state.is_active,
          state_id          : data.state.id,
          state_heading     : data.state.heading || '',
          state_sequence_no : data.state.sequence_no || 0,

          activity_category_is_active     : data.activity_category.is_active,
          activity_category_id            : data.activity_category.id,
          activity_category_heading       : data.activity_category.heading || '',
          activity_category_sequence_no   : data.activity_category.sequence_no || 0,

          location_insights_category_is_active    : data.location_insights_category.is_active,
          location_insights_category_id           : data.location_insights_category.id,
          location_insights_category_heading      : data.location_insights_category.heading || '',
          location_insights_category_sequence_no  : data.location_insights_category.sequence_no || 0,

          blog_is_active    : data.blog.is_active,
          blog_id           : data.blog.id,
          blog_heading      : data.blog.heading || '',
          blog_sequence_no  : data.blog.sequence_no || 0,
          
          stay_is_active    : data.stay.is_active,
          stay_heading      : data.stay.heading || '',
          stay_sequence_no  : data.stay.sequence_no || 0,

        };
  
        form.setFieldsValue(formattedValues);
  
        await Promise.all(
          data.activity.add_on.map(async (item, index) => {
            fetchActivity(item.category_id, index);
          })
        );

        await Promise.all(
          data.location_insights.add_on.map(async (item, index) => {
            fetchLocationInsights(item.category_id, index);
          })
        );
  
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchLocationInsightsCategory = () => {
    setLoading(true);
    request({
      url: api.category + '?type=Location Insights',
      method: 'GET',
      onSuccess: (response) => {
        setLocationCategoryList(response.data.data);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchActivityCategory = () => {
    setLoading(true);
    request({
      url: api.category + '?type=Activity',
      method: 'GET',
      onSuccess: (response) => {
        setActivityCategoryList(response.data.data);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  
  useEffect(() => {
    fetchData();
    fetchState();
    fetchCity();
    fetchBlog();
    fetchActivityCategory();
    fetchLocationInsightsCategory();
  }, []);

  return (
    <Card title={sectionName}>
      {loading ? (
        [1, 2, 3, 4].map(item => <Skeleton active key={item} />)
      ) : (
        <Form
        className="edit-page-wrap"
        form={form}
        onFinish={onUpdate}
        autoComplete="off"
        layout="vertical"
        name="app_home_form"
      >
        <Row gutter={[24, 0]}>
          {APPHOME_SECTION.map(app => (
            <React.Fragment key={app.name}>
              <Divider orientation="left" orientationMargin={15} className="devider-color">
                {app.name}
              </Divider>
      
              <Col span={24} xs={24} sm={24} md={2}>
                <Form.Item label={`Active Status`} name={`${app.section}_is_active`} valuePropName="checked">
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    loading={loading}
                    className={form.getFieldValue(`${app.section}_is_active`) ? "custom-switch-checked" : ""}
                  />
                </Form.Item>
              </Col>

              <Col span={24} md={4}>
                <Form.Item
                  label="Sequence No"
                  name={`${app.section}_sequence_no`}
                  rules={[{ required: true, message: `Please enter the sequence!` }]}
                >
                  <Input autoComplete="off" placeholder={`Enter Sequence No.`} />
                </Form.Item>
              </Col>
      
              {app.section === "location_insights" && (
                <Col span={24}>
                  <Form.List name="locationInsights">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name: rowIndex }) => (
                          <Row gutter={24} key={key}>

                            <Col span={24} md={8}>
                              <Form.Item
                                label="Heading"
                                name={[rowIndex, 'heading']}
                                rules={[{ required: true, message: `Please enter the heading!` }]}
                              >
                                <Input autoComplete="off" placeholder={`Enter Heading`} />
                              </Form.Item>
                            </Col>
      
                            <Col span={24} md={8}>
                              <Form.Item
                                label="Select Category"
                                name={[rowIndex, 'category_id']}
                                rules={[{ required: true, message: "Please select the category!" }]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Select Category"
                                  onChange={(id) => {handleCategoryChange(id,'location',rowIndex);}}
                                  filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                >
                                  {locationCategoryList.map(item => (
                                    <Option key={item._id} value={item._id}>
                                      {item.en_name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
      
                            <Col span={24} md={20}>
                              <Form.Item
                                label="Select Location Insights (Multiple)"
                                name={[rowIndex, 'id']}
                                rules={[{ required: true, message: "Please select the location insights!" }]}
                              >
                                <Select
                                  mode="multiple"
                                  showSearch
                                  placeholder="Select Location Insights"
                                  filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                >
                                  {(insightsMap[rowIndex] || []).map(item => (
                                    <Option key={item._id} value={item._id}>
                                      {item.en_name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
      
                            <Col span={1}>
                              <MinusCircleOutlined onClick={() => remove(rowIndex)} style={{ marginTop: '5.2px' }} />
                            </Col>
                          </Row>
                        ))}
      
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Location Insight
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              )}
      
              {app.section === "activity" && (
                <Col span={24}>
                  <Form.List name="activities">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name: rowIndex }) => (
                          <Row gutter={24} key={key}>

                            <Col span={24} md={8}>
                              <Form.Item
                                label="Heading"
                                name={[rowIndex, 'heading']}
                                rules={[{ required: true, message: `Please enter the heading!` }]}
                              >
                                <Input autoComplete="off" placeholder={`Enter Heading`} />
                              </Form.Item>
                            </Col>
      
                            <Col span={24} md={8}>
                              <Form.Item
                                label="Select Category"
                                name={[rowIndex, 'category_id']}
                                rules={[{ required: true, message: "Please select the category!" }]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Select Category"
                                  onChange={(id) => handleCategoryChange(id, "activity", rowIndex)}
                                  filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                >
                                  {activityCategoryList.map(item => (
                                    <Option key={item._id} value={item._id}>
                                      {item.en_name}
                                    </Option>
                                  ))}

                                </Select>
                              </Form.Item>
                            </Col>
      
                            <Col span={24} md={20}>
                              <Form.Item
                                label="Select Activities (Multiple)"
                                name={[rowIndex, 'id']}
                                rules={[{ required: true, message: "Please select the Activity!" }]}
                              >
                                <Select
                                  mode="multiple"
                                  showSearch
                                  placeholder="Select Activities"
                                  filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                >
                                  {(activityMap[rowIndex] || []).map(item => (
                                    <Option key={item._id} value={item._id}>
                                      {item.en_name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
      
                            <Col span={1}>
                              <MinusCircleOutlined onClick={() => remove(rowIndex)} style={{ marginTop: '5.2px' }} />
                            </Col>
                          </Row>
                        ))}
      
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Activity
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              )}
      
              {app.section !== "location_insights" && app.section !== "activity" && (
                <>
                  <Col span={24} md={6}>
                    <Form.Item
                      label="Heading"
                      name={`${app.section}_heading`}
                      rules={[{ required: true, message: `Please enter the heading!` }]}
                    >
                      <Input autoComplete="off" placeholder={`Enter Heading`} />
                    </Form.Item>
                  </Col>
                </>
              )}
      
              {app.section == "location_insights_category" && (
                <Col span={24} md={16}>
                  <Form.Item
                    label="Select Location Insights Category (Multiple)"
                    name="location_insights_category_id"
                    rules={[{ required: true, message: "Please select location insights category!" }]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select Category"
                      filterOption={(input, option) =>
                        (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {locationCategoryList.map(location => (
                        <Option key={location._id} value={location._id}> {location.en_name} </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {app.section == "activity_category" && (
                <Col span={24} md={16}>
                  <Form.Item
                    label="Select Activity Category (Multiple)"
                    name="activity_category_id"
                    rules={[{ required: true, message: "Please select activity category!" }]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select Category"
                      filterOption={(input, option) =>
                        (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {activityCategoryList.map(activity => (
                        <Option key={activity._id} value={activity._id}> {activity.en_name} </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {app.section == "state" && (
                <Col span={24} md={16}>
                  <Form.Item
                    label="Select State"
                    name="state_id"
                    rules={[{ required: true, message: "Please select a state!" }]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select State"
                      filterOption={(input, option) =>
                        (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {stateList.map(state => (
                        <Option key={state._id} value={state._id}> {state.en_name} </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {app.section == "city" && (
                <Col span={24} md={16}>
                  <Form.Item
                    label="Select City"
                    name="city_id"
                    rules={[{ required: true, message: "Please select a city!" }]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select City"
                      filterOption={(input, option) =>
                        (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {cityList.map(city => (
                        <Option key={city._id} value={city._id}> {city.en_name} </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {app.section == "blog" && (
                <Col span={24} md={16}>
                  <Form.Item
                    label="Select Blog"
                    name="blog_id"
                    rules={[{ required: true, message: "Please select a blog!" }]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select Blog"
                      filterOption={(input, option) =>
                        (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {blogList.map(blog => (
                        <Option key={blog._id} value={blog._id}> {blog.en_name} </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      
        <Form.Item className="btn-row float-right">
          <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}>
            <i className="fas fa-arrow-left mr-5"></i> Back
          </Link>

          <Button type="primary" loading={loading} htmlType="submit">
            <i className="fas fa-check mr-5"></i> Submit
          </Button>
        </Form.Item>
      </Form>
      )}
    </Card>
  );
}

export default Index;
