import {Row, Col, Card, Button, Input, Form, Tabs, Select, Badge, Skeleton} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import DescriptionEditor from '../../components/DescriptionEditor'
import MultipleImageUpload from "../../components/MultipleImageUpload";
import languages from "../../config/language";
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ShowToast, Severty } from "../../helper/toast";
import HashTagModel from "../HashTag/HashTagModel";
const { Option } = Select;
const { TabPane } = Tabs;

function Edit() {

    const sectionName   =   "Blog";
    const routeName     =   "blog";

    const api = {
        addEdit     :   apiPath.addEditBlog,
        category    :   apiPath.commonCategory,
        subCategory :   apiPath.commonSubCategory,
        getHashtag  :   apiPath.commonHashtag,
        edit        :   apiPath.editBlog,
        country     :   apiPath.commonCountry,
        state       :   apiPath.commonState,
        city        :   apiPath.commonCity,
    }

    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const navigate                          =   useNavigate();
    const params                            =   useParams();
    const [form]                            =   Form.useForm();
    const FileType                          =   ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
    const [editoEnValue, setEditoEnValue]   =   useState('');
    const [multipleImage, setMultipleImage]         =   useState([]);
    const [categoryList, setCategoryList]           =   useState([]);
    const [subCategoryList, setSubCategoryList]     =   useState([]);
    const [validationStatus, setValidationStatus]   =   useState({});
    const [hashTagList, setHashTagList]             =   useState([]);
    const [isModalVisible, setIsModalVisible]       =   useState(false);
    const [refresh, setRefresh]                     =   useState(false);
    const [countryList, setCountryList]   =   useState([]);
    const [stateList, setStateList]       =   useState([]);
    const [cityList, setCityList]         =   useState([]);

    const handelHashTagModel = () => {
        setIsModalVisible(true);
    }

    const viewHashTag = () => {
        navigate(`/hashtag`);
    }

    const handleMultipleImage = (data) =>{
        setMultipleImage(data);
    }

    const handleMultipleDeleteImage = (data) =>{
        setMultipleImage(prevFileList => prevFileList.filter(item => item.url !== data));
    }
    
    const handleCategoryChange = (e) => {
        getSubCategoryList(e);
    }

    const getHashTagList = () => {
        request({
          url: api.getHashtag,
          method: 'GET',
          onSuccess: (response) => {
            setHashTagList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const getSubCategoryList = (id) => {
        request({
            url: api.subCategory + "/" + id,
            method: 'GET',
            onSuccess: (response) => {
              setSubCategoryList(response.data.data);
            },
            onError: (error) => {
              console.log(error)
              ShowToast(error, Severty.ERROR)
            }
        })
    };

    const getCategoryList = () => {
        request({
          url: api.category,
          method: 'GET',
          onSuccess: (response) => {
            setCategoryList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const handleCountryChange = (e) => {
        getStateList(e);
    }
    
    const handleStateChange = (e) => {
        getCityList(e);
    }

    const getCountryList = () => {
        request({
        url: api.country,
        method: 'GET',
        onSuccess: (response) => {
            setCountryList(response.data.data);
        },
        onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
        }
        })
    };

    const getStateList = (country_id) => {
        request({
        url: api.state + "/" + country_id,
        method: 'POST',
        onSuccess: (response) => {
            setStateList(response.data.data);
        },
        onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
        }
        })
    };

    const getCityList = (state_id) => {
        request({
        url: api.city + "/" + state_id,
        method: 'POST',
        onSuccess: (response) => {
            setCityList(response.data.data);
        },
        onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
        }
        })
    };

    const handleFieldChange = (data, lang, type) => {
        if (type === 'description' && lang === 'en') {
            setEditoEnValue(data);
        }

        const name                  =   form.getFieldValue(`${lang}_name`);
        const description           =   form.getFieldValue(`${lang}_description`);
        const isDescriptionEmpty    =   !description || (description.trim() === '<p></p>' || description.trim() === "");
        setValidationStatus(prevStatus => ({
            ...prevStatus, 
            [lang]: !!name && !isDescriptionEmpty
        }));
    }

    const fetchData = (id) => {
        request({
            url: api.edit + "/" + id,
            method: 'GET',
            onSuccess: (data) => {
                setLoading(false);
                form.setFieldsValue(data.data);
                setEditoEnValue(data?.data?.en_description);
                setMultipleImage(data?.data?.images.length > 0 && data?.data?.images.map(item => ({ url : item })))
                getSubCategoryList(data?.data?.category_id);
                getStateList(data?.data?.state_id);
                getCityList(data?.data?.city_id);

                // Initialize validationStatus based on fetched data
                const fetchedValidationStatus = {};
                
                languages.forEach(lang => {
                    const name                  =   form.getFieldValue(`${lang.short}_name`);
                    const description           =   form.getFieldValue(`${lang.short}_description`);
                    const isDescriptionEmpty    =   !description || (description.trim() === '<p></p>' || description.trim() === "");
                    fetchedValidationStatus[lang.short] = !!name && !isDescriptionEmpty;
                });

                setValidationStatus(fetchedValidationStatus);
            },
            onError: (error) => {
                console.error(error);
            }
        })
    }
    
    useEffect(() => {
        const initialValidationStatus = {};
        
        languages.forEach(lang => {
            initialValidationStatus[lang.short] = !!form.getFieldValue(`${lang.short}_name`) && !!form.getFieldValue(`${lang.short}_description`);
        });

        setValidationStatus(initialValidationStatus);
        
        fetchData(params.id);

        setLoading(true);
        
    }, [])

    const validateFields = async () => {
        try {
            await form.validateFields();

            const englishTitle          =   form.getFieldValue('en_name');
            const englishDescription    =   form.getFieldValue('en_description');

            if (englishDescription.trim() === '<p></p>' || englishDescription.trim() === "") {
                return ShowToast('Please update english description', Severty.ERROR)
            }

            if (!englishTitle) {
                return ShowToast("Please update english title", Severty.ERROR);
            }

            onUpdate();

        } catch (error) {
            console.error(error);
        }
    };

    const onUpdate = () => {
        
        let payload = {
            ...form.getFieldsValue(),
            images: multipleImage.length > 0 ? multipleImage.map((item) => item.url) : '',
        };

        setLoading(true)
        request({
            url     : api.addEdit + "/" + params.id,
            method  : 'POST',
            data    : payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    useEffect(() => {
        getHashTagList();
    }, [refresh])

    useEffect(() => {
        getCategoryList();
        getCountryList();
    }, []);

    return (
        <>
            <Card title={"Update " + sectionName + " Details"}>

                {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :

                    <Form className="edit-page-wrap" form={form} autoComplete="off" layout="verticle" name="blog_form">
                        
                        <Row gutter={[24, 0]}>

                            <Col span={12} xs={12} sm={12} md={8}>
                                <Form.Item className="banner-elt"
                                    name="country_id"
                                    label="Select Country"
                                    rules={[{ required: true, message: 'Missing Country Selection' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Country"
                                        optionFilterProp="children"
                                        onChange={handleCountryChange}
                                        filterOption={(input, option) =>
                                            option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countryList?.map((item, index) => (
                                            <Option key={index} value={item._id}>
                                                <span className="cap">{item.en_name}</span>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12} xs={12} sm={12} md={8}>
                                <Form.Item className="banner-elt"
                                    name="state_id"
                                    label="Select State"
                                    rules={[{ required: true, message: 'Missing State Selection' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select State"
                                        optionFilterProp="children"
                                        onChange={handleStateChange}
                                        filterOption={(input, option) =>
                                            option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {stateList?.map((item, index) => (
                                            <Option key={index} value={item._id}>
                                                <span className="cap">{item.en_name}</span>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12} xs={12} sm={12} md={8}>
                                <Form.Item className="banner-elt"
                                    name="city_id"
                                    label="Select City"
                                    rules={[{ required: true, message: 'Missing City Selection' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select City"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {cityList?.map((item, index) => (
                                            <Option key={index} value={item._id}>
                                                <span className="cap">{item.en_name}</span>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={24} md={12}>
                                <Form.Item
                                    label="Select Category"
                                    name="category_id"
                                    rules={[
                                        { required: true, message: "Please select the category!" },
                                    ]}
                                    >
                                    <Select showSearch placeholder="Select Category" onChange={(id) => handleCategoryChange(id)} filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}>
                                        {categoryList?.map((item) => (
                                            <Select.Option key={item._id} value={item._id} label={item.en_name}> {item.en_name} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={24} md={12}>
                                <Form.Item
                                    label="Select Sub Category"
                                    name="sub_category_id"
                                    rules={[
                                        { required: true, message: "Please select the sub category!" },
                                    ]}
                                    >
                                    <Select showSearch placeholder="Select Sub Category" filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}>
                                        {subCategoryList?.map((item) => (
                                            <Select.Option key={item._id} value={item._id} label={item.en_name}> {item.en_name} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={24} md={24}>
                                <Button 
                                    type="link"
                                    icon={<PlusOutlined />} 
                                    style={{ marginLeft: '-20px' }} 
                                    onClick={handelHashTagModel}>
                                    Add New HashTag
                                </Button>

                                <Button
                                    type="link"
                                    icon={<SearchOutlined />} 
                                    style={{ marginLeft: '-20px' }} 
                                    onClick={() => { viewHashTag() }}>
                                    <span className="ml-5">View All HashTag</span>
                                </Button>
                            </Col>
                            
                            <Col span={24} xs={24} sm={24} md={24}>
                                <Form.Item
                                    label="Select Hashtag (Multiple)"
                                    name="hashtag"
                                    rules={[
                                        { required: true, message: "Please select the hashtag!" },
                                    ]}
                                    >
                                    <Select mode="multiple" showSearch placeholder="Select Hashtag" filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}>
                                        {hashTagList?.map((item) => (
                                            <Select.Option key={item._id} value={item._id} label={item.name}> {item.name} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={24} md={12}>
                                <Form.Item label="Upload Image (Multiple)" name="images" className="mb-1">
                                    <div className="mb-1"></div>
                                    <MultipleImageUpload data={[...multipleImage.map((item) => item.url)]} fileType={FileType} imageType={'explore-stay'} btnName={'Image'} onDelete={(data) => handleMultipleDeleteImage(data)} onChange={(data)=> handleMultipleImage(data)} />
                                </Form.Item>
                            </Col>

                            <Col md={24}>
                                <Tabs defaultActiveKey={languages[0].short}>
                                    {languages.map(lang => (
                                        
                                        <TabPane tab={<span>{lang.long} {validationStatus[lang.short] === false && <Badge dot color="red" />}</span>} key={lang.short}>
                                            
                                            <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                                                rules={[
                                                    ...(lang.short === 'en' ? [
                                                        { required: true, message: `Please enter the name in ${lang.long}!` }
                                                    ] : []),
                                                    { max: 100, message: "Name should not contain more than 100 characters!" },
                                                    { min: 2, message: "Name should contain at least 2 characters!" },
                                                ]}
                                                initialValue={form.getFieldValue(`${lang.short}_name`)}
                                                onChange={(data) => handleFieldChange(data, lang.short, 'title')}
                                            >

                                                <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>

                                            </Form.Item>

                                            <Form.Item label={`Description (${lang.long})`} name={`${lang.short}_description`}
                                                rules={[
                                                    { required: lang.short === 'en', message: `Please enter the description in ${lang.long}!` },
                                                ]}
                                                initialValue={form.getFieldValue(`${lang.short}_description`)}
                                            >
                                                <DescriptionEditor value={editoEnValue} placeholder={`Enter Description in ${lang.long}`} onChange={(data)=> handleFieldChange(data, lang.short, 'description')} />

                                            </Form.Item>

                                        </TabPane>
                                    ))}
                                </Tabs>
                            </Col>

                        </Row>

                        <Form.Item className="btn-row float-right">
                            <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                            <Button type="primary" loading={loading} onClick={validateFields}>Submit</Button>
                        </Form.Item>
                    </Form>
                }

            </Card>

            {isModalVisible && <HashTagModel type={sectionName} path={api.addEditHashTag} sectionName={sectionName} show={isModalVisible} hide={() => { setIsModalVisible(false) }} data={null} refresh={() => setRefresh(prev => !prev)} />}
        </>
    )
}

export default Edit;