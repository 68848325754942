import {Row, Col, Card, Button, Input, Form, Divider, Skeleton} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import languages from "../../config/language";
import { Link, useParams } from "react-router-dom";

function Edit() {

    const sectionName   =   "Master Filter";
    const routeName     =   "master-filter";

    const api = {
        addEditMaster   :  apiPath.addEditMasterFilter,
        view            :  apiPath.viewMasterFilter,
    }
    
    const [form]                    =   Form.useForm();
    const { request }               =   useRequest();
    const [loading, setLoading]     =   useState(false);
    const params                    =   useParams();
    const navigate                  =   useNavigate();
    
    const onAddon = (values) => {
        let payload = {
            ...values,
        };
        console.log(payload);
        
        setLoading(true)
        request({
            url: api.addEditMaster + "/" + params.id,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    const fetchData = (id) => {
        request({
          url: api.view + "/" + id,
          method: 'GET',
          onSuccess: (response) => {
            form.setFieldsValue({...response.data});
            setLoading(false);
        },
        onError: (error) => {
            setLoading(false);
            ShowToast(error, Severty.ERROR)
          }
        })
    }
    
    useEffect(() => {
        fetchData(params.id);
        setLoading(true);
    }, []);
    
    return (
        <Card title={sectionName}>
            {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :
                <Form className="edit-page-wrap" form={form} onFinish={onAddon} autoComplete="off" layout="verticle" name="master_form">
                    
                    <Row gutter={[24, 0]}>

                        <Col span={24} xs={24} sm={24} md={24}>
                            <Row gutter={[24, 0]}>
                                {languages.map((lang) => (
                                    <Col span={8} key={lang.short}>
                                        <Form.Item
                                            className="qty-cls"
                                            name={`${lang.short}_name`}
                                            label={`Name (${lang.long})`}
                                            rules={[
                                                ...(lang.short === 'en' ? [
                                                    { required: true, message: `Please enter the name in ${lang.long}!` }
                                                ] : []),
                                            ]}
                                        >
                                            <Input autoComplete="off" placeholder={`Enter Filter Name in ${lang.long}`} />
                                        </Form.Item>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        
                        <Col span={24} xs={24} sm={24} md={24}>
                            <Form.List name="answer">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name }, index) => (
                                            <div key={key} style={{ marginBottom: '20px' }}>
                                                <div align="baseline" className="gap-cls">
                                                
                                                    <Row gutter={16}>
                                                        <Col span={24}>
                                                            <Divider orientation="left" orientationMargin={0} className="devider-color">
                                                            {`Filter Value - ${index + 1}`}
                                                            </Divider>
                                                        </Col>

                                                        <Col span={24}>
                                                            <Row gutter={16}>
                                                                {languages.map((lang) => (
                                                                    <Col span={8} key={`${key}_${lang.short}`}>
                                                                        <Form.Item
                                                                            className="qty-cls"
                                                                            name={[name, `${lang.short}_answer`]}
                                                                            label={`Value (${lang.long})`}
                                                                            rules={[
                                                                                ...(lang.short === 'en' ? [
                                                                                    { required: true, message: `Please enter the value in ${lang.long}!` }
                                                                                ] : []),
                                                                            ]}
                                                                        >
                                                                            <Input autoComplete="off" placeholder={`Enter Filter Value in ${lang.long}`} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Col>
                                                        
                                                        <Col span={24}>
                                                            {index > 0 && (
                                                                <div className="minus-wrap" style={{ marginBottom: '8px' }}>
                                                                    <MinusCircleOutlined
                                                                        onClick={() => remove(index)}
                                                                        style={{ borderRadius: '8px' }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Filter Values
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                            
                    <Form.Item className="btn-row float-right">
                        <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                    </Form.Item>
                </Form>
            }
        </Card>
    )
}

export default Edit;