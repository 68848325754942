import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Avatar, Image, Tabs, Switch } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import UserFrom from "./UserFrom";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import ConfirmationBox from "../../components/ConfirmationBox";
import { DownloadExcel } from "../../components/ExcelFile";
import { ChangeApprovalStatus } from "../../components/ChangeApprovalStatus";
import { APPROVAL_STATUS } from "../../constants/conts";

const { TabPane } = Tabs;
const Search = Input.Search;
const { RangePicker } = DatePicker;


function Index() {

  const sectionName = "User";
  const routeName = "user";

  const api = {
    status      : apiPath.statusUser,
    addEdit     : apiPath.addEditUser,
    list        : apiPath.listUser,
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [importVisible, setImportVisible] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();
  const [approvalStatusVisible, setApprovalStatusVisible] = useState(false);
  const [userId, setUserId]   =   useState();
  const [otpVerification, setOtpVerification]       =   useState(true);

  const view = (id, otp_verification) => {
    navigate(`/${routeName}/view/${id}?otp_verification=${otp_verification}`)
  }

  const changeApprovalStatus = (id, status) => {
    setUserId(id);
    if(status == undefined || status == APPROVAL_STATUS.PENDING || status == APPROVAL_STATUS.REJECTED) {
      setApprovalStatusVisible(true);
    } else {
      ShowToast(`Status has already been ${status}`, Severty.INFO)
    }
  };

  const handleTabChange = (status) => {
    setLoading(true);
    setOtpVerification(status);
    fetchData(pagination, "", status);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, { name, _id, image }) => {
        return (
          <>
            <Avatar className="cap" size={40}> {name.charAt(0)} </Avatar>
            <a style={{ marginLeft: 12 }} className="cap avatar-text" onClick={(e) => view(_id)}>{name}</a>
          </>
        );
      },
    },
    {
      title: "Mobile Number",
      width: 150,
      sorter: (a, b) => a.mobile_number.localeCompare(b.mobile_number),
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+965") + (mobile_number ? mobile_number : '')
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      width: 250,
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (_, { email }) => {
        return email ? email : '-';
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.en_name.localeCompare(b.state.en_name),
      render: (_, { state }) => {
        return state ? state.en_name : '-';
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.city.en_name.localeCompare(b.city.en_name),
      render: (_, { city }) => {
        return city ? city.en_name : '-';
      },
    },
    {
      title: "Referral Code",
      dataIndex: "referral_code",
      key: "referral_code",
      sorter: (a, b) => a.referral_code.localeCompare(b.referral_code),
      render: (_, { referral_code }) => {
        return referral_code ? referral_code : '-';
      },
    },
    {
      title: `Approval Status`,
      key: "is_approve",
      filters: [
        {
          text: 'Pending',
          value: 'Pending',
        },
        {
          text: 'Approved',
          value: 'Approved',
        },
        {
          text: 'Rejected',
          value: 'Rejected',
        },
      ],
      render: (_, { approval_email_sent, is_approve, _id, is_otp_verify }) => {

        let color;

        switch (is_approve) {
          case APPROVAL_STATUS.APPROVED:
            color = "green";
            break;
          case APPROVAL_STATUS.REJECTED:
            color = "red";
            break;
          case APPROVAL_STATUS.PENDING:
            color = "cyan";
            break;
          default:
            color = "blue";
        }
    
        return (
          <>
            {is_otp_verify ? (
              <>
                <a>
                  <Tag onClick={(e) => changeApprovalStatus(_id, is_approve)} color={color} key={is_approve}>
                    {is_approve || APPROVAL_STATUS.PENDING}
                  </Tag>
                </a>
                {approval_email_sent && (
                  <div className="mt-1 text-success min-font-bold">
                    <i className="fas fa-check"></i> E-Mail Sent
                  </div>
                )}
              </>
            ) : (
              <div className="text-danger">OTP Not Verified</div>
            )}
          </>
        );
    }
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id, is_otp_verify }) => {
        return (
          is_otp_verify ? (
            <Switch
              checked = {is_active}
              checkedChildren = {<CheckOutlined />}
              unCheckedChildren = {<CloseOutlined />}
              loading = {loading}
              onChange = {(checked) =>
                showConfirm({
                  record    : _id,
                  path      : api.status,
                  onLoading : () => setLoading(true),
                  onSuccess : () => setRefresh((prev) => !prev),
                  is_active : checked,
                })
              }
              className={is_active ? "custom-switch-checked" : ""}
            />
          ) : ('-')
        );
      },
    },
    {
      title: "Registered On",
      key: "created_at",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
          {
            // record.is_otp_verify ? ( 
              <>
                <Tooltip title={"Update " + sectionName} color={"purple"} key={"update" + routeName}>
                  <Button title="Edit" onClick={() => {
                    setSelected(record)
                    setVisible(true)
                  }}>
                    <i className="fa fa-light fa-pen"></i>
                  </Button>
                </Tooltip>

                <Tooltip title={"View " + sectionName} color={"purple"} key={"view" + routeName}>
                  <Button title="View" onClick={() => view(record._id, record.is_otp_verify)}>
                    <i className="fa fa-light fa-eye"></i>
                  </Button>
                </Tooltip>
              </>
            // ) : ('-')
          }
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination, "", otpVerification)
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters, otpVerification) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: api.list + `?otp_verification=${otpVerification}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters, otpVerification);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1, pageSize: pagination.pageSize })
  };

  const handleChangeDate = (e) => {
    setPagination({ current: 1, pageSize: pagination.pageSize })
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };

  const excelData = list.map(row => ({
    "Name": row.name ? row.name : '-',
    "Email": row.email ? row.email : '-',
    "Country Code": row.country_code ? row.country_code : '-',
    "Mobile Number": row.mobile_number ? row.mobile_number : '-',
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Location": row.location ? row.location : '-',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY"),
  }));

  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Manager"}
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder="Search By Name, Mobile, Email"
                    />
                    <RangePicker onChange={handleChangeDate} />
                  </div>
                  
                  <div className="button_group">
                    <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add {sectionName}</Button>
                    <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i class="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;Export</Button>
                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="custom-tab">
                <Tabs
                  onTabClick={handleTabChange}
                  className="min-font-bold"
                  tabBarStyle={{ color: "Black" }}
                >
                  <TabPane tab={"OTP Verified"} key={true}>
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                          current: pagination.current,
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                          showSizeChanger: true,
                          showQuickJumper: true,
                          pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        onChange={handleChange}
                        className="ant-border-space"
                      />
                    </div>
                  </TabPane>

                  <TabPane tab={"Non Verified"} key={false}>
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                          current: pagination.current,
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                          showSizeChanger: true,
                          showQuickJumper: true,
                          pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        onChange={handleChange}
                        className="ant-border-space"
                      />
                    </div>
                  </TabPane>
                </Tabs>
              </div>

            </Card>
          </Col>
        </Row>
      </div>

      {visible && <UserFrom type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {approvalStatusVisible && <ChangeApprovalStatus path={api.status} show={approvalStatusVisible} hide={() => { setApprovalStatusVisible(false) }} userId={userId} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}
export default Index;
