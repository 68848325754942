import { Row, Col, Card, Table, Button, Modal, Form, Tag, Image, Tooltip, Select, DatePicker, Input, Switch } from "antd";
import apiPath from "../../constants/apiPath";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import ShowTotal from "../../components/ShowTotal";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import { bannerType } from "../../constants/conts";
import moment from 'moment';
import { Link } from "react-router-dom";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;

function Index() {

  const sectionName = "Banner";
  const routeName = "banner";

  const api = {
    status    :   apiPath.statusBanner,
    addEdit   :   apiPath.addEditBanner,
    list      :   apiPath.listBanner,
    category  :   apiPath.commonCategory
  }

  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => <Image width={60} height={55} src={image} />
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: [
        { text: 'Normal', value: 'normal' },
        { text: 'Category', value: 'category' },
      ],
      render: (_, { type }) => {
        return (type ? <span className="cap">{type}</span> : '-')
      }
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (_, { category, offer, link, type }) => {
        if (type === 'normal') {
          return <span>{link ? <Link target="_blank" to={link}>{link}</Link> : '-'}</span>
        } else if (type === 'category') {
          return <span className="cap">{category?.name ? category.name : '-'}</span>
        } else {
          return '-';
        }
      }
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        return (
          <Switch
            checked = {is_active}
            checkedChildren = {<CheckOutlined />}
            unCheckedChildren = {<CloseOutlined />}
            loading = {loading}
            onChange = {(checked) =>
              showConfirm({
                record    : _id,
                path      : api.status,
                onLoading : () => setLoading(true),
                onSuccess : () => setRefresh((prev) => !prev),
                is_active : checked,
              })
            }
            className={is_active ? "custom-switch-checked" : ""}
          />
        );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <Tooltip title={"Update " + sectionName} color={"purple"} key={"update" + routeName}>
            <Button onClick={() => {
              setSelected(record)
              setVisible(true)
            }}>
              <i className="fa fa-light fa-pen"></i>
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, startDate, endDate])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    const filterType = filters ? filters.type : null
    request({
      url: api.list + `?type=${filterType ? filterType.join(',') : ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        const list = data.data.list.docs.map(item => ({ ...item, key: item._id }))
        setList(list)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };

  return (
    <>
      <div className="tabled bannerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Manager"}
              extra={
                <>
                  <div className="w-100 text-end d-sm-flex">
                    <RangePicker onChange={handleChangeDate} />
                    <div className="button_group justify-content-end ms-sm-2 mt-xs-2">
                      <Button onClick={(e) => setVisible(true)}>Add {sectionName}</Button>
                    </div>
                  </div>
                </>
              }
            >
              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {visible && <AddFrom section={sectionName} api={api} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </>
  );
}

const AddFrom = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [selectedBannerType, setSelectedBannerType] = useState();
  const [categoryList, setCategoryList] = useState([])
  const [categoryType, setCategoryType] = useState()
  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]

  const handleImage = (data) => {
    setImage(data);
    setFile(data[0].url);
  }

  const getCategoryList = () => {
    request({
      url: api.category,
      method: 'GET',
      onSuccess: (data) => {
        setCategoryList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  useEffect(() => {
    getCategoryList();
    if (!data) return
    form.setFieldsValue({ ...data })
    setSelectedBannerType(data.type);
    setFile([data.image])
  }, [data])

  const handleTypeChange = (value) => {
    setSelectedBannerType(value);
    if (value === 'normal') {
      form.resetFields(['link']);
    }
    if (value === 'category') {
      form.resetFields(['category_id']);
    }
  }

  const onCreate = (values) => {
    if (file.length <= 0) return ShowToast('Please select the banner image ', Severty.ERROR)

    setLoading(true)
    const payload = {}
    const { type, category_id, link } = values;
    payload.type = type;
    payload.category_id = category_id;
    payload.link = link;
    payload.image = image && image.length > 0 ? image[0].url : '';
    payload.category_type = categoryType;
    
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Edit ' + section : 'Create a New ' + section}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row>

          <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="banner-elt"
              name="type"
              label="Select Type"
              rules={[{ required: true, message: 'Missing Type Selection' }]}
            >
              <Select onChange={handleTypeChange} placeholder="Select Type">
                {bannerType?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.value}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {selectedBannerType === 'normal' ?
            <Col span={24} xs={24} sm={24} md={24}>
              <Form.Item className="link-elt" name="link" label="Enter Link" rules={[
                { pattern: new RegExp(/^(?:\w+:)?\/\/([^\s\.]|localhost[\:?\d]*)\S*$/), message: 'Please enter valid link Ex. https://google' }
              ]}>
                <Input placeholder="Enter Link"></Input>
              </Form.Item>
            </Col>

            : selectedBannerType === 'category' ?

              <Col span={24} xs={24} sm={24} md={24}>
                <Form.Item className="category-elt" name="category_id" label="Select Category" rules={[{ required: true, message: 'Missing Category Selection' }]}>
                  <Select placeholder="Select Category" onChange={(value, option) => setCategoryType(option?.key?.split("-")[1])}>
                    {categoryList?.map((item, index) => (
                      <option key={index + "-" + item.type} value={item._id}>
                        <span className="cap">{item.name}</span>
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

            : null}


          <Col span={24}>
            <Form.Item label={"Upload " + section} name="image"
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Image is required'));
                  },
                }
              ]}>
              <SingleImageUpload value={image} fileType={FileType} imageType={'banner'} btnName={'Thumbnail'} onChange={(data) => handleImage(data)} />

            </Form.Item>

            {file && file.length > 0 &&
              <div className="mt-2"> <Image width={120} src={file && file.length > 0 && file !== "" ? file : notfound}></Image> </div>
            }
          
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Index;
