import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {

  const sectionName = "Referral Reward";
  const routeName   = "referral-reward";

  const api = {
    list : apiPath.listReferral,
  }

  const navigate                    =   useNavigate();
  const { request }                 =   useRequest()
  const [list, setList]             =   useState([])
  const [loading, setLoading]       =   useState(false)
  const [refresh, setRefresh]       =   useState(false)
  const [startDate, setStartDate]   =   useState();
  const [endDate, setEndDate]       =   useState();
  const [searchText, setSearchText] =   useState('');
  const [pagination, setPagination] =   useState({ current: 1, pageSize: 10 });
  const debouncedSearchText         =   useDebounce(searchText, 300);

  const columns = [
    {
      title: "Referral Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: (_, { code }) => {
        return code ? code : '-';
      },
    },
    {
      title: "Amount (INR)",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount.localeCompare(b.amount),
      render: (_, { amount }) => {
        return amount ? amount : '0.00';
      },
    },
    {
      title: "Referred By",
      dataIndex: "from",
      key: "from",
      sorter: (a, b) => a.from.name.localeCompare(b.from.name),
      render: (_, { from }) => {
        return from ? from.name : '-';
      },
    },
    {
      title: "Referred To",
      dataIndex: "to",
      key: "to",
      sorter: (a, b) => a.to.name.localeCompare(b.to.name),
      render: (_, { to }) => {
        return to ? to.name : '-';
      },
    },
    {
      title: "Login Count",
      key: "login_count",
      dataIndex: "login_count",
      render: (_, { login_count }) => {
        return login_count ? login_count : 0;
      },
    },
    {
      title: "Amount Credited",
      key: "amount_credited",
      dataIndex: "amount_credited",
      render: (_, { amount_credited }) => {
        let color = amount_credited ? 'green' : 'red';
        return (<Tag color={color} key={amount_credited}>{amount_credited ? "Yes" : "No"}</Tag>);
      },
    },
    {
      title: "Refered On",
      key: "created_at",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    request({
      url: api.list + `?page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };

  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Manager"}
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder="Search By Name"
                    />
                    <RangePicker onChange={handleChangeDate} />
                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                 pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true,  pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Index;
