import { Row, Col, Card, Button, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from "moment";

function View() {

  const sectionName   =   "Contact Feedback";
  const routeName     =   "contact-feedback";
  
  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    request({
      url       :   apiPath.viewContactFeedback + "/" + id,
      method    :   'GET',
      onSuccess :   (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <Card title={sectionName + " Details"}>
      <Row gutter={16}>
        <Col span={12} xs={24} md={24}>

          {loading ? <Skeleton active /> : (
            <div className="view-main-list">
              
              <div className="view-inner-cls">
                <h5>User Name:</h5>
                <h6 className="cap">{list && list.user_id?.name ? list.user_id?.name : '-'}</h6>
              </div>
              
              <div className="view-inner-cls">
                <h5>User Email:</h5>
                <h6>{list && list.user_id?.email ? list.user_id?.email : '-'}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>Title:</h5>
                <h6 className="cap">{list && list.title ? list.title : '-'}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>Message:</h5>
                <h6 className="cap">{list && list.message ? list.message : '-'}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>Created On:</h5>
                <h6>{list.created_at ? moment(list.created_at).format('DD-MMM-YYYY') : '-'}</h6>
              </div>

              <div className="view-inner-cls float-right">
                <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
              </div>

            </div>
          )}

        </Col>
      </Row>
    </Card>
  );
  
}

export default View;