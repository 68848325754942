import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const currentYear = new Date().getFullYear();

  return (
    <AntFooter>
      <div className="copyright">
        <div> Copyright © {currentYear}. All Rights Reserved </div>
        <div> V 1.0 </div>
      </div>
    </AntFooter>
  );
}

export default Footer;