
let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://tripntales.s3.amazonaws.com/";
let URL;

// URL = 'http://3.109.38.103:2024/api/'
// URL = 'http://localhost:2024/api/'
URL = 'http://api.tripntales.in/api/'

let apiPath = {

  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "admin/dashboard",
  location: "admin/dashboard/location",

  // Auth API
  logout: "admin/auth/logout",
  login: "admin/auth/login",

  profile: "admin/auth/get-profile",
  updateProfile: "admin/auth/update-profile",
  changePassword: "admin/auth/change-password",
  updateAppSetting: "admin/auth/update-app-setting",

  forgotPassword: "admin/auth/forgot-password",
  verifyOTP: "admin/auth/verify-otp",
  resetPassword: "admin/auth/reset-password",

  listReferral: "admin/referral/list",
  listWalletTransaction: "admin/wallet/list",

  // User APIs
  listUser: "admin/user/list",
  addEditUser: "admin/user/add-edit",
  statusUser: "admin/user/status",
  viewUser: "admin/user/view",

  // Contact Feedback APIs
  listContactFeedback: "admin/contact-feedback/list",
  viewContactFeedback: "admin/contact-feedback/view",

  // Language APIs
  addEditLanguage: "admin/language/add-edit",
  viewLanguage: "admin/language/view",

  // SubAdmin APIs
  listSubAdmin: "admin/sub-admin/list",
  addEditSubAdmin: "admin/sub-admin/add-edit",
  statusSubAdmin: "admin/sub-admin/status",
  viewSubAdmin: "admin/sub-admin/view",
  getModule: "admin/sub-admin/module-list",
  addPermission: "admin/sub-admin/add-permission",

  // Application Form APIs
  listApplicationForm: "admin/application-form/list",
  addEditApplicationForm: "admin/application-form/add-edit",
  viewApplicationForm: "admin/application-form/view",
  statusApplicationForm: "admin/application-form/status",

  // Hobbies APIs
  listHobbies: "admin/master/hobbie/list",
  addEditHobbies: "admin/master/hobbie/add-edit",
  statusHobbies: "admin/master/hobbie/status",
  viewHobbies: "admin/master/hobbie/view",

  // App Home APIs
  addEditAppHome: "admin/app-home/add-edit",
  viewAppHome: "admin/app-home/view",

  // Occupation APIs
  listOccupation: "admin/master/occupation/list",
  addEditOccupation: "admin/master/occupation/add-edit",
  viewOccupation: "admin/master/occupation/view",
  statusOccupation: "admin/master/occupation/status",

  // State APIs
  listState: "admin/state/list",
  addEditState: "admin/state/add-edit",
  addEditStateAddon: "admin/state/add-edit-addon",
  viewState: "admin/state/view",
  statusState: "admin/state/status",
  
  // Country APIs
  listCountry: "admin/country/list",
  addEditCountry: "admin/country/add-edit",
  viewCountry: "admin/country/view",
  statusCountry: "admin/country/status",
  
  editSetting: "admin/setting/edit",
  viewSetting: "admin/setting/view",

  // Subscription APIs
  listSubscription: "admin/subscription/list",
  addEditSubscription: "admin/subscription/add-edit",
  viewSubscription: "admin/subscription/view",
  statusSubscription: "admin/subscription/status",

  // City APIs
  listCity: "admin/city/list",
  addEditCity: "admin/city/add-edit",
  addEditCityAddon: "admin/city/add-edit-addon",
  statusCity: "admin/city/status",
  viewCity: "admin/city/view",
  editCity: "admin/city/edit",

  listCategory: "admin/category/main/list",
  addEditCategory: "admin/category/main/add-edit",
  viewCategory: "admin/category/main/view",
  statusCategory: "admin/category/main/status",

  listMasterFilter: "admin/master-filter/list",
  addEditMasterFilter: "admin/master-filter/add-edit",
  viewMasterFilter: "admin/master-filter/view",
  statusMasterFilter: "admin/master-filter/status",

  listSubCategory: "admin/category/sub/list",
  addEditSubCategory: "admin/category/sub/add-edit",
  viewSubCategory: "admin/category/sub/view",
  statusSubCategory: "admin/category/sub/status",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/add-edit",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  // Blog APIs
  listBlog: "admin/blog/list",
  addEditBlog: "admin/blog/add-edit",
  editBlog: "admin/blog/edit",
  statusBlog: "admin/blog/status",
  viewBlog: "admin/blog/view",

  // Banner APIs
  listBanner: "admin/banner/list",
  addEditBanner: "admin/banner/add-edit",
  statusBanner: "admin/banner/status",
  viewBanner: "admin/banner/view",

  // Content APIs
  listContent: "admin/content/list",
  addEditContent: "admin/content/add-edit",
  statusContent: "admin/content/status",
  viewContent: "admin/content/view",

  // HashTag APIs
  listHashTag: "admin/hashtag/list",
  addEditHashTag: "admin/hashtag/add-edit",
  statusHashTag: "admin/hashtag/status",
  
  // Hashtag APIs
  listHashtag: "admin/hashtag/list",
  addEditHashtag: "admin/hashtag/add-edit",
  statusHashtag: "admin/hashtag/status",
  viewHashtag: "admin/hashtag/view",

  // Location Insights APIs
  listLocationInsights: "admin/location-insights/list",
  addEditLocationInsights: "admin/location-insights/add-edit",
  addEditLocationInsightsAddon: "admin/location-insights/add-edit-addon",
  statusLocationInsights: "admin/location-insights/status",
  viewLocationInsights: "admin/location-insights/view",
  editLocationInsights: "admin/location-insights/edit",

  // Activity APIs
  listActivity: "admin/activity/list",
  addEditActivity: "admin/activity/add-edit",
  addEditActivityAddon: "admin/activity/add-edit-addon",
  statusActivity: "admin/activity/status",
  viewActivity: "admin/activity/view",
  editActivity: "admin/activity/edit",

  // Common Routes 
  commonCategory: "common/category",
  commonSubCategory: "common/sub-category",
  commonHashtag: "common/hashtag",
  getAppSetting: "common/app-setting",
  commonState: "common/states",
  commonCity: "common/city",
  commonBlog: "common/blog",
  commonCountry: "common/country",
  commonActivity: "common/activity",
  commonLocationInsights: "common/location-insights",
  commonFilter: "common/master-filter",
  commonFilterValue: "common/master-filter-value",

};

export default apiPath;
