import React, { useState, useEffect } from 'react';
import { Input } from "antd";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import axios from 'axios';
const libraries = ["drawing", "places"];

const LocationMap = ({ onChange, userData }) => {

  const [userAddress, setUserAddress]   =   useState(null);
  const [map, setMap]                   =   useState(null);
  const [coordinates, setCoordinates]   =   useState({ lat: 30.5595, lng: 22.9375 });
  const googleMapsApiKey                =   'AIzaSyDQ2DCe8qS4qVCkMtRZRnZZ_TF2qq1HSvs'; 

  const handleLocationSelect = async (selectedAddress) => {
    try {
      const results       =   await geocodeByAddress(selectedAddress);
      const { lat, lng }  =   await getLatLng(results[0]);
      setCoordinates({ lat, lng });
      setUserAddress(selectedAddress);
      if(onChange){
        onChange({ address: selectedAddress, latitude: lat, longitude: lng });
      }
    } catch (error) {
      console.error('Error while selecting location:', error);
    }
  };

  const geocodeByLatLng = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          resolve(results[0].formatted_address);
        } else {
          reject(new Error('Geocoder request failed.'));
        }
      });
    });
  };

  const handleMarkerDrag = async (event) => {
    try {
      const { latLng } = event;
      const lat = latLng.lat();
      const lng = latLng.lng();
      setCoordinates({ lat, lng });
      const results = await geocodeByLatLng(lat, lng);
      if (results.length > 0) {
        const selectedAddress = results;        
        setUserAddress(selectedAddress);
        if (onChange) {
          onChange({ address: selectedAddress, latitude: lat, longitude: lng });
        }
      }
    } catch (error) {
      console.error('Error while dragging marker:', error);
    }
  };

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          latlng: `${lat},${lng}`,
          key: googleMapsApiKey
        }
      });

      if (response.data.status === 'OK') {
        const address = response.data.results[0]?.formatted_address || 'Address not found';
        setUserAddress(address);
      } else {
        console.error('Geocode error :', response.data.status);
        setUserAddress('Failed to retrieve address');
      }
    } catch (error) {
      console.error('Error fetching address :', error);
      setUserAddress('Error fetching address');
    }
  };
  
  useEffect(() => {
    if (userData) {
      setUserAddress(userData?.location?.address);
      setCoordinates({ lat: parseFloat(userData?.location?.latitude), lng: parseFloat(userData?.location?.longitude) });
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCoordinates({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
            // fetchAddress(latitude, longitude);
          },
          (error) => {
            console.error('Error getting location:', error);
          }
        );
      }
    }
  }, [userData]);

  /* useEffect(() => {
    if (userData) {
      setUserAddress(userData.location);
      setCoordinates({ ...coordinates, lat: parseFloat(userData.latitude), lng: parseFloat(userData.longitude) });
    }else{
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setCoordinates({ lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) });
        });
      }
    }
  }, []); */

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleMapsApiKey, libraries });

  if (!isLoaded) {
    return <div className='text-center'>Loading Map</div>
  }

  return (
    <>
      <PlacesAutocomplete value={userAddress} onChange={setUserAddress} onSelect={handleLocationSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Input {...getInputProps({placeholder: 'Search Location'})} />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Please Wait...</div>}

              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                const style     = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                
                return (
                  <div {...getSuggestionItemProps(suggestion, { className, style })}> <span>{suggestion.description}</span> </div>
                );

              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
      
      <GoogleMap center={coordinates} zoom={14} mapContainerClassName="googleMap" onLoad={(map) => setMap(map)}>
        <Marker position={coordinates} draggable={true} onDragEnd={handleMarkerDrag} />
      </GoogleMap>

    </>
  );
}

export default LocationMap;
