import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Modal } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce"; 
import ShowTotal from "../../components/ShowTotal";
import moment from 'moment';
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import ConfirmationBox from "../../components/ConfirmationBox";
const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {

  const sectionName = "Wallet Transaction";
  const routeName   = "transaction/wallet";

  const api = {
    list    : apiPath.listWalletTransaction
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const columns = [
    {
      title: "Transaction No.",
      dataIndex: "transaction_no",
      key: "transaction_no",
      render: (_, { transaction_no }) => {
        return ( transaction_no ? transaction_no : '-' );
      },
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (_, { user }) => {
        return ( user?.name ? user.name : '-' );
      },
    },
    {
      title: "Amount (INR)",
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => {
        return ( amount ? amount : '0.00' );
      },
    },
    {
      title: "Balance (INR)",
      dataIndex: "balance",
      key: "balance",
      render: (_, { balance }) => {
        return ( balance ? balance : '0.00' );
      },
    },
    {
      title: "Transaction Type",
      key: "transaction_type",
      filters: [
        {
          text: 'CR',
          value: 'CR',
        },
        {
          text: 'DR',
          value: 'DR',
        },
      ],
      render: (_, { transaction_type, _id }) => {
        let color = transaction_type == 'CR' ? 'green' : 'red';
        return ( 
          <Tag color={color} key={transaction_type}>{transaction_type}</Tag>
        );
      },
    },
    {
      title: "Payment Status",
      key: "payment_status",
      filters: [
        {
          text: 'Pending',
          value: 'Pending',
        },
        {
          text: 'Success',
          value: 'Success',
        },
        {
          text: 'Failed',
          value: 'Failed',
        },
      ],
      render: (_, { payment_status }) => {
        let color = payment_status == 'Success' ? 'green' : payment_status == 'Failed' ? 'red' : 'warning';
        return ( 
          <Tag color={color} key={payment_status}> {payment_status} </Tag>
        );
      },
    },
    {
      title: "Transaction On",
      key: "created_at",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <Tooltip title={"View " + sectionName} color={"purple"} key={"view"+routeName}>
            <Button title="View" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
              <i className="fa fa-light fa-eye"></i>
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])


  const fetchData = (pagination, filters) => {
    const filterPaymentStatus   = filters ? filters.payment_status : null
    const filterTransactionType = filters ? filters.transaction_type : null

    request({
      url: api.list + `?transaction_type=${filterTransactionType ? filterTransactionType.join(',') : ''}&payment_status=${filterPaymentStatus ? filterPaymentStatus.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,

      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };


  const handleChangeDate = (e) => {
    if(e!=null){
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    }else{
      setStartDate()
      setEndDate()
    }
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " Manager"}
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder="Search By Name, Email"
                  />
                  <RangePicker onChange={handleChangeDate} />
                </>
              }
            >
              
              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <View sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} />}

    </>
  );
}

const View = ({ sectionName, show, hide, data }) => {

  return (
    <Modal
      width={800}
      visible={show}
      title={`${'View ' + sectionName}`}
      okText="Ok"
      footer={[
        <Button key="cancel" onClick={hide}>
          Cancel
        </Button>
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} xs={12} sm={12} md={24}>
          
          <div className="view-main-list">

            <div className="view-inner-cls">
              <h5>Transaction No.:</h5>
              <h6><span className="cap">{data?.transaction_no}</span></h6>
            </div>

            <div className="view-inner-cls">
              <h5>User Name:</h5>
              <h6><span className="cap">{data?.user?.name}</span></h6>
            </div>

            <div className="view-inner-cls">
              <h5>User Email:</h5>
              <h6><span>{data?.user?.email}</span></h6>
            </div>

            <div className="view-inner-cls">
              <h5>Transaction Type:</h5>
              <h6><span className="cap">{data?.transaction_type}</span></h6>
            </div>

            <div className="view-inner-cls">
              <h5>Amount (INR):</h5>
              <h6><span className="cap">{data?.amount}</span></h6>
            </div>

            <div className="view-inner-cls">
              <h5>Balance (INR):</h5>
              <h6><span className="cap">{data?.balance}</span></h6>
            </div>

            <div className="view-inner-cls">
              <h5>Payment Status:</h5>
              <h6><span className="cap">{data?.payment_status}</span></h6>
            </div>

            <div className="view-inner-cls">
              <h5>Description:</h5>
              <h6><span className="cap">{data?.description}</span></h6>
            </div>

            <div className="view-inner-cls">
              <h5>Transaction On:</h5>
              <h6>{data.created_at ? moment(data.created_at).format('DD-MMM-YYYY') : '-'}</h6>
            </div>

          </div>

        </Col>
      </Row>
    </Modal>
  )
}
export default Index;
