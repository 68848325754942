import {Row, Col, Card, Table, Button, Input, Tag, Tooltip, Image, Switch} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import ShowTotal from "../../../components/ShowTotal";
import { useNavigate } from "react-router";
import ConfirmationBox from "../../../components/ConfirmationBox";
import apiPath from "../../../constants/apiPath";
import moment from 'moment';
import notfound from "../../../assets/images/not_found.png";
import { Link } from "react-router-dom";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { STAY_CATEGORY } from "../../../constants/conts";
const Search = Input.Search;

function Index() {

  const sectionName   =   "Category";
  const routeName     =   "activity/category";

  const api = {
    status  : apiPath.statusCategory,
    list    : apiPath.listCategory
  }

  const [searchText, setSearchText]   =   useState('');
  const { request }                   =   useRequest()
  const [list, setList]               =   useState([])
  const { showConfirm }               =   ConfirmationBox()
  const [loading, setLoading]         =   useState(false)
  const [refresh, setRefresh]         =   useState(false)
  const [pagination, setPagination]   =   useState({ current: 1, pageSize: 10 });
  const debouncedSearchText           =   useDebounce(searchText, 300);
  const navigate                      =   useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const edit = (id) => {
    navigate(`/${routeName}/update/${id}`)
  }

  const activity = (id) => {
    navigate(`/activity/add/${id}`)
  }

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => <Image width={60} height={60} src={image ? image : notfound} />
    },
    {
      title: "Name",
      dataIndex: "en_name",
      key: "en_name",
      sorter: (a, b) => a.en_name.localeCompare(b.en_name),
      render: (_, { en_name }) => {
        return (
          en_name ? <span className="cap">{en_name}</span> : '-'
        );
      },
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        return (
          <Switch
            checked = {is_active}
            checkedChildren = {<CheckOutlined />}
            unCheckedChildren = {<CloseOutlined />}
            loading = {loading}
            onChange = {(checked) =>
              showConfirm({
                record    : _id,
                path      : api.status,
                onLoading : () => setLoading(true),
                onSuccess : () => setRefresh((prev) => !prev),
                is_active : checked,
              })
            }
            className={is_active ? "custom-switch-checked" : ""}
          />
        );
      },
    },
    {
      title: "Total Activities",
      dataIndex: "activity",
      key: "activity",
      render: (_, { activityCount, _id }) => {
        const formattedCount = activityCount ? activityCount.toString().padStart(2, '0') : '0';
        return <strong> <Link className="text-danger" to={`/activity/${_id}`}> {formattedCount} </Link> </strong>
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={"Activity"} color={"purple"} key={"activity"}>
              <Button onClick={() => { activity(record._id) }}>
                <i className="fa fa-light fa-plus"></i>
              </Button>
            </Tooltip>
            
            <Tooltip title={"Update " + sectionName} color={"purple"} key={"update"}>
              <Button onClick={() => { edit(record._id) }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>

            <Tooltip title={"View " + sectionName} color={"purple"} key={"view"}>
              <Button onClick={() => { view(record._id) }}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];


  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: api.list + `?type=${STAY_CATEGORY.ACTIVITY}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={sectionName + " Management"}
            extra={
              <>
                <Search
                  size="large"
                  onChange={onSearch}
                  value={searchText}
                  onPressEnter={onSearch}
                  placeholder="Search By Name"
                />
                <Link className="ant-btn ant-btn-default" to={`/${routeName}/add`}>Add New {sectionName}</Link>
              </>
            }
          >
            <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
            <div className="table-responsive customPagination">
              <Table
                loading={loading}
                columns={columns}
                dataSource={list}
                pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                onChange={handleChange}
                className="ant-border-space"
              />
            </div>

          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
