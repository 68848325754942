import { Card, Typography, Row, Col, Skeleton } from 'antd';
import React, { useState, useEffect } from "react";

const { Title } = Typography;

const TodaysWeather = ({data, loading, location}) => {
    
    useEffect(() => {
        if(!location) return;
    });

    const weatherData = {
        condition: data?.current?.condition?.text,
        temp_c: data?.current?.temp_c,
        temp_f: data?.current?.temp_f,
    };

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: getBackgroundColor(weatherData.condition),
    };

    return (
        <Card className="mb-24" title="Today's Weather" bordered={false} style={cardStyle}>
            
            {loading ? [1,2].map(item => <Skeleton active key={item} />) :
                
                <Row gutter={[16, 24]}>

                    {location.latitude && location.longitude ? (
                        <>
                            <Col span={24}>
                                <Row justify="space-between" align="middle" gutter={[16, 0]}>
                                    <Col>
                                        <img src={data?.current?.condition?.icon} width={80} height={80}></img>
                                        {/* <i className={`fas ${getWeatherIcon(weatherData.condition)}`} style={{ fontSize: '48px', color: getIconColor(weatherData.condition) }} /> */}
                                    </Col>

                                    <Col>
                                        <Title level={4} style={{ color: '#ff0000', fontWeight: 'bold' }}>{weatherData.condition}</Title>
                                        <p level={2} style={{ fontWeight: '600' }}>Temp C : {weatherData.temp_c}</p>
                                        <p level={2} style={{ fontWeight: '600' }}>Temp F: {weatherData.temp_f}</p>
                                    </Col>

                                    <Col>
                                        <Title level={5}>Current Location:</Title>
                                        <p>{data?.location?.name}, {data?.location?.region}, {data?.location?.country} </p>
                                    </Col>
                                    <Col>
                                        <Title level={5}>Current Position:</Title>
                                        <p>{location.latitude}, {location.longitude}</p>
                                    </Col>

                                    <Col>
                                        <Title level={5}>Time Zone:</Title>
                                        <p>{data?.location?.tz_id}</p>
                                    </Col>

                                    <Col>
                                        <Title level={5}>Last Updated On:</Title>
                                        <p>{data?.current?.last_updated}</p>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row gutter={[16, 0]}>
                                    <Col span={3}>
                                        <Title level={5}>UI Index: </Title>
                                        <p>{data?.current?.uv}</p>
                                    </Col>

                                    <Col span={3}>
                                        <Title level={5}>Pressure: </Title>
                                        <p>{data?.current?.pressure_mb} mb</p>
                                    </Col>
                                    
                                    <Col span={3}>
                                        <Title level={5}>Visibility: </Title>
                                        <p>{data?.current?.vis_km} Km</p>
                                    </Col>

                                    <Col span={3}>
                                        <Title level={5}>Humidity: </Title>
                                        <p>{data?.current?.humidity}%</p>
                                    </Col>

                                    <Col span={3}>
                                        <Title level={5}>Wind: </Title>
                                        <p>{data?.current?.wind_kph} km/h</p>
                                    </Col>

                                    <Col span={3}>
                                        <Title level={5}>Precipitation: </Title>
                                        <p>{data?.current?.precip_mm} mm</p>
                                    </Col>

                                    <Col span={3}>
                                        <Title level={5}>Feels Like: </Title>
                                        <p>{data?.current?.feelslike_c} C</p>
                                    </Col>

                                    <Col span={3}>
                                        <Title level={5}>AQI (PM2.5): </Title>
                                        <p>{data?.current?.air_quality?.pm10}</p>
                                    </Col>

                                </Row>
                            </Col>
                        </>
                    ) : (
                        <div className="location-container">
                            <div className="location-prompt">
                            <img
                                src={"https://us.123rf.com/450wm/svetlanaborovkova/svetlanaborovkova1903/svetlanaborovkova190300156/124573553-location-pin-global-communications-navigation-gps-and-digital-technologies-label-with-people-and.jpg?ver=6"}
                                alt="Enable Location"
                                className="location-image"
                            />
                            <div className="location-message">
                                <h2>Enable Location Services</h2>
                                <p>
                                To provide you with the most accurate weather information for your area, we need access
                                to your location. Please enable location services in your browser settings.
                                </p>
                                <p><strong>Steps to enable location:</strong></p>
                                <ol className="steps-list">
                                <li>Open your browser settings.</li>
                                <li>Go to Privacy and Security settings.</li>
                                <li>Find Location settings and enable it for this site.</li>
                                </ol>
                                <p>
                                Once enabled, refresh this page to see the weather details for your current location.
                                </p>
                            </div>
                            </div>
                        </div>
                    )}

                </Row>
            }
        </Card>
    );
};

const getBackgroundColor = (condition) => {
    switch (condition?.toLowerCase()) {
        case 'sunny':
            return '#fffacd'; // Light yellow
        case 'cloudy':
            return '#f0f0f0'; // Light grey
        case 'rainy':
            return '#f5f5f5'; // Lighter grey
        case 'stormy':
            return '#dcdcdc'; // Silver
        default:
            return '#ffffff'; // White
    }
};

const getWeatherIcon = (condition) => {
    switch (condition?.toLowerCase()) {
        case 'sunny':
            return 'fa-sun';
        case 'cloudy':
            return 'fa-cloud';
        case 'rainy':
            return 'fa-umbrella';
        case 'stormy':
            return 'fa-bolt';
        default:
            return 'fa-question-circle';
    }
};

const getIconColor = (condition) => {
    switch (condition.toLowerCase()) {
        case 'sunny':
            return '#ffae42'; // Orange
        case 'cloudy':
            return '#6c757d'; // Grey
        case 'rainy':
            return '#0050b3'; // Blue
        case 'stormy':
            return '#6600cc'; // Purple
        default:
            return '#666'; // Default grey
    }
};

export default TodaysWeather;
