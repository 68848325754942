
import { Menu, Button, Modal, Skeleton } from "antd";
import { useState, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { QuestionCircleFilled } from '@ant-design/icons';
import moment, { isMoment } from "moment";

export const menuItems = [
  {
    key: "Dashboard",
    path: "/dashboard",
    icon: "fas fa-tachometer-alt",
    label: "Dashboard"
  },
  {
    key: "SubAdminSection",
    path: "/sub-admin",
    icon: "fas fa-user-cog",
    label: "Sub Admin Manager"
  }, 
  {
    key: "UserSection",
    path: "/user",
    icon: "fas fa-users",
    label: "User Manager"
  },
  {
    key: "BannerSection",
    path: "/banner",
    icon: "far fa-images",
    label: "Banner Manager"
  }, 
  {
    key: "LocationInsightsSection",
    label: "Location Insights",
    icon: "fas fa-map-marker-alt",
    children: [
      {
        key: "LocationInsights",
        path: "/location-insights",
        label: "All Insights"
      },
      {
        key: "MainCategory",
        path: "/category/main",
        label: "Main Category"
      },
      {
        key: "SubCategorySection",
        path: "/category/sub",
        label: "Sub Category"
      }
    ]
  },
  /* {
    key: "LocationInsightsSection",
    path: "/location-insights",
    icon: "fas fa-map-marker-alt",
    label: "Location Insights"
  }, */
  {
    key: "RegionalsSection",
    label: "Regional Segments",
    icon: "fas fa-flag",
    children: [
      {
        key: "CountrySubSection",
        path: "/country",
        label: "Country Manager"
      },
      {
        key: "StateSubSection",
        path: "/state",
        label: "State Manager"
      },
      {
        key: "CitySubSection",
        path: "/city",
        label: "City Manager"
      } 
    ]
  },
  /* {
    key: "CountrySection",
    path: "/country",
    icon: "fas fa-flag",
    label: "Country Manager"
  },
  {
    key: "StateSection",
    path: "/state",
    icon: "fas fa-flag",
    label: "State Manager"
  },
  {
    key: "CityMenu",
    path: "/city",
    label: "City Manager",
    icon: "fas fa-city",
  }, */
  {
    key: "AppHomeMenu",
    path: "/app-home",
    label: "App Home Screen",
    icon: "fas fa-city",
  },
  {
    key: "LanguageMenu",
    path: "/language",
    label: "Language Manager",
    icon: "fas fa-language",
  },
  {
    key: "ContactFeedbackMenu",
    path: "/contact-feedback",
    label: "Contact / Feedback",
    icon: "fas fa-headset",
  },
  {
    key: "MastersSection",
    label: "Master Manager",
    icon: "fab fa-servicestack",
    children: [
      {
        key: "FilterSubSection",
        path: "/master-filter",
        label: "All Filter"
      },
      {
        key: "HobbiesSubSection",
        path: "/master/hobbies",
        label: "Hobbies"
      },
      {
        key: "OccupationSubSection",
        path: "/master/occupation",
        label: "Occupation"
      }, 
    ]
  },
  {
    key: "ActivitySection",
    path: "/activity/category",
    icon: "fas fa-filter",
    label: "Activity Manager"
  },
  /* {
    key: "MasterFilterSection",
    path: "/master-filter",
    icon: "fas fa-filter",
    label: "Filter"
  }, */
  /* {
    key: "PackageSection",
    path: "/package",
    icon: "fas fa-globe-asia",
    label: "Package Manager"
  }, */
  {
    key: "subscriptionSection",
    path: "/subscription",
    icon: "fa fa-file",
    label: "Subscription Manager"
  },
  {
    key: "StaySection",
    label: "Stay Manager",
    icon: "fas fa-hotel",
    children: [
      {
        key: "Category",
        path: "/stay/category",
        label: "Category Manager"
      },
    ]
  },
  {
    key: "ApplicationFormSection",
    path: "/application-form",
    icon: "fas fa-mobile-alt",
    label: "Application Forms"
  },
  {
    key: "BlogMenu",
    path: "/blog",
    label: "Blog Manager",
    icon: "fas fa-envelope",
  },
  {
    key: "ReferralSection",
    path: "/referral-reward",
    icon: "fas fa-share-alt",
    label: "Referral Reward"
  },
  {
    key: "WalletTransactionSection",
    path: "/transaction/wallet",
    icon: "fas fa-rupee-sign",
    label: "Wallet Transaction"
  },
  {
    key: "ContentSection",
    path: "/content",
    icon: "fa fa-file",
    label: "Content Manager"
  },
  {
    key: "EmailSection",
    path: "/email-template",
    icon: "fas fa-envelope",
    label: "Email Template Manager"
  }, 
  {
    key: "SettingSection",
    path: "/setting",
    icon: "fas fa-cog",
    label: "Setting Manager"
  },
]

function Sidenav({ color }) {

  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { userProfile, logout, setUserProfile } = useContext(AuthContext)
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState('vertical');
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  const showDeleteConfirm = (record) => {
    setTimeout(() => {
      confirm({
        okText: 'Logout',
        okType: 'danger',
        icon: <QuestionCircleFilled />,
        cancelText: 'Cancel',
        content: <Button >Are you sure you want to logout ?</Button>,
        onOk() {
          logout();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const renderTitle = (item) => {
    return (
      <>
        <span className="icon"><i className={item.icon}></i></span>
        <span className="label">{item.label}</span>
      </>
    )
  }

  useEffect(() => {
    setLoading(true)
    if (!userProfile) return
    if (userProfile.user_type == 'Admin') {
      setMenuLinks(menuItems)
      setLoading(false)
      return
    }
    const newArray = menuItems.filter(item => {
      if (item.children) {
        item.children = item.children.filter(child => userProfile.permission?.hasOwnProperty(child.key));
      } else {
        return userProfile.permission?.hasOwnProperty(item.key)
      }
      return item
    });

    const links = newArray.filter(item => {
      if (item?.children?.length) {
        return true
      } else if (!item?.children) {
        return true
      } else {
        return false
      }
    })
    setMenuLinks(links)
  }, [userProfile])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode('inline');
      } else {
        setMenuMode('vertical');
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="brand w-100">
        <NavLink to="/dashboard" className="imgOuter"> Trip N Tales </NavLink>
      </div>
        
      <p className="text-center mt-1">Current TimeZone : {currentDateTime.format("YYYY-MM-DD hh:mm A")}</p>

      {
        loading ? [1, 2, 3, 4, 5, 6].map(item => <Skeleton active key={item} />)
          :
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
            {menuLinks.map(item => {
              if (item.children) {
                return (
                  <Menu.SubMenu key={item.key} title={<>
                    <span className="icon"><i className={item.icon}></i></span>
                    <span className="label">{item.label}</span>
                  </>}>
                    {item.children.map(child => (
                      <Menu.Item key={child.key}>
                        <NavLink to={child.path}>{child.label}</NavLink>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              }

              return (
                <Menu.Item key={item.key}>
                  <NavLink to={item.path}>
                    {renderTitle(item)}
                  </NavLink>
                </Menu.Item>
              );
            })}


            <Menu.Item >
              <a onClick={showDeleteConfirm}>
                <>
                  <span className="icon"><i className="fas fa-sign-out-alt"></i></span>
                  <span className="label">Logout </span>
                </>
              </a>
            </Menu.Item>

          </Menu>
      }

    </>
  );

}

export default Sidenav;
