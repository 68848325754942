import {Button, Modal } from "antd";
import { ShowToast, Severty } from "../helper/toast";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useRequest from "../hooks/useRequest";
const { confirm } = Modal;

const ConfirmationBox = () => {

  const { request } = useRequest();

  const showConfirm = ({record, path, onLoading, onSuccess, type}) => {
    /* setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button>{type == 'approval' ? 'Do you really want to proceed with changing the approval status?' : 'Please confirm if you would like to update the status ?'}</Button>,
        onOk() {
          if(type == 'approval') {
            approvalStatusChange(record, path, onLoading, onSuccess, type);
          } else {
            statusChange(record, path, onLoading, onSuccess, type);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5); */

    if(type == 'approval') {
      approvalStatusChange(record, path, onLoading, onSuccess, type);
    } else {
      statusChange(record, path, onLoading, onSuccess, type);
    }
    
  };

  const statusChange = (record, path, onLoading, onSuccess, type) => {
    onLoading(true);
    let url = '';
    if(type !== null && type !== undefined){
      url   =   path + "/" + type + "/" + record
    }else{
      url   =   path + "/" + record
    }
    request({
      url: url,
      method: 'GET',
      onSuccess: (data) => {
        onSuccess();
        onLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const approvalStatusChange = (record, path, onLoading, onSuccess, type) => {
    onLoading(true);
    request({
      url: path,
      method: 'POST',
      data : record,
      onSuccess: (data) => {
        onSuccess();
        onLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  return { showConfirm }

};

export default ConfirmationBox;