import {Row, Col, Card, Button, Input, Form, Divider, Tabs, Select, Badge} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import languages from "../../config/language";
import { Link } from "react-router-dom";
const { TabPane } = Tabs;

function Add() {

    const sectionName   =   "Subscription";
    const routeName     =   "subscription";

    const api = {
        addEdit   :  apiPath.addEditSubscription,
    }
    
    const [form]                  =   Form.useForm();
    const { request }             =   useRequest()
    const [loading, setLoading]   =   useState(false)
    const navigate                =   useNavigate();
    const [validationStatus, setValidationStatus]   =   useState({});
    
    const handleFieldChange = (data, lang, type) => {
        const name   =   form.getFieldValue(`${lang}_name`);
        
        setValidationStatus(prevStatus => ({
            ...prevStatus, 
            [lang]: !!name
        }));
    }

    useEffect(() => {
        const initialValidationStatus = {};
        
        languages.forEach(lang => {
            initialValidationStatus[lang.short] = !!form.getFieldValue(`${lang.short}_name`);
        });

        setValidationStatus(initialValidationStatus);
    }, [])

    const onCreate = () => {

        let payload = {
            ...form.getFieldsValue(),
            description: {
                notification: form.getFieldValue('notification'),
                no_of_like: form.getFieldValue('no_of_like')
            }
        };

        // Remove the original fields that have been nested inside 'description'
        delete payload.notification;

        setLoading(true)
        request({
            url: api.addEdit,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    return (
        <Card title={"Add " + sectionName}>
            <Form className="edit-page-wrap" form={form} onFinish={onCreate} autoComplete="off" layout="verticle" name="subscription_form">
                
                <Row gutter={[24, 0]}>

                    <Col span={12} xs={12} sm={12} md={24}>
                        <Tabs defaultActiveKey={languages[0].short}>
                            {languages.map(lang => (

                                <TabPane tab={<span>{lang.long} {validationStatus[lang.short] === false && <Badge dot color="red" />}</span>} key={lang.short}>
                                                                    
                                    <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                                        rules={[
                                            ...(lang.short === 'en' ? [
                                                { required: true, message: `Please enter the name in ${lang.long}!` }
                                            ] : []),
                                            { max: 100, message: "Name should not contain more than 100 characters!" },
                                            { min: 2, message: "Name should contain at least 2 characters!" },
                                        ]}
                                        initialValue={form.getFieldValue(`${lang.short}_name`)}
                                        onChange={(data) => handleFieldChange(data, lang.short, 'title')}
                                    >

                                        <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>

                                    </Form.Item>
                                
                                </TabPane>
                            ))}
                        </Tabs>
                    </Col>

                    <Col span={12} xs={12} sm={12} md={12}>
                        <Form.Item name="type" label="Select Subscription Type" 
                            rules={[
                                { required: true, message: 'Please seelct the type' },
                            ]}
                        >
                            <Select placeholder="Select Subscription Type">
                                <option key={'Monthly'} value={'Monthly'}>Monthly</option>
                                <option key={'Yearly'} value={'Yearly'}>Yearly</option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12} xs={12} sm={12} md={12}>
                        <Form.Item name="price" label="Price (INR)" 
                            rules={[
                                { required: true, message: 'Please enter the price' },
                                { max: 10, message: "Price should not contain more than 10 digits!" },
                                { min: 1, message: "Price should contain at least 1 digits!" },
                                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" }
                            ]}
                        >
                            <Input autoComplete="off" placeholder={`Enter Price (INR)`}/>
                        </Form.Item>
                    </Col>

                    <Divider orientation="left" orientationMargin={15} className="devider-color">{"Subscription Detail"}</Divider>

                    <Col span={12} xs={12} sm={12} md={6}>
                        <Form.Item name="notification" label="Notification" 
                            rules={[
                                { required: true, message: 'Please enter the notification' },
                                { max: 10, message: "Notification should not contain more than 10 digits!" },
                                { min: 1, message: "Notification should contain at least 1 digits!" },
                                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" }
                            ]}
                        >
                            <Input autoComplete="off" placeholder={`Enter Notification`}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item className="btn-row float-right">
                    <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                    <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Add;
