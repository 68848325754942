import {Row, Col, Card, Button, Input, Form, Tabs, Badge} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import languages from "../../config/language";
import { Link } from "react-router-dom";
const { TabPane } = Tabs;

function Add() {

    const sectionName   =   "App Home";
    const routeName     =   "app-home";

    const api = {
        addEdit   :  apiPath.addEditCountry,
    }
    
    const [form]                            =   Form.useForm();
    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const navigate                          =   useNavigate();
    const [validationStatus, setValidationStatus] = useState({});

    const handleFieldChange = (data, lang, type) => {
        const name                  =   form.getFieldValue(`${lang}_name`);
        setValidationStatus(prevStatus => ({
            ...prevStatus, 
            [lang]: !!name
        }));
    }

    useEffect(() => {
        const initialValidationStatus = {};
        languages.forEach(lang => {
            initialValidationStatus[lang.short] = !!form.getFieldValue(`${lang.short}_name`) && !!form.getFieldValue(`${lang.short}_description`);
        });
        setValidationStatus(initialValidationStatus);
    }, [])

    const onCreate = (values) => {
        let payload = {
            ...values,
        };

        setLoading(true)
        request({
            url: api.addEdit,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    return (
        <Card title={"Add New " + sectionName}>
            
            <Form className="edit-page-wrap" form={form} onFinish={onCreate} autoComplete="off" layout="verticle" name="country_form">
                
                <Row gutter={[24, 0]}>

                    <Col md={24}>
                        <Tabs defaultActiveKey={languages[0].short}>
                            {languages.map(lang => (
                                <TabPane tab={<span>{lang.long} {validationStatus[lang.short] === false && <Badge dot color="red" />}</span>} key={lang.short}>

                                    <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                                        rules={[
                                            ...(lang.short === 'en' ? [
                                                { required: true, message: `Please enter the name in ${lang.long}!` }
                                            ] : []),
                                            { max: 200, message: "Name should not contain more than 200 characters!" },
                                            { min: 2, message: "Name should contain at least 2 characters!" },
                                        ]}
                                        onChange={(data) => handleFieldChange(data, lang.short, 'title')}
                                    >
                                        <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>
                                    </Form.Item>
                                
                                    {/* <Form.Item label={`Description (${lang.long})`} name={`${lang.short}_description`}>
                                        <DescriptionEditor placeholder={`Enter Description in ${lang.long}`} />
                                    </Form.Item> */}
                                </TabPane>
                            ))}
                        </Tabs>
                    </Col>
                </Row>
                        
                <Form.Item className="btn-row float-right">
                    <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                    <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Add;
