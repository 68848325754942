import {Row, Col, Card, Button, Input, Form, Divider, Space, Skeleton, Image} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import SingleImageUpload from "../../components/SingleImageUpload";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import languages from "../../config/language";
import { Link, useParams } from "react-router-dom";

function Addon() {

    const sectionName   =   "Activity Addon";
    const routeName     =   "activity";

    const api = {
        addEditAddon    :  apiPath.addEditActivityAddon,
        view            :  apiPath.viewActivity,
    }
    
    const [form]                  =   Form.useForm();
    const { request }             =   useRequest()
    const [loading, setLoading]   =   useState(false)
    const params                  =   useParams();
    const navigate                =   useNavigate();
    const [image, setImage]       =   useState([]);
    const FileType                =   ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/svg", "image/gif"]
    
    const addMainField = () => {
        const currentFields = form.getFieldValue('addon_field') || [];
        form.setFieldsValue({ addon_field: [...currentFields, {}] });
    };
    
    const removeMainField = (index) => {
        const fields = form.getFieldValue('addon_field') || [];
        form.setFieldsValue({ addon_field: fields.filter((_, i) => i !== index) });
    };
    
    const removeInsiderField = (mainIndex, insideIndex) => {
        const fields = form.getFieldValue(['addon_field', mainIndex, 'inside_fields']) || [];
        form.setFieldsValue({
            addon_field: form.getFieldValue('addon_field').map((field, i) =>
                i === mainIndex ? { ...field, inside_fields: fields.filter((_, j) => j !== insideIndex) } : field
            ),
        });
    };

    const handleImage = (data) =>{
        setImage(data);
    }
    
    const onAddon = (values) => {
        const type          =   values.type;
        let updatedValues   =   JSON.parse(JSON.stringify(values));
        let addOnData       =   values?.addon_field?.map(addon => ({
                                    ...addon,
                                    inside_fields: addon.inside_fields.map(field => ({
                                        ...field,
                                        image: Array.isArray(field.image) ? field.image[0]?.url : field.image || null
                                    }))
                                }))
    
        let payload = {
            ...updatedValues,
            type : 'add-on',
            addon_field : addOnData
        };

        setLoading(true)
        request({
            url: api.addEditAddon + "/" + params.id,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    const fetchData = (id) => {
        setLoading(true);
        request({
            url: api.view + "/" + id,
            method: 'GET',
            onSuccess: (response) => {
                const { data } = response;
               
                setTimeout(() => {
                    form.setFieldsValue({...data});
                    setLoading(false);
                }, 1);

            },
            onError: (error) => {
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    useEffect(() => {
        fetchData(params.id);
    }, [])

    return (
        <Card title={sectionName}>
            {loading ? [1,2,3,4,5,6,7,8].map(item => <Skeleton active key={item} />) :
                <Form className="edit-page-wrap" form={form} onFinish={onAddon} autoComplete="off" layout="verticle" name="blog_form">
                    
                    <Row gutter={[24, 0]}>

                        <Col span={24} xs={24} sm={24} md={24}>
                            <Form.List name="addon_field">
                                {(fields, { add }) => (
                                    <>
                                        {fields.map(({ key, name }, index) => (
                                            <div key={key} style={{ marginBottom: '20px' }}>
                                                <div align="baseline" className="gap-cls">
                                                
                                                    <Row gutter={16}>
                                                        <Col span={24}>
                                                            <Divider orientation="left" orientationMargin={0} className="devider-color">
                                                            {`Main Field - ${index + 1}`}
                                                            </Divider>
                                                        </Col>

                                                        {/* Main Field Specific Form Items */}
                                                        <Col span={24}>
                                                            <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                                {'Heading'}
                                                            </Divider>

                                                            <Row gutter={16}>
                                                                {languages.map((lang) => (
                                                                    <Col span={8} key={`${key}_${lang.short}`}>
                                                                        <Form.Item
                                                                        className="qty-cls"
                                                                        name={[name, `${lang.short}_heading`]}
                                                                        label={`Heading (${lang.long})`}
                                                                        rules={[
                                                                            ...(lang.short === 'en' ? [
                                                                                { required: true, message: `Please enter the heading in ${lang.long}!` }
                                                                            ] : []),
                                                                        ]}
                                                                        >
                                                                        <Input autoComplete="off" placeholder={`Enter Heading in ${lang.long}`} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Col>
                                                        
                                                        <Col span={24}>
                                                            {index > 0 && (
                                                                <div className="minus-wrap" style={{ marginBottom: '8px' }}>
                                                                    <MinusCircleOutlined
                                                                        onClick={() => removeMainField(index)}
                                                                        style={{ borderRadius: '8px' }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>

                                                    {/* Insider Fields Section */}
                                                    <Form.List name={[name, 'inside_fields']}>
                                                        {(insideFields, { add }) => (
                                                            <>
                                                                {insideFields.map(({ key: insideKey, name: insideName }, insideIndex) => (
                                                                    <div key={insideKey} style={{ marginBottom: '20px' }}>
                                                                        <Space align="baseline" className="gap-cls">
                                                                            <Row gutter={16}>
                                                                                <Col span={24}>
                                                                                    <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                                                        {`Insider Field Title - ${insideIndex + 1}`}
                                                                                    </Divider>

                                                                                    <Row gutter={16}>
                                                                                        {languages.map((lang) => (
                                                                                            <Col span={8} key={`${key}_${lang.short}`}>
                                                                                                <Form.Item
                                                                                                className="qty-cls"
                                                                                                name={[insideName, `${lang.short}_title`]}
                                                                                                label={`Title (${lang.long})`}
                                                                                                rules={[
                                                                                                    ...(lang.short === 'en' ? [
                                                                                                        { required: true, message: `Please enter the title in ${lang.long}!` }
                                                                                                    ] : []),
                                                                                                ]}
                                                                                                >
                                                                                                <Input autoComplete="off" placeholder={`Enter Title in ${lang.long}`} />
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                        ))}
                                                                                    </Row>

                                                                                    <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                                                        {`Insider Field Sub Title - ${insideIndex + 1} `}
                                                                                    </Divider>

                                                                                    <Row gutter={16}>
                                                                                        {languages.map((lang) => (
                                                                                            <Col span={8} key={`${key}_${lang.short}`}>
                                                                                                <Form.Item
                                                                                                    className="qty-cls"
                                                                                                    name={[insideName, `${lang.short}_sub_title`]}
                                                                                                    label={`Sub Title (${lang.long})`}
                                                                                                    rules={[
                                                                                                        ...(lang.short === 'en' ? [
                                                                                                            { required: true, message: `Please enter the sub title in ${lang.long}!` }
                                                                                                        ] : []),
                                                                                                    ]}
                                                                                                >
                                                                                                <Input autoComplete="off" placeholder={`Enter Sub Title in ${lang.long}`} />
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                        ))}
                                                                                    </Row>


                                                                                    <Col span={6}>
                                                                                        <Form.Item className="mt-2" {...insideName} name={[insideName, 'image']}>
                                                                                            <SingleImageUpload
                                                                                                value={image}
                                                                                                fileType={FileType}
                                                                                                imageType={'location-insights'}
                                                                                                btnName={'Image'}
                                                                                                onChange={(data) => handleImage(data)}
                                                                                            />
                                                                                        </Form.Item>

                                                                                        {form.getFieldValue(['addon_field', index, 'inside_fields', insideIndex, 'image']) && (
                                                                                            <Image
                                                                                                width={150}
                                                                                                src={form.getFieldValue(['addon_field', index, 'inside_fields', insideIndex, 'image'])}
                                                                                                fallback="https://via.placeholder.com/200"
                                                                                            />
                                                                                        )}

                                                                                    </Col>

                                                                                    {insideIndex > 0 && (
                                                                                        <div className="minus-wrap" style={{ marginBottom: '8px' }}>
                                                                                            <MinusCircleOutlined
                                                                                            onClick={() => removeInsiderField(index, insideIndex)}
                                                                                            style={{ borderRadius: '8px' }}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </Col>
                                                                            </Row>
                                                                        </Space>
                                                                    </div>
                                                                ))}

                                                                <Form.Item>
                                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                        Add Title, Sub Title, & Image
                                                                    </Button>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </div>
                                            </div>
                                        ))}

                                        <Form.Item>
                                            <Button type="dashed" onClick={addMainField} block icon={<PlusOutlined />}>
                                                Add Main Addon
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                            
                    <Form.Item className="btn-row float-right">
                        <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                    </Form.Item>
                </Form>
            }
        </Card>
    )
}

export default Addon;