import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Avatar, Switch } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce"; 
import ShowTotal from "../../components/ShowTotal";
import moment from 'moment';
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import UserFrom from "../../pages/User/UserFrom";
import ConfirmationBox from "../../components/ConfirmationBox";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {

  const sectionName = "Sub Admin";
  const routeName   = "sub-admin";

  const api = {
    status  : apiPath.statusSubAdmin,
    addEdit : apiPath.addEditSubAdmin,
    list    : apiPath.listSubAdmin
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const permission = (id) => {
    navigate(`/${routeName}/permission/${id}`)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, { name, _id, image }) => {
        return (
          <>
            <Avatar className="cap" size={40}> {name.charAt(0)} </Avatar>
            <a style={{ marginLeft: 12 }} className="cap avatar-text" onClick={(e) => view(_id)}>{name}</a>
          </>
        );
      },
    },
    {
      title: "Mobile Number",
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+965") + (mobile_number ? mobile_number : '')
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (_, { email }) => {
        return email ? email : '-';
      },
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        return (
          <Switch
            checked = {is_active}
            checkedChildren = {<CheckOutlined />}
            unCheckedChildren = {<CloseOutlined />}
            loading = {loading}
            onChange = {(checked) =>
              showConfirm({
                record    : _id,
                path      : api.status,
                onLoading : () => setLoading(true),
                onSuccess : () => setRefresh((prev) => !prev),
                is_active : checked,
              })
            }
            className={is_active ? "custom-switch-checked" : ""}
          />
        );
      },
    },
    {
      title: "Registered On",
      key: "created_at",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={"Update " + sectionName} color={"purple"} key={"update"+routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>

            <Tooltip title={"View " + sectionName} color={"purple"} key={"view"+routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>

            <Tooltip title={"Permission " + sectionName} color={"purple"} key={"permission"+routeName}>
              <Button title="Permission" onClick={(e) => permission(record._id)}>
                <i className="fa fa-light fa-key"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: api.list + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };


  const handleChangeDate = (e) => {
    if(e!=null){
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    }else{
      setStartDate()
      setEndDate()
    }
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Sub Admin Manager"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder="Search By Name, Mobile, Email"
                  />
                  <RangePicker onChange={handleChangeDate} />
                  <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add Sub Admin</Button>
                </>
              }
            >
              
              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>


      {visible && <UserFrom type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {/* {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />} */}

    </>
  );
}

/* const AddFrom = ({ show, hide, data, refresh }) => {
  
  const [mobileNumber, setMobileNumber] = useState({
    mobile: '',
    country_code: '',
  });
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)

  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
  }
  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
    setMobileNumber({
      mobile: data.mobile_number,
      country_code: data.country_code
    })
  }, [data])

  const onCreate = (values) => {
    if (!mobileNumber.mobile) return ShowToast('Please enter mobile number', Severty.ERROR)
    console.log((mobileNumber.mobile).length,"kjjjjj");
    if (((mobileNumber.mobile).length < 8 || (mobileNumber.mobile).length > 12)) {
      return ShowToast('Mobile number should be between 8 to 12 digits', Severty.ERROR);
    }
    const { name, email } = values
    const payload = {}
    setLoading(true)
    payload.country_code = mobileNumber.country_code;
    payload.mobile_number = mobileNumber.mobile;
    payload.name = name;
    payload.email = email;
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Update Sub Admin' : 'Create a New Sub Admin'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please Enter the name!" },
                { max: 50, message: "Name should not contain more then 50 characters!" },
                { min: 2, message: "Name should contain atleast 2 characters!" },
                { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "Only Alphabetic Characters Allowed" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Name"/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: 'email', message: 'The enter is not valid E-mail!',
                },
                { required: true, message: "Please enter the email!" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Email Address"/>
            </Form.Item>

          </Col>

          <Col span={24}>
            <Form.Item label="Mobile Number">
              <PhoneInput inputProps={{
                name: "mobile",
                required: true,
                autoFocus: false,
                placeholder: "Enter Mobile Number"
              }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else if (value.match(/1234/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"om"} value={mobileNumber ? ((mobileNumber.country_code ? mobileNumber.country_code : "+965") + (mobileNumber.mobile ? mobileNumber.mobile : null)) : '+965'} onChange={handleChange} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
} */

export default Index;
