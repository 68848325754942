import {Row, Col, Card, Table, Button, Input, Tabs, Tooltip, DatePicker} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import ShowTotal from "../../components/ShowTotal";
import { useNavigate } from "react-router";
import ConfirmationBox from "../../components/ConfirmationBox";
import apiPath from "../../constants/apiPath";
import moment from 'moment';
import { CONTACT_FEEDBACK_TYPE } from "../../constants/conts";
const Search = Input.Search;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function Index() {

  const sectionName   =   "Contact Feedback";
  const routeName     =   "contact-feedback";

  const api = {
    list  : apiPath.listContactFeedback
  }

  const [searchText, setSearchText] =   useState('');
  const { request }                 =   useRequest()
  const [list, setList]             =   useState([])
  const { showConfirm }             =   ConfirmationBox()
  const [loading, setLoading]       =   useState(false)
  const [refresh, setRefresh]       =   useState(false)
  const [pagination, setPagination] =   useState({ current: 1, pageSize: 10 });
  const debouncedSearchText         =   useDebounce(searchText, 300);
  const [type, setType]             =   useState(CONTACT_FEEDBACK_TYPE.CONTACT_US);
  const [startDate, setStartDate]   =   useState();
  const [endDate, setEndDate]       =   useState();
  const navigate                    =   useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (_, { title }) => {
        return (
          title ? <span className="cap">{title}</span> : '-'
        );
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      sorter: (a, b) => a.user.localeCompare(b.user),
      render: (user) => {
        const { name, email } = user || {};

        return user ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Email : {email}</div>
          </div>
        ) : '-';
      }
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={"View " + sectionName} color={"purple"} key={"view"}>
              <Button onClick={() => { view(record._id) }}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const fetchData = (pagination, type) => {
    request({
      url: api.list + `?type=${type}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs);
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.list.totalDocs, pageSize: Number(data.data.list.limit) }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleTabChange = (type) => {   
    setType(type);
    fetchData(pagination, type);
  };

  const handleChange = (pagination) => {
    fetchData(pagination);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1, pageSize: pagination.pageSize })
  };

  const handleChangeDate = (e) => {
    setPagination({ current: 1, pageSize: pagination.pageSize })
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchData(pagination, type);
  }, [refresh, debouncedSearchText, startDate, endDate])

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={sectionName + " Manager"}
            extra={
              <>
                <Search
                  size="large"
                  onChange={onSearch}
                  value={searchText}
                  onPressEnter={onSearch}
                  placeholder="Search By User Name / Title"
                />

                <RangePicker
                  placeholder={["Start Date", "End Date"]}
                  onChange={handleChangeDate}
                  disabledDate={(current) =>
                    current.isAfter(moment().subtract(0, "day"))
                  }
                />
              </>
            }
          >
            <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>

            <div className="custom-tab">
              <Tabs
                onTabClick={handleTabChange}
                className="min-font-bold"
                tabBarStyle={{ color: "Black" }}
              >
                <TabPane tab={"Contact Us"} key={CONTACT_FEEDBACK_TYPE.CONTACT_US}>
                  <div className="table-responsive customPagination">
                    <Table
                      loading={loading}
                      columns={columns}
                      dataSource={list}
                      pagination={{
                        current: pagination.current,
                        defaultPageSize: 10,
                        responsive: true,
                        total: pagination.total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                      }}
                      onChange={handleChange}
                      className="ant-border-space"
                    />
                  </div>
                </TabPane>

                <TabPane tab={"Custom FAQ"} key={CONTACT_FEEDBACK_TYPE.CUSTOM_FAQ}>
                  <div className="table-responsive customPagination">
                    <Table
                      loading={loading}
                      columns={columns}
                      dataSource={list}
                      pagination={{
                        current: pagination.current,
                        defaultPageSize: 10,
                        responsive: true,
                        total: pagination.total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                      }}
                      onChange={handleChange}
                      className="ant-border-space"
                    />
                  </div>
                </TabPane>

                <TabPane tab={"Suggestion"} key={CONTACT_FEEDBACK_TYPE.SUGGESTION}>
                  <div className="table-responsive customPagination">
                    <Table
                      loading={loading}
                      columns={columns}
                      dataSource={list}
                      pagination={{
                        current: pagination.current,
                        defaultPageSize: 10,
                        responsive: true,
                        total: pagination.total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                      }}
                      onChange={handleChange}
                      className="ant-border-space"
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>

            {/* <div className="table-responsive customPagination">
              <Table
                loading={loading}
                columns={columns}
                dataSource={list}
                pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                onChange={handleChange}
                className="ant-border-space"
              />
            </div> */}

          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
