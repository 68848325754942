import { Row, Col, Radio, Modal, Form, Input, Select } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../../components/LocationMap";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { INDIACOUNTRY } from "../../constants/conts";
const { Option } = Select;

const UserFrom = ({ type, path, sectionName, show, hide, data, refresh }) => {

  const api = {
    country : apiPath.commonCountry,
    state   : apiPath.commonState,
    city    : apiPath.commonCity,
  }

  const [form]                          =   Form.useForm();
  const { request }                     =   useRequest();
  const [loading, setLoading]           =   useState(false);
  const [mobileNumber, setMobileNumber] =   useState({ mobile: '', country_code: '' });
  const [latLong, setlatLong]           =   useState({ lat: 30.5595, lng: 22.9375 });
  const [userAddress, setUserAddress]   =   useState(null);
  const [countryList, setCountryList]   =   useState([]);
  const [stateList, setStateList]       =   useState([]);
  const [cityList, setCityList]         =   useState([]);

  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
  }

  const handleCountryChange = (e) => {
    getStateList(e);
  }
  
  const handleStateChange = (e) => {
    getCityList(e);
  }

  const getCountryList = () => {
    request({
      url: api.country,
      method: 'GET',
      onSuccess: (response) => {
        setCountryList(response.data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getStateList = (country_id) => {
    request({
      url: api.state + "/" + country_id,
      method: 'POST',
      onSuccess: (response) => {
        setStateList(response.data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getCityList = (state_id) => {
    request({
      url: api.city + "/" + state_id,
      method: 'POST',
      onSuccess: (response) => {
        setCityList(response.data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const handleLocationChange = (value) => {
    setUserAddress(value.address);
    setlatLong({ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) });
  }

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ ...data, country_id : data?.country?._id, state_id : data?.state?._id, city_id : data?.city?._id });
    setlatLong({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) });
    setUserAddress(data.location);
    // Call City API
    data?.state?._id && getCityList(data?.state?._id);
    // End City API
    setMobileNumber({
      mobile: data.mobile_number,
      country_code: data.country_code
    })
  }, [data])

  const onCreate = (values) => {
    if (!mobileNumber.mobile) return ShowToast('Please enter mobile number', Severty.ERROR)
    if (((mobileNumber.mobile).length < 8 || (mobileNumber.mobile).length > 12)) {
      return ShowToast('Mobile number should be between 8 to 12 digits', Severty.ERROR);
    }
    const payload = {
      ...values,
      otp_verification: data && data.is_otp_verify ? data.is_otp_verify : false,
      mobile_number: mobileNumber.mobile,
      country_code: mobileNumber.country_code,
    }
    
    setLoading(true);
    request({
      url: `${data ? path + "/" + data._id : path}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };

  useEffect(() => {
    getCountryList();
  }, [])

  return (
    <Modal
      width={1100}
      visible={show}
      title={`${data ? 'Update ' + sectionName : 'Create a New ' + sectionName}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} xs={12} sm={12} md={8}>
            <Form.Item className="mb-0"
              label="Name"
              name="name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the name!" },
                { max: 20, message: "Name should not contain more then 20 characters!" },
                { min: 2, message: "Name should contain at least 2 characters!" },
                { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "Only Alphabetic Characters Allowed!" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Name" />
            </Form.Item>
          </Col>

          <Col span={24} xs={12} sm={12} md={8}>
            <Form.Item className="mb-0"
              label="Email Address"
              name="email"
              rules={[
                { type: 'email', message: 'The email is not a valid email!' },
                { required: true, message: "Please enter the email!" },
                { max: 50, message: "Email should not contain more then 50 characters!" },
                { min: 5, message: "Email should contain at least 5 characters!" },
                {
                  pattern: new RegExp(/^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/),
                  message: "Enter valid email!"
                }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Email Address" />
            </Form.Item>
          </Col>

          <Col span={24} xs={12} sm={12} md={8} className="flagMobileNumber">
            <Form.Item className="mb-0" 
            label="Mobile Number">
              <PhoneInput inputProps={{
                name: "mobile",
                required: true,
                autoFocus: false,
                placeholder: "Enter Mobile Number"
              }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else if (value.match(/1234/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"in"} value={mobileNumber ? ((mobileNumber.country_code ? mobileNumber.country_code : "+91") + (mobileNumber.mobile ? mobileNumber.mobile : null)) : '+91'} onChange={handleChange} />
            </Form.Item>
          </Col>

          {type !== 'Sub Admin' ?
          <>
            <Col span={12} xs={12} sm={12} md={8}>
              <Form.Item className="banner-elt"
                  name="country_id"
                  label="Select Country"
                  rules={[{ required: true, message: 'Missing Country Selection' }]}
              >
                  <Select
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      onChange={handleCountryChange}
                      filterOption={(input, option) =>
                          option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                  >
                      {countryList?.map((item, index) => (
                          <Option key={index} value={item._id}>
                              <span className="cap">{item.en_name}</span>
                          </Option>
                      ))}
                  </Select>
              </Form.Item>
            </Col>

            <Col span={12} xs={12} sm={12} md={8}>
              <Form.Item className="banner-elt"
                  name="state_id"
                  label="Select State"
                  rules={[{ required: true, message: 'Missing State Selection' }]}
              >
                  <Select
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      onChange={handleStateChange}
                      filterOption={(input, option) =>
                          option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                  >
                      {stateList?.map((item, index) => (
                          <Option key={index} value={item._id}>
                              <span className="cap">{item.en_name}</span>
                          </Option>
                      ))}
                  </Select>
              </Form.Item>
            </Col>

            <Col span={12} xs={12} sm={12} md={8}>
              <Form.Item className="banner-elt"
                  name="city_id"
                  label="Select City"
                  rules={[{ required: true, message: 'Missing City Selection' }]}
              >
                  <Select
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                  >
                      {cityList?.map((item, index) => (
                          <Option key={index} value={item._id}>
                              <span className="cap">{item.en_name}</span>
                          </Option>
                      ))}
                  </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Location (Drag Marker for Selecting Location)"
                name="location"
                rules={[
                  { required: true, message: "Please select the location!" },
                ]}
              >
                <LocationMap onChange={handleLocationChange} userData={data && data} />
              </Form.Item>
            </Col>
          </>
          : null}
        </Row>
      </Form>
    </Modal>
  )
};

export default UserFrom;