import {Row, Col, Card, Button, Input, Form, Tabs, Image, Badge, Skeleton, Select} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import apiPath from "../../../constants/apiPath";
import DescriptionEditor from '../../../components/DescriptionEditor'
import SingleImageUpload from "../../../components/SingleImageUpload";
import languages from "../../../config/language";
import notfound from "../../../assets/images/not_found.png";
import { ShowToast, Severty } from "../../../helper/toast";
import { STAY_CATEGORY } from "../../../constants/conts";
const { TabPane } = Tabs;

function Edit() {

    const sectionName           =   "Sub Category";
    const routeName             =   "category/sub";

    const api = {
        addEdit     :  apiPath.addEditSubCategory,
        view        :  apiPath.viewSubCategory,
        category    :  apiPath.commonCategory,
    }

    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const navigate                          =   useNavigate();
    const params                            =   useParams();
    const FileType                          =   ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
    const [image, setImage]                 =   useState([]);
    const [editoEnValue, setEditoEnValue]   =   useState('');
    const [validationStatus, setValidationStatus] = useState({});
    const [form]                            =   Form.useForm();
    const [categoryList, setCategoryList]   =   useState([]);

    const getCategoryList = () => {
        request({
          url: api.category,
          method: 'GET',
          onSuccess: (response) => {
            setCategoryList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const handleImage = (data) =>{
        setImage(data[0].url);
    }

    const handleFieldChange = (data, lang, type) => {
        if (type === 'description' && lang === 'en') {
            setEditoEnValue(data);
        }

        const name                  =   form.getFieldValue(`${lang}_name`);
        const description           =   form.getFieldValue(`${lang}_description`);
        const isDescriptionEmpty    =   !description || (description.trim() === '<p></p>' || description.trim() === "");
        setValidationStatus(prevStatus => ({
            ...prevStatus, 
            [lang]: !!name && !isDescriptionEmpty
        }));
    }

    const fetchData = (id) => {
        request({
            url: api.view + "/" + id,
            method: 'GET',
            onSuccess: (data) => {
                setLoading(false);
                form.setFieldsValue({ ...data.data, category_id : data?.data?.category_id?._id });
                setImage(data?.data?.image);
                setEditoEnValue(data?.data?.en_description);
    
                // Initialize validationStatus based on fetched data
                const fetchedValidationStatus = {};
                
                languages.forEach(lang => {
                    const name                  =   form.getFieldValue(`${lang.short}_name`);
                    const description           =   form.getFieldValue(`${lang.short}_description`);
                    const isDescriptionEmpty    =   !description || (description.trim() === '<p></p>' || description.trim() === "");
                    fetchedValidationStatus[lang.short] = !!name && !isDescriptionEmpty;
                });

                setValidationStatus(fetchedValidationStatus);
            },
            onError: (error) => {
                console.error(error);
            }
        })
    }
    
    useEffect(() => {
        getCategoryList();
    }, [])

    useEffect(() => {
        const initialValidationStatus = {};
        
        languages.forEach(lang => {
            initialValidationStatus[lang.short] = !!form.getFieldValue(`${lang.short}_name`) && !!form.getFieldValue(`${lang.short}_description`);
        });

        setValidationStatus(initialValidationStatus);
        
        fetchData(params.id);

        setLoading(true);
        
    }, [])

    const validateFields = async () => {
        try {
            await form.validateFields();

            const englishTitle          =   form.getFieldValue('en_name');
            const englishDescription    =   form.getFieldValue('en_description');

            if (englishDescription.trim() === '<p></p>' || englishDescription.trim() === "") {
                return ShowToast('Please update english description', Severty.ERROR)
            }

            if (!englishTitle) {
                return ShowToast("Please update english title", Severty.ERROR);
            }

            onUpdate();

        } catch (error) {
            console.error(error);
        }
    };

    const onUpdate = () => {

        let payload = {
            ...form.getFieldsValue(),
            type: STAY_CATEGORY.LOCATION_INSIGHTS,
            images: image ? image[0].url : null,
        };

        setLoading(true)
        request({
            url     : api.addEdit + "/" + params.id,
            method  : 'POST',
            data    : payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    return (
        <Card title={"Update " + sectionName + " Details"}>

            {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :

                <Form className="edit-page-wrap" form={form} autoComplete="off" layout="verticle" name="blog_form">
                    
                    <Row gutter={[24, 0]}>

                        <Col span={24} xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Select Category"
                                name="category_id"
                                rules={[
                                    { required: true, message: "Please select the category!" },
                                ]}
                                >
                                <Select showSearch placeholder="Select Category" filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}>
                                    {categoryList?.map((item) => (
                                        <Select.Option key={item._id} value={item._id} label={item.en_name}> {item.en_name} </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24} xs={24} sm={24} md={12}>
                            <Form.Item label="Upload Image" name="image" className="mb-1"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (image !== undefined && image?.length > 0) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Missing Image'));
                                    },
                                }
                            ]}
                            >
                                <div className="mb-1"></div>
                                
                                <SingleImageUpload value={image} fileType={FileType} imageType={'category'} btnName={'Image'} onChange={(data) => handleImage(data)} />
                                
                                <Image className="mt-2" width={120} src={image ? image : notfound} />

                            </Form.Item>
                        </Col>

                        <Col md={24}>
                            <Tabs defaultActiveKey={languages[0].short}>
                                {languages.map(lang => (
                                    
                                    <TabPane tab={<span>{lang.long} {validationStatus[lang.short] === false && <Badge dot color="red" />}</span>} key={lang.short}>
                                        
                                        <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                                            rules={[
                                                ...(lang.short === 'en' ? [
                                                    { required: true, message: `Please enter the name in ${lang.long}!` }
                                                ] : []),
                                                { max: 100, message: "Name should not contain more than 100 characters!" },
                                                { min: 2, message: "Name should contain at least 2 characters!" },
                                            ]}
                                            initialValue={form.getFieldValue(`${lang.short}_name`)}
                                            onChange={(data) => handleFieldChange(data, lang.short, 'title')}
                                        >

                                            <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>

                                        </Form.Item>

                                        <Form.Item label={`Description (${lang.long})`} name={`${lang.short}_description`}
                                            rules={[
                                                { required: lang.short === 'en', message: `Please enter the description in ${lang.long}!` },
                                            ]}
                                            initialValue={form.getFieldValue(`${lang.short}_description`)}
                                        >
                                            <DescriptionEditor value={editoEnValue} placeholder={`Enter Description in ${lang.long}`} onChange={(data)=> handleFieldChange(data, lang.short, 'description')} />

                                        </Form.Item>

                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>

                    </Row>

                    <Form.Item className="btn-row float-right">
                        <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                        <Button type="primary" loading={loading} onClick={validateFields}>Submit</Button>
                    </Form.Item>
                </Form>
            }

        </Card>
    )
}

export default Edit;