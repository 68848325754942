import { Row, Col, Card, Table, Button, Image, Modal, Form, Input, Skeleton, Select, Divider } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

function Edit() {

    const sectionName = "Setting";
    const routeName = "setting";

    const api = {
        edit: apiPath.editSetting,
        get: apiPath.viewSetting,
    };

    const [form] = Form.useForm();
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
    const [mobileNumber, setMobileNumber] = useState({ mobile: "", country_code: "" });


    const fetchData = () => {
        setLoading(true);
        request({
            url: api.get,
            method: "GET",
            onSuccess: (data) => {
                setLoading(false);
                if (data.data) {
                    setMobileNumber({
                        mobile: data.data.contact_mobile_number,
                        country_code: data.data.contact_country_code,
                    });
                }
                form.setFieldsValue({ ...data.data });
            },
            onError: (error) => {
                ShowToast(error, Severty.ERROR);
            },
        });
    };

    const handleChange = (value, data, event, formattedValue) => {
        var country_code = data.dialCode;
        setMobileNumber({
            country_code: country_code,
            mobile: value.slice(data.dialCode.length),
        });
    };

    const onSubmit = (values) => {
        const payload = {
            ...values,
            contact_country_code: mobileNumber.country_code,
            contact_mobile_number: mobileNumber.mobile,
        };

        request({
            url: api.edit,
            method: "POST",
            data: payload,
            onSuccess: (data) => {
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR);
                setLoading(false);
            },
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Card title={"Update " + sectionName}>
                {loading ? (
                    [1, 2, 3, 4].map((item) => <Skeleton active key={item} />)
                ) : (
                    <Form
                        className="edit-page-wrap"
                        form={form}
                        onFinish={onSubmit}
                        autoComplete="off"
                        layout="vertical"
                        name="setting_form"
                    >
                        <Row gutter={[24, 0]}>
                            <Divider
                                orientation="left"
                                orientationMargin={15}
                                className="devider-color"
                            >
                                Contact Setting
                            </Divider>

                            <Col span={24} xs={24} sm={12} lg={12} xxl={6}>
                                <Form.Item
                                    label="Name"
                                    name="contact_name"
                                    rules={[
                                        {
                                            type: "name",
                                            message: "The name is not a valid name!",
                                        },
                                        { required: true, message: "Please enter the name!" },
                                        {
                                            max: 100,
                                            message:
                                                "Name should not contain more then 100 characters!",
                                        },
                                        {
                                            min: 5,
                                            message: "Name should contain atleast 5 characters!",
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={100}
                                        autoComplete="off"
                                        placeholder="Enter Contact Name"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={12} lg={12} xxl={6}>
                                <Form.Item
                                    label="Email Address"
                                    name="contact_email"
                                    rules={[
                                        {
                                            type: "email",
                                            message: "The email is not a valid email!",
                                        },
                                        { required: true, message: "Please enter the email!" },
                                        {
                                            max: 100,
                                            message:
                                                "Email should not contain more then 100 characters!",
                                        },
                                        {
                                            min: 5,
                                            message: "Email should contain atleast 5 characters!",
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={100}
                                        autoComplete="off"
                                        placeholder="Enter Contact Email Address"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={12} lg={12} xxl={8}>
                                <Form.Item label="Mobile Number">
                                    <PhoneInput
                                        inputProps={{
                                            name: "contact_mobile_number",
                                            required: true,
                                            autoFocus: false,
                                            placeholder: "Enter Contact Mobile Number",
                                        }}
                                        isValid={(value, country) => {
                                            if (value.match(/1234/)) {
                                                return "Invalid value: " + value + ", " + country.name;
                                            } else if (value.match(/1234/)) {
                                                return "Invalid value: " + value + ", " + country.name;
                                            } else {
                                                return true;
                                            }
                                        }}
                                        country={"in"}
                                        value={
                                            mobileNumber
                                                ? (mobileNumber.country_code
                                                    ? mobileNumber.country_code
                                                    : "+91") +
                                                (mobileNumber.mobile ? mobileNumber.mobile : null)
                                                : "+91"
                                        }
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                            </Col>


                            <Divider
                                orientation="left"
                                orientationMargin={15}
                                className="devider-color"
                            >
                                Other Settings
                            </Divider>


                            <Col span={24} xs={24} sm={12} lg={12} xxl={5}>
                                <Form.Item
                                    label="Admin Version"
                                    name="version"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the admin current version!",
                                        },
                                        {
                                            pattern: new RegExp(/^[\.0-9]*$/),
                                            message: "Only Numeric Characters Allowed",
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={80}
                                        autoComplete="off"
                                        placeholder="Enter Current Admin Version"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={12} lg={12} xxl={5}>
                                <Form.Item
                                    label="Referral Reward (INR)"
                                    name="referral_reward"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the referral reward!",
                                        },
                                        {
                                            pattern: new RegExp(/^[\.0-9]*$/),
                                            message: "Only Numeric Characters Allowed",
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={80}
                                        autoComplete="off"
                                        placeholder="Enter Referral Reward (INR)"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={12} lg={12} xxl={6}>
                                <Form.Item
                                    label="Referral Reward Credited Min Day(s)"
                                    name="referral_reward_min_day"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the referral reward credited days!",
                                        },
                                        {
                                            pattern: new RegExp(/^[\.0-9]*$/),
                                            message: "Only Numeric Characters Allowed",
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={80}
                                        autoComplete="off"
                                        placeholder="Enter Referral Reward Credited Min Day(s)"
                                    />
                                </Form.Item>
                            </Col>

                            <Divider
                                orientation="left"
                                orientationMargin={15}
                                className="devider-color"
                            >
                                Technical Setting (Do not modify or implement this setting without consulting a developer)
                            </Divider>
                            
                            <Col span={24} xs={24} sm={12} lg={12} xxl={12}>
                                <Form.Item
                                    label="Google Map Key"
                                    name="google_map_key"
                                >
                                    <Input
                                        maxLength={255}
                                        autoComplete="off"
                                        placeholder="Enter Google Map Key"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} xs={24} sm={12} lg={12} xxl={12}>
                                <Form.Item
                                    label="Google Weather Key"
                                    name="google_weather_key"
                                >
                                    <Input
                                        maxLength={255}
                                        autoComplete="off"
                                        placeholder="Enter Google Weathers Key"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item className="btn-row float-right">
                            <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Update Setting</Button>
                        </Form.Item>
                    </Form>
                )}
            </Card>
        </>
    );
}

export default Edit;