
export const Constant = {
    PROJECT_NAME : "Trip N Tales",
    PRICE : "₹"
};

export const STAY_CATEGORY = {
    HOTEL : "Hotel",
    ACTIVITY : "Activity",
    LOCATION_INSIGHTS : "Location Insights"
};

export const APPROVAL_STATUS = {
    PENDING : 'Pending',
    APPROVED : 'Approved',
    REJECTED : 'Rejected'
}

export const bannerType = [
    {id : 'normal', value : "Normal"},
    {id : 'category', value : "Category"},
]

export const FORM_INPUT_TYPE = [
    {value : 'text', name : "Text"},
    {value : 'date', name : "Date"},
    {value : 'number', name : "Number"},
    {value : 'single_select', name : "Single Select"},
    {value : 'multiple_select', name : "Multiple Select"},
    {value : 'single_image', name : "Single Image"},
    {value : 'multiple_image', name : "Multiple Image"},
    {value : 'textarea', name : "Textarea"},
    {value : 'radio', name : "Radio"},
    {value : 'checkbox', name : "Checkbox"},
]

export const FORM_INPUT_REQUIRED = [
    {value : 'yes', name : "Yes"},
    {value : 'no', name : "No"},
]

export const FORM_INPUT_SHOW = [
    {value : 'yes', name : "Yes"},
    {value : 'no', name : "No"},
]

export const FORM_INPUT_DROPDOWN_DATA = [
    {value : 'aminities', name : "Aminities"},
    {value : 'meal', name : "Meal"},
]

export const INDIACOUNTRY   =   '66e06f562ed53a07a43b07b1';

export const CategoryType = [
    { value : 'description', name : 'Description Content' },
    { value : 'add-on', name : 'Add On Content' },
];

export const CONTACT_FEEDBACK_TYPE = {
    SUGGESTION  :   "Suggestion",
    CONTACT_US  :   "Contact_Us",
    CUSTOM_FAQ  :   "Custom_FAQ",
};
  
export const DefaultLatLng = { lat: 30.5595, lng: 22.9375 };

export const APPHOME_SECTION = [
    { name: 'Location Insights Category', section: 'location_insights_category' },
    { name: 'Activity Category', section: 'activity_category' },
    { name: 'State', section: 'state' },
    { name: 'City', section: 'city' },
    { name: 'Location Insights', section: 'location_insights' },
    { name: 'Activity', section: 'activity' },
    { name: 'Blog', section: 'blog' },
    { name: 'Stay', section: 'stay' },
    // { name: 'Category', section: 'Category' },
    // { name: 'Sub Category', section: 'Sub_Category' },
    // { name: 'Banner', section: 'Banner' },
];