import { Col, Form, Modal, Row, Input, Select, Badge } from "antd";
import React, { useState } from "react";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { APPROVAL_STATUS } from "../constants/conts";
const { TextArea } = Input;

export const ChangeApprovalStatus = ({ path, show, hide, userId, refresh }) => {

  const [forms] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [status, changeStatus]  = useState();

  const handleChange = (value) => {
    changeStatus(value);
  }

  const onCreate = (values) => {
    const payload = {
        ...values,
        id : userId,
        type : 'approval'
    };
    
    setLoading(true);
    request({
      url       :   path,
      method    :   "POST",
      data      :    payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={600}
      title="Change Apporval Status"
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <span className="text-danger">Note : User will notify through email</span>
      <Form id="create" form={forms} onFinish={onCreate} layout="vertical">
        <Row>
          <Col span={24} className="mt-1">
            <Form.Item
              label="Select Approval Status"
              name="status"
              rules={[
                { required: true, message: "Please select the approval status!" },
              ]}
            >
              <Select placeholder="Select Approval Status" onChange={handleChange}>
                <option value=''> Select Approval Status </option>
                <option value={APPROVAL_STATUS.APPROVED}> <Badge status="success" /> Approved </option>
                <option value={APPROVAL_STATUS.REJECTED}> <Badge status="error" /> Reject </option>
              </Select>
            </Form.Item>
          </Col>

          <Col className="mt-1" span={24}>
            <Form.Item label="Comment (Optional)" name="comment"
              rules={[
                { required: status == APPROVAL_STATUS.REJECTED ? true : false, message: `Please enter the comment` },
                { min: 5, message: `Comment contain atleast 5 characters` },
              ]}
            >
              <TextArea placeholder="Enter Comment or Rejection Comment" rows={4} autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
