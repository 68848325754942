import { Row, Col, Card, Button, Skeleton, Image, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import languages from "../../config/language";
import moment from "moment";
import notfound from "../../assets/images/not_found.png";

function View() {

  const sectionName   =   "Subscription";
  const routeName     =   "subscription";

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    request({
      url: apiPath.viewSubscription + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <Card title={sectionName + " Details"}>
      <Row gutter={16}>
        <Col span={12} xs={24} md={24}>

          {loading ? <Skeleton active /> : 
            <div className="view-main-list">

              <div className="view-inner-cls">
                <h5>Type:</h5>
                <h6>{list && list.type ? list.type : '-'}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>Price (INR):</h5>
                <h6>{list && list.price ? list.price : '-'}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>Status:</h5>
                <h6>{list.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
              </div>

              {languages.map(lang => (
                <>
                  <Divider orientation="left" orientationMargin={0} className="devider-color">{lang.long}</Divider>

                  <div className="view-inner-cls">
                    <h5>Name:</h5>
                    <h6 className="cap">{list && list[`${lang.short}_name`] ? list[`${lang.short}_name`] : '-'}</h6>
                  </div>
                </>
              ))}
           
              <div className="view-inner-cls">
                <h5>Created On:</h5>
                <h6>{list.created_at ? moment(list.created_at).format('DD-MMM-YYYY') : '-'}</h6>
              </div>

              <div className="view-inner-cls float-right">
                <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
              </div>

            </div>
          }

        </Col>
      </Row>
    </Card>
  );
  
}

export default View;