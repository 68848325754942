import { Row, Col, Modal, Form, Input, Button, Table, message } from "antd";
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";

const HashTagModel = ({ type, path, sectionName, show, hide, data, refresh }) => {

    const [form]                                =   Form.useForm();
    const { request }                           =   useRequest();
    const [loading, setLoading]                 =   useState(false);

    const onCreate = (values) => {
        setLoading(true);
        request({
            url: `${data ? path + "/" + data._id : path}`,
            method: 'POST',
            data: values,
            onSuccess: (data) => {
                setLoading(false);
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    hide();
                    refresh();
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR);
                setLoading(false);
            },
        })
    };

    useEffect(() => {
        if (!data) return;
        form.setFieldsValue({ ...data });
      }, [data])

    useEffect(() => {
    }, [refresh])

    return (
        <Modal
            open={show}
            title={`Add / Update ${sectionName}`}
            okText="Ok"
            onCancel={hide}
            okButtonProps={{
                form: 'create',
                htmlType: 'submit',
                loading: loading,
            }}
        >
            <Form id="create" form={form} onFinish={onCreate} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col span={24} xs={24} sm={24} md={24}>
                        <Form.Item className="mb-0"
                        label="HashTag"
                            name="name"
                            normalize={value => value.trimStart()}
                            rules={[
                                { required: true, message: "Please Enter the hashtag!" },
                                { max: 200, message: "HashTag should not contain more then 200 characters!" },
                                { min: 2, message: "HashTag should contain at least 2 characters!" },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter HashTag" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default HashTagModel;