import React, { useState, useEffect } from "react";
import { Card, Col, Row, Typography, Table, Tag, Skeleton, Radio, Avatar } from "antd";
import { Link } from 'react-router-dom';
import useRequest from "../../hooks/useRequest";
import { NavLink, useLocation } from "react-router-dom";
import { ShowToast, Severty } from "../../helper/toast";
import moment from 'moment';
import { Bar } from "react-chartjs-2";
import apiPath from "../../constants/apiPath";
import Logo from "../../assets/images/Logo.png";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from "chart.js";
import Weather from "../../components/Weather";

function Home() {

  const { Title, Text }                   =   Typography;
  const { request }                       =   useRequest();
  const [loading, setLoading]             =   useState(false);
  const [userGraphData, setGraphUserData] =   useState({});
  const [barData, setBarData]             =   useState(false);
  const [recentUser, setRecentUser]       =   useState([]);
  const [weather, setWeather]             =   useState();
  const [location, setLocation]           =   useState({ latitude: null, longitude: null });

  ChartJS.register(CategoryScale, LinearScale, BarElement);

  const [dashboard, setDashboard] = useState({
    customerCount : 0,
    specialistCount : 0,
    dealerCount : 0,
    makeCount: 0,
    modelCount: 0,
    productSaleCount: 0,
    productCatalogueCount : 0,
    requestQuoteCount : 0,
  });

  const options = {
    responsive: true,
    indexAxis: 'x',
  }

  const userColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, { name, _id, image }) => {
        return (
          <>
            <Avatar className="cap" size={40}> {name.charAt(0)} </Avatar>
            <a style={{ marginLeft: 12 }} className="cap avatar-text">{name}</a>
          </>
        );
      },
    },
    {
      title: "Mobile Number",
      sorter: (a, b) => a.mobile_number.localeCompare(b.mobile_number),
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+965") + (mobile_number ? mobile_number : '')
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      width: 250,
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (_, { email }) => {
        return email ? email : '-';
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.name.localeCompare(b.state.name),
      render: (_, { state }) => {
        return state ? state.name : '-';
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.city.name.localeCompare(b.city.name),
      render: (_, { city }) => {
        return city ? city.name : '-';
      },
    },
    {
      title: "Status",
      key: "is_active",
      render: (_, { is_active }) => {
        let color = is_active ? 'green' : 'red';
        return (<Tag color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag>);
      },
    },
    {
      title: "Registered On",
      key: "created_at",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
  ];

  const userMonthlyCount = (data) => {
    const Cmonths = data?.userGraph?.userMonthlyCount?.map(item => item?.month);
    const Ccounts = data?.userGraph?.userMonthlyCount?.map(item => item?.count);
    const userChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: "User Monthly",
          data: Ccounts,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1,
        },
      ],
    };
    setGraphUserData(userChartData)
  }

  const userWeeklyCount = () => {
    const Cmonths = dashboard?.userGraph?.userWeeklyCount?.map(item => item?.week);
    const Ccounts = dashboard?.userGraph?.userWeeklyCount?.map(item => item?.count);
    const userChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: "User Weekly",
          data: Ccounts,
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgb(255, 159, 64)',
          borderWidth: 1,
        },
      ],
    };
    setGraphUserData(userChartData)
  }

  const userYearlyCount = () => {
    const Cmonths = dashboard?.userGraph?.userYearlyCount?.map(item => item?.year);
    const Ccounts = dashboard?.userGraph?.userYearlyCount?.map(item => item?.count);
    const userChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: "User Yearly",
          data: Ccounts,
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgb(255, 205, 86)',
          borderWidth: 1,
        },
      ],
    };
    setGraphUserData(userChartData)
  }

  const count = [
    {
      today: "No. of Active / Reg. Sub Admin",
      title: `${dashboard && dashboard.activeSubAdminCount ? dashboard.activeSubAdminCount : 0} /  ${dashboard && dashboard.subAdminCount ? dashboard.subAdminCount : 0}`,
      persent: "10%",
      icon: <i class="fas fa-user-cog"></i>,
      bnb: "bnb2",
      url: '/sub-admin'
    },
    {
      today: "No. of Active / Reg. User",
      title: `${dashboard && dashboard.activeUserCount ? dashboard.activeUserCount : 0} / ${dashboard && dashboard.userCount ? dashboard.userCount : 0}`,
      persent: "10%",
      icon: <i class="fas fa-users"></i>,
      bnb: "bnb2",
      url: '/user'
    },
    {
      today: "No. of Category & Subcategoy",
      title: `${dashboard && dashboard.categoryCount ? dashboard.categoryCount : 0} & ${dashboard && dashboard.subCategoryCount ? dashboard.subCategoryCount : 0}`,
      icon: <i class="fas fa-people-carry"></i>,
      bnb: "bnb2",
      url: '/category'
    },
    {
      today: "No. of State & City",
      title: `${dashboard && dashboard.stateCount ? dashboard.stateCount : 0} & ${dashboard && dashboard.cityCount ? dashboard.cityCount : 0}`,
      icon: <i class="fas fa-flag"></i>,
      bnb: "bnb2",
      url: '/state'
    },
    {
      today: "No. of Subscription",
      title: `${dashboard && dashboard.subscriptionCount ? dashboard.subscriptionCount : 0}`,
      icon: <i class="fas fa-city"></i>,
      bnb: "bnb2",
      url: '/city'
    },
    {
      today: "No. of Wallet Transaction",
      title: `${dashboard && dashboard.walletTransCount ? dashboard.walletTransCount : 0}`,
      icon: <i class="fas fa-city"></i>,
      bnb: "bnb2",
      url: '/city'
    },
    {
      today: "Wallet Earning (INR)",
      title: `${dashboard && dashboard.walletEarning ? dashboard.walletEarning : 0}`,
      icon: <i class="fas fa-city"></i>,
      bnb: "bnb2",
      url: '/city'
    },
    {
      today: "No. of Application Form",
      title: `${dashboard && dashboard.applicationFormCount ? dashboard.applicationFormCount : 0}`,
      icon: <i class="fas fa-city"></i>,
      bnb: "bnb2",
      url: '/city'
    },
    {
      today: "No. of Package",
      title: `${dashboard && dashboard.packageCount ? dashboard.packageCount : 0}`,
      icon: <i class="fas fa-city"></i>,
      bnb: "bnb2",
      url: '/city'
    },
    {
      today: "No. of Blog",
      title: `${dashboard && dashboard.blogCount ? dashboard.blogCount : 0}`,
      icon: <i class="fas fa-city"></i>,
      bnb: "bnb2",
      url: '/city'
    },
  ];

  useEffect(() => {
    /* if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    } */
  }, []);
  
  useEffect(() => {
    if (location) {
      setLoading(true);
      request({
        url: apiPath.location, 
        method: 'POST',
        data: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
        onSuccess: (data) => {
          setLoading(false)
          setWeather(data?.data?.weather);
        },
        onError: (error) => {
          setLoading(false)
          ShowToast(error, Severty.ERROR)
        }
      })
    }
  }, [location]);

  useEffect(() => {
    setLoading(true)
    request({
      url: apiPath.dashboard, 
      method: 'POST',
      onSuccess: (data) => {
        setLoading(false)
        setDashboard(data.data);
        userMonthlyCount(data?.data);
        setBarData(true);
        setRecentUser(data.data.recentUser);
      },
      onError: (error) => {
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }, [])
 
  return (
    <>
      <div className="layout-content">
        
        {/* <Weather data={weather} loading={loading} location={location}></Weather> */}
        
        <div className="mb-24">
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={18} md={24}>
              <Row className="rowgap-vbox" gutter={[24, 16]}>

                {count.map((c, index) => (
                  <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Link to={c.url}>
                      <Card bordered={false} className="criclebox" style={{height:"100%"}}>
                        <div className="number">
                          <Row align="middle" gutter={[24, 0]}>
                            <Col xs={18}>
                                <span>{c.today}</span>
                                <Title level={3}>{c.title}</Title>
                            </Col>
                            <Col xs={6}> <div className="icon-box">{c.icon}</div></Col>
                          </Row>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>

        <Row gutter={[24, 16]}>
          <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Title level={5}>User Activity Insights</Title>
              
              <Row style={{ justifyContent: "end" }}>
                <Radio.Group defaultValue="month" buttonStyle="solid">
                  <Radio.Button className="chart-btn" onClick={userWeeklyCount} value="week">Week</Radio.Button>
                  <Radio.Button className="chart-btn" onClick={() => userMonthlyCount(dashboard)} value="month">Month</Radio.Button>
                  <Radio.Button className="chart-btn" onClick={userYearlyCount} value="year">Year</Radio.Button>
                </Radio.Group>
              </Row>
              
              {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>{barData && <Bar data={userGraphData} options={options} />}</>}

            </Card>
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Title level={5}>Subscription Activity Insights</Title>
              
              <Row style={{ justifyContent: "end" }}>
                <Radio.Group defaultValue="month" buttonStyle="solid">
                  <Radio.Button className="chart-btn" onClick={userWeeklyCount} value="week">Week</Radio.Button>
                  <Radio.Button className="chart-btn" onClick={() => userMonthlyCount(dashboard)} value="month">Month</Radio.Button>
                  <Radio.Button className="chart-btn" onClick={userYearlyCount} value="year">Year</Radio.Button>
                </Radio.Group>
              </Row>
              
              {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>{barData && <Bar data={userGraphData} options={options} />}</>}

            </Card>
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Title level={5}>Wallet Transaction Insights</Title>
              
              <Row style={{ justifyContent: "end" }}>
                <Radio.Group defaultValue="month" buttonStyle="solid">
                  <Radio.Button className="chart-btn" onClick={userWeeklyCount} value="week">Week</Radio.Button>
                  <Radio.Button className="chart-btn" onClick={() => userMonthlyCount(dashboard)} value="month">Month</Radio.Button>
                  <Radio.Button className="chart-btn" onClick={userYearlyCount} value="year">Year</Radio.Button>
                </Radio.Group>
              </Row>
              
              {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>{barData && <Bar data={userGraphData} options={options} />}</>}

            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Card bordered={false} className="criclebox tablespace" style={{ marginBottom: '16px' }}>
              <p className="dashboard-table-heading">Recent Joined User (10)</p>
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={userColumns}
                  dataSource={recentUser}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default Home;
