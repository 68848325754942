import {Row, Col, Card, Button, Input, Form, Badge, Tabs, Select, Divider, Space} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import DescriptionEditor from '../../components/DescriptionEditor';
import MultipleImageUpload from "../../components/MultipleImageUpload";
import languages from "../../config/language";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
const { TabPane } = Tabs;
const { Option } = Select;

function Add() {

    const sectionName   =   "Location Insights";
    const routeName     =   "location-insights";

    const api = {
        addEdit         :  apiPath.addEditLocationInsights,
        category        :  apiPath.commonCategory,
        state           :  apiPath.commonState,
        city            :  apiPath.commonCity,
        filter          :  apiPath.commonFilter,
        filterValue     :  apiPath.commonFilterValue,
        subCategory     :  apiPath.commonSubCategory,
        country         :  apiPath.commonCountry,
    }

    const [form]                            =   Form.useForm();
    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const navigate                          =   useNavigate();
    const FileType                          =   ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
    const [editoEnValue, setEditoEnValue]           =   useState('');
    const [multipleImage, setMultipleImage]         =   useState([]);
    const [categoryList, setCategoryList]           =   useState([]);
    const [subCategoryList, setSubCategoryList]     =   useState([]);
    const [filterList, setFilterList]               =   useState([]);
    const [filterValuesList, setFilterValuesList]   =   useState([]);
    const [validationStatus, setValidationStatus]   =   useState({});
    const [cityList, setCityList]                   =   useState([]);
    const [stateList, setStateList]                 =   useState([]);
    const [countryList, setCountryList]   =   useState([]);

    const getCountryList = () => {
        request({
          url: api.country,
          method: 'GET',
          onSuccess: (response) => {
            setCountryList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const handleMultipleImage = (data) =>{
        setMultipleImage(data);
    }
    
    const handleCountryChange = (e) => {
        getStateList(e);
    }

    const getStateList = (country_id) => {
        request({
          url: api.state + "/" + country_id,
          method: 'POST',
          onSuccess: (response) => {
            setStateList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const handleStateChange = (value) => {
        getCityList(value);
    }

    const handleFilterChange = (value) => {
        getFilterValuesList(value);
    }
    
    const getFilterList = () => {
        request({
          url: api.filter,
          method: 'GET',
          onSuccess: (response) => {
            setFilterList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const getFilterValuesList = (e) => {
        request({
          url: api.filterValue + "/" + e,
          method: 'GET',
          onSuccess: (response) => {
            setFilterValuesList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const getCityList = (e) => {
        request({
          url: api.city + "/" + e,
          method: 'POST',
          onSuccess: (response) => {
            setCityList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const handleCategoryChange = (e) => {
        request({
            url: api.subCategory + "/" + e,
            method: 'GET',
            onSuccess: (response) => {
              setSubCategoryList(response.data.data);
            },
            onError: (error) => {
              console.log(error)
              ShowToast(error, Severty.ERROR)
            }
        })
    }

    const getCategoryList = () => {
        request({
          url: api.category,
          method: 'GET',
          onSuccess: (response) => {
            setCategoryList(response.data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const handleFieldChange = (data, lang, type) => {
        if (type === 'description' && lang === 'en') {
            setEditoEnValue(data);
        }

        const name                  =   form.getFieldValue(`${lang}_name`);
        const description           =   form.getFieldValue(`${lang}_description`);
        const isDescriptionEmpty    =   !description || (description.trim() === '<p></p>' || description.trim() === "");
        setValidationStatus(prevStatus => ({
            ...prevStatus, 
            [lang]: !!name && !isDescriptionEmpty
        }));
    }

    useEffect(() => {
        const initialValidationStatus = {};
        
        languages.forEach(lang => {
            initialValidationStatus[lang.short] = !!form.getFieldValue(`${lang.short}_name`) && !!form.getFieldValue(`${lang.short}_description`);
        });

        setValidationStatus(initialValidationStatus);
    }, [])

    const onCreate = () => {

        let payload = {
            ...form.getFieldsValue(),
            images: multipleImage.length > 0 ? multipleImage.map((item) => item.url) : '',
        };

        setLoading(true)
        request({
            url: api.addEdit,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    useEffect(() => {
        getCategoryList();
        getFilterList();
        getCountryList();
    }, [])
    
    return (
        <Card title={"Add New " + sectionName}>
            <Form className="edit-page-wrap" form={form} onFinish={onCreate} autoComplete="off" layout="verticle" name="blog_form">
                
                <Row gutter={[24, 0]}>

                    <Divider orientation="left" orientationMargin={12} className="devider-color">
                        {`General Information`}
                    </Divider>

                    <Col span={12} xs={12} sm={12} md={8}>
                        <Form.Item className="banner-elt"
                            name="country_id"
                            label="Select Country"
                            rules={[{ required: true, message: 'Missing Country Selection' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Country"
                                optionFilterProp="children"
                                onChange={handleCountryChange}
                                filterOption={(input, option) =>
                                    option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {countryList?.map((item, index) => (
                                    <Option key={index} value={item._id}>
                                        <span className="cap">{item.en_name}</span>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12} xs={12} sm={12} md={8}>
                        <Form.Item className="banner-elt"
                            name="state_id"
                            label="Select State"
                            rules={[{ required: true, message: 'Missing State Selection' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select State"
                                optionFilterProp="children"
                                onChange={handleStateChange}
                                filterOption={(input, option) =>
                                    option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {stateList?.map((item, index) => (
                                    <Option key={index} value={item._id}>
                                        <span className="cap">{item.en_name}</span>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12} xs={12} sm={12} md={8}>
                        <Form.Item className="banner-elt"
                            name="city_id"
                            label="Select City"
                            rules={[{ required: true, message: 'Missing City Selection' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select City"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {cityList?.map((item, index) => (
                                    <Option key={index} value={item._id}>
                                        <span className="cap">{item.en_name}</span>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={24}>
                        <Form.Item label="Short Description (Max: 250)" name="short_description" 
                            rules={[
                                { required: true, message: `Please enter the short description!` },
                                { max: 250, message: "Short description should not contain more than 250 characters!" },
                                { min: 10, message: "Short description should contain at least 10 characters!" },
                            ]}
                        >
                            <Input autoComplete="off" placeholder={`Enter Short Description`}/>
                        </Form.Item>
                    </Col>

                    <Col span={24} xs={24} sm={24} md={12}>
                        <Form.Item label="Upload Image (Multiple)" name="images" className="mb-1">
                           <div className="mb-1"></div>
                           <MultipleImageUpload fileType={FileType} imageType={'product'} btnName={'Image'} onChange={(data)=> handleMultipleImage(data)} />
                       </Form.Item>
                    </Col>

                    <Divider orientation="left" orientationMargin={15} className="devider-color">{"YouTube URLs"}</Divider>

                    <Col span={24} xs={24} sm={24} md={24} style={{ marginLeft : "-8px" }}>
                        <Form.List name="youtube_url">
                            {(fields, { add, remove }) => (
                                <>
                                    <Row gutter={16}>
                                        {fields.map((field, index) => (
                                            <Col span={24} xs={24} sm={24} md={24}>
                                                <div key={field.key} align="baseline" className="gap-cls" style={{ display: 'flex' }}>
                                                    
                                                    <Col span={20} xs={20} sm={20} md={20}> 
                                                        <Form.Item
                                                            className="qty-cls"
                                                            name={[field.name]}
                                                            rules={[{ required: true, message: `Please enter URL ${index + 1}` }]}
                                                        >
                                                            <Input autoComplete="off" placeholder={`Enter YouTube URL ${index + 1}`} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={2} xs={2} sm={2} md={2}> 
                                                        {index > 0 && (
                                                            <div className="minus-wrap" style={{ marginTop: '-10px', marginLeft: "10px" }}>
                                                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: '8px' }} />
                                                            </div>
                                                        )}
                                                    </Col>

                                                </div>
                                            </Col>
                                        ))}
                                    </Row>

                                    <Col span={6}>
                                        <Form.Item className="mt-2" style={{ marginLeft: '-3px', width: '60%' }}>
                                        <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Form.List>
                    </Col>

                    <Divider orientation="left" orientationMargin={15} className="devider-color">{"FAQs"}</Divider>

                    <Col span={24} xs={24} sm={24} md={24} style={{ marginLeft : "-8px" }}>
                        <Form.List name="faq">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Space align="baseline" className="gap-cls">
                                            <Row>
                                                <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                    {`Question - ${index + 1}`}
                                                </Divider>

                                                {languages.map((lang) => (
                                                    <Col span={6} key={`${field.key}_${lang.short}`}>
                                                    <Form.Item
                                                        className="qty-cls"
                                                        name={[field.name, `${lang.short}_question`]}
                                                        label={`Question (${lang.long})`}
                                                        rules={[
                                                            ...(lang.short === 'en' ? [
                                                                { required: true, message: `Please enter the question in ${lang.long}!` }
                                                            ] : [])
                                                        ]}
                                                    >
                                                        <Input autoComplete="off" placeholder={`Enter Question in ${lang.long}`} />
                                                    </Form.Item>
                                                    </Col>
                                                ))}

                                                <Divider orientation="left" orientationMargin={15} className="devider-color">
                                                    {`Answer - ${index + 1}`}
                                                </Divider>

                                                {languages.map((lang) => (
                                                    <Col span={12} key={`${field.key}_${lang.short}`}>
                                                        <Form.Item
                                                            className="qty-cls"
                                                            name={[field.name, `${lang.short}_answer`]}
                                                            label={`Answer (${lang.long})`}
                                                            rules={[
                                                                ...(lang.short === 'en' ? [
                                                                    { required: true, message: `Please enter the answer in ${lang.long}!` }
                                                                ] : [])
                                                            ]}
                                                        >
                                                            <Input autoComplete="off" placeholder={`Enter Answer in ${lang.long}`} />
                                                        </Form.Item>
                                                    </Col>
                                                ))}

                                                {index > 0 && (
                                                    <div className="minus-wrap" style={{ marginBottom: '8px', marginLeft: '10px' }}>
                                                        <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: '8px' }} />
                                                    </div>
                                                )}
                                            </Row>
                                        </Space>
                                    ))}

                                    <Col span={6}>
                                        <Form.Item className="mt-2" style={{ marginLeft: '-3px', width: '60%' }}>
                                        <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Form.List>
                    </Col>
                    <Col md={24}>
                        <Tabs defaultActiveKey={languages[0].short}>
                            {languages.map(lang => (
                                
                                <TabPane tab={<span>{lang.long} {validationStatus[lang.short] === false && <Badge dot color="red" />}</span>} key={lang.short}>
                                    
                                    <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                                        rules={[
                                            ...(lang.short === 'en' ? [
                                                { required: true, message: `Please enter the name in ${lang.long}!` }
                                            ] : []),
                                            { max: 100, message: "Name should not contain more than 100 characters!" },
                                            { min: 2, message: "Name should contain at least 2 characters!" },
                                        ]}
                                        initialValue={form.getFieldValue(`${lang.short}_name`)}
                                        onChange={(data) => handleFieldChange(data, lang.short, 'title')}
                                    >

                                        <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>

                                    </Form.Item>

                                    <Form.Item label={`Description (${lang.long})`} name={`${lang.short}_description`}
                                        rules={[
                                            { required: lang.short === 'en', message: `Please enter the description in ${lang.long}!` },
                                        ]}
                                        initialValue={form.getFieldValue(`${lang.short}_description`)}
                                    >
                                        <DescriptionEditor value={editoEnValue} placeholder={`Enter Description in ${lang.long}`} onChange={(data)=> handleFieldChange(data, lang.short, 'description')} />

                                    </Form.Item>

                                </TabPane>
                            ))}
                        </Tabs>
                    </Col>

                    <Divider orientation="left" orientationMargin={12} className="devider-color">
                        {`Filter Options`}
                    </Divider>

                    <Col span={24} xs={24} sm={24} md={24}>
                        <Form.List name="filter">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name }) => (
                                        <Row gutter={24} key={key}>
                                            <Col span={24} xs={24} sm={24} md={11}>
                                                <Form.Item
                                                    label="Select Filter"
                                                    name={[name, 'master_filter_id']}
                                                    rules={[{ required: true, message: "Please select the filter!" }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select Filter"
                                                        onChange={handleFilterChange}
                                                        filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                    {filterList?.map((item) => (
                                                        <Option key={item._id} value={item._id} label={item.en_name}>
                                                            {item.en_name}
                                                        </Option>
                                                    ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={24} xs={24} sm={24} md={11}>
                                                <Form.Item
                                                    label="Select Values (Multiple)"
                                                    name={[name, 'master_filter_value_id']}
                                                    rules={[{ required: true, message: "Please select the values!" }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        placeholder="Select Values"
                                                        filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                    {filterValuesList?.map((item) => (
                                                        <Option key={item._id} value={item._id} label={item.en_answer}>
                                                            {item.en_answer}
                                                        </Option>
                                                    ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={1}>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ marginBottom: '16px' }} />
                                            </Col>
                                        </Row>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Filter
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>

                    <Divider orientation="left" orientationMargin={12} className="devider-color">
                        {`Category Options`}
                    </Divider>

                    <Col span={24} xs={24} sm={24} md={24}>
                        <Form.List name="categories">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey }) => (
                                        <Row gutter={24} key={key}>
                                            <Col span={24} xs={24} sm={24} md={11}>
                                                <Form.Item
                                                    label="Select Category"
                                                    name={[name, 'category_id']}
                                                    rules={[{ required: true, message: "Please select the category!" }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select Category"
                                                        onChange={handleCategoryChange}
                                                        filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                    {categoryList?.map((item) => (
                                                        <Option key={item._id} value={item._id} label={item.en_name}>
                                                            {item.en_name}
                                                        </Option>
                                                    ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={24} xs={24} sm={24} md={11}>
                                                <Form.Item
                                                    label="Select Sub Category"
                                                    name={[name, 'sub_category_id']}
                                                    rules={[{ required: true, message: "Please select the sub category!" }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        placeholder="Select Sub Category"
                                                        filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                    {subCategoryList?.map((item) => (
                                                        <Option key={item._id} value={item._id} label={item.en_name}>
                                                            {item.en_name}
                                                        </Option>
                                                    ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={1}>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ marginBottom: '16px' }} />
                                            </Col>
                                        </Row>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Category & Sub Category
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>

                    

                </Row>

                <Form.Item className="btn-row float-right">
                    <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                    <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Add;
