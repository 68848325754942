import {Row, Col, Card, Button, Input, Form, Badge, Tabs} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../../constants/apiPath";
import DescriptionEditor from '../../../components/DescriptionEditor';
import SingleImageUpload from "../../../components/SingleImageUpload";
import languages from "../../../config/language";
import { Link } from "react-router-dom";
import { STAY_CATEGORY } from "../../../constants/conts";
const { TabPane } = Tabs;

function Add() {

    const sectionName   =   "Category";
    const routeName     =   "stay/category";

    const api = {
        addEdit   :  apiPath.addEditCategory,
    }

    const [form]                            =   Form.useForm();
    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const [type, setType]                   =   useState('')
    const navigate                          =   useNavigate();
    const FileType                          =   ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
    const [image, setImage]                 =   useState([]);
    const [editoEnValue, setEditoEnValue]   =   useState('');
    const [validationStatus, setValidationStatus] = useState({});

    const handleImage = (data) =>{
        setImage(data);
    }

    const handleFieldChange = (data, lang, type) => {
        if (type === 'description' && lang === 'en') {
            setEditoEnValue(data);
        }

        const name                  =   form.getFieldValue(`${lang}_name`);
        const description           =   form.getFieldValue(`${lang}_description`);
        const isDescriptionEmpty    =   !description || (description.trim() === '<p></p>' || description.trim() === "");
        setValidationStatus(prevStatus => ({
            ...prevStatus, 
            [lang]: !!name && !isDescriptionEmpty
        }));
    }

    useEffect(() => {
        const initialValidationStatus = {};
        
        languages.forEach(lang => {
            initialValidationStatus[lang.short] = !!form.getFieldValue(`${lang.short}_name`) && !!form.getFieldValue(`${lang.short}_description`);
        });

        setValidationStatus(initialValidationStatus);
    }, [])

    const validateFields = async () => {
        try {
            await form.validateFields();

            const englishTitle          =   form.getFieldValue('en_name');
            const englishDescription    =   form.getFieldValue('en_description');

            if (englishDescription.trim() === '<p></p>' || englishDescription.trim() === "") {
                return ShowToast('Please update english description', Severty.ERROR)
            }

            if (!englishTitle) {
                return ShowToast("Please update english title", Severty.ERROR);
            }

            onCreate();

        } catch (error) {
            console.error(error);
        }
    };
    

    const onCreate = () => {

        let payload = {
            ...form.getFieldsValue(),
            type: STAY_CATEGORY.HOTEL,
            image: image ? image[0].url : null,
        };

        setLoading(true)
        request({
            url: api.addEdit,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    return (
        <Card title={"Add New " + sectionName}>
            <Form className="edit-page-wrap" form={form} onFinish={onCreate} autoComplete="off" layout="verticle" name="blog_form">
                
                <Row gutter={[24, 0]}>

                    <Col span={24} xs={24} sm={24} md={12}>
                        <Form.Item label="Upload Image"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (image !== undefined && image?.length > 0) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Missing Image'));
                                    },
                                }
                            ]}
                            name="image" className="mb-1"
                        >
                            <div className="mb-1"></div>
                            <SingleImageUpload value={image} fileType={FileType} imageType={'category'} btnName={'Image'} onChange={(data) => handleImage(data)} />
                        </Form.Item>
                    </Col>

                    <Col md={24}>
                        <Tabs defaultActiveKey={languages[0].short}>
                        {languages.map(lang => (
                                
                                <TabPane tab={<span>{lang.long} {validationStatus[lang.short] === false && <Badge dot color="red" />}</span>} key={lang.short}>
                                    
                                    <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                                        rules={[
                                            ...(lang.short === 'en' ? [
                                                { required: true, message: `Please enter the name in ${lang.long}!` }
                                            ] : []),
                                            { max: 100, message: "Name should not contain more than 100 characters!" },
                                            { min: 2, message: "Name should contain at least 2 characters!" },
                                        ]}
                                        initialValue={form.getFieldValue(`${lang.short}_name`)}
                                        onChange={(data) => handleFieldChange(data, lang.short, 'title')}
                                    >

                                        <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>

                                    </Form.Item>

                                    <Form.Item label={`Description (${lang.long})`} name={`${lang.short}_description`}
                                        rules={[
                                            { required: lang.short === 'en', message: `Please enter the description in ${lang.long}!` },
                                        ]}
                                        initialValue={form.getFieldValue(`${lang.short}_description`)}
                                    >
                                        <DescriptionEditor value={editoEnValue} placeholder={`Enter Description in ${lang.long}`} onChange={(data)=> handleFieldChange(data, lang.short, 'description')} />

                                    </Form.Item>

                                </TabPane>
                            ))}
                        </Tabs>
                    </Col>

                </Row>

                <Form.Item className="btn-row float-right">
                    <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                    <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Add;
