import {Row, Col, Card, Button, Input, Form, Divider, Space} from "antd";
import React, { useState } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import languages from "../../config/language";
import { Link } from "react-router-dom";

function Add() {

    const sectionName   =   "Master Filter";
    const routeName     =   "master-filter";

    const api = {
        addEditMaster   :  apiPath.addEditMasterFilter,
    }
    
    const [form]                    =   Form.useForm();
    const { request }               =   useRequest();
    const [loading, setLoading]     =   useState(false);
    const navigate                  =   useNavigate();
    
    const onAddon = (values) => {
        let payload = {
            ...values,
        };
        console.log(payload);
        
        setLoading(true)
        request({
            url: api.addEditMaster,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    return (
        <Card title={sectionName}>
            <Form className="edit-page-wrap" form={form} onFinish={onAddon} autoComplete="off" layout="verticle" name="master_form">
                
                <Row gutter={[24, 0]}>

                    <Col span={24} xs={24} sm={24} md={24}>
                        <Row gutter={[24, 0]}>
                            {languages.map((lang) => (
                                <Col span={8} key={lang.short}>
                                    <Form.Item
                                        className="qty-cls"
                                        name={`${lang.short}_name`}
                                        label={`Name (${lang.long})`}
                                        rules={[
                                            ...(lang.short === 'en' ? [
                                                { required: true, message: `Please enter the name in ${lang.long}!` }
                                            ] : []),
                                        ]}
                                    >
                                        <Input autoComplete="off" placeholder={`Enter Filter Name in ${lang.long}`} />
                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    
                    <Col span={24} xs={24} sm={24} md={24}>
                        <Form.List name="answer">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name }, index) => (
                                        <div key={key} style={{ marginBottom: '20px' }}>
                                            <div align="baseline" className="gap-cls">
                                            
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Divider orientation="left" orientationMargin={0} className="devider-color">
                                                        {`Filter Value - ${index + 1}`}
                                                        </Divider>
                                                    </Col>

                                                    <Col span={24}>
                                                        <Row gutter={16}>
                                                            {languages.map((lang) => (
                                                                <Col span={8} key={`${key}_${lang.short}`}>
                                                                    <Form.Item
                                                                        className="qty-cls"
                                                                        name={[name, `${lang.short}_answer`]}
                                                                        label={`Value (${lang.long})`}
                                                                        rules={[
                                                                            ...(lang.short === 'en' ? [
                                                                                { required: true, message: `Please enter the value in ${lang.long}!` }
                                                                            ] : []),
                                                                        ]}
                                                                    >
                                                                        <Input autoComplete="off" placeholder={`Enter Filter Value in ${lang.long}`} />
                                                                    </Form.Item>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Col>
                                                    
                                                    <Col span={24}>
                                                        {index > 0 && (
                                                            <div className="minus-wrap" style={{ marginBottom: '8px' }}>
                                                                <MinusCircleOutlined
                                                                    onClick={() => remove(index)}
                                                                    style={{ borderRadius: '8px' }}
                                                                />
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    ))}
                                     <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Filter Values
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                        
                <Form.Item className="btn-row float-right">
                    <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                    <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Add;