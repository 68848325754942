import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { AppContextProvider } from './context/AppContext';
import React, { useContext, Suspense } from 'react';
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import PrivateRoute from './components/PrivateRoute'
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Error from './pages/Error';

import User from './pages/User/Index';
import UserView from './pages/User/View';

import Content from './pages/Content/Index';
import ContentView from './pages/Content/View';
import ContentEdit from './pages/Content/Edit';

import State from './pages/State/Index';
import StateAdd from './pages/State/Add';
import StateView from './pages/State/View';
import StateEdit from './pages/State/Edit';
import StateAddon from './pages/State/Addon';
import StateDescription from './pages/State/Description';

import Country from './pages/Country/Index';
import CountryAdd from './pages/Country/Add';
import CountryEdit from './pages/Country/Edit';

import AppHome from './pages/AppHome/Index';

import Package from './pages/Package/Index';
import PackageAdd from './pages/Package/Add';
import PackageView from './pages/Package/View';
import PackageEdit from './pages/Package/Edit';

import Setting from './pages/Setting/Edit';
import Banner from './pages/Banner/Index';

import Blog from './pages/Blog/Index';
import BlogAdd from './pages/Blog/Add';
import BlogView from './pages/Blog/View';
import BlogEdit from './pages/Blog/Edit';

import Referral from './pages/Referral/Index';

import WalletTransaction from './pages/Wallet/Index';

import City from './pages/City/Index';
import CityAdd from './pages/City/Add';
import CityView from './pages/City/View';
import CityEdit from './pages/City/Edit';
import CityAddon from './pages/City/Addon';
import CityDescription from './pages/City/Description';

import ApplicationForm from './pages/ApplicationForm/Index';
import ApplicationFormAdd from './pages/ApplicationForm/Add';
import ApplicationFormEdit from './pages/ApplicationForm/Edit';

import Subscription from './pages/Subscription/Index';
import SubscriptionAdd from './pages/Subscription/Add';
import SubscriptionView from './pages/Subscription/View';
import SubscriptionEdit from './pages/Subscription/Edit';

import SubAdmin from './pages/SubAdmin/Index';
import SubAdminView from './pages/SubAdmin/View';
import SubAdminPermission from './pages/SubAdmin/Permission';

import Category from './pages/Category/Main/Index';
import CategoryAdd from './pages/Category/Main/Add';
import CategoryView from './pages/Category/Main/View';
import CategoryEdit from './pages/Category/Main/Edit';

import MasterFilter from './pages/MasterFilter/Index';
import MasterFilterAdd from './pages/MasterFilter/Add';
import MasterFilterEdit from './pages/MasterFilter/Edit';

import StayCategory from './pages/Stay/Category/Index';
import StayCategoryAdd from './pages/Stay/Category/Add';
import StayCategoryView from './pages/Stay/Category/View';
import StayCategoryEdit from './pages/Stay/Category/Edit';

import Hobbies from './pages/Master/Hobbies/Index';
import HobbiesAdd from './pages/Master/Hobbies/Add';
import HobbiesEdit from './pages/Master/Hobbies/Edit';

import Occupation from './pages/Master/Occupation/Index';
import OccupationAdd from './pages/Master/Occupation/Add';
import OccupationEdit from './pages/Master/Occupation/Edit';

import SubCategory from './pages/Category/Sub/Index';
import SubCategoryAdd from './pages/Category/Sub/Add';
import SubCategoryView from './pages/Category/Sub/View';
import SubCategoryEdit from './pages/Category/Sub/Edit';

import HashTag from './pages/HashTag/Index';

import LocationInsights from './pages/LocationInsights/Index';
import LocationInsightsAdd from './pages/LocationInsights/Add';
import LocationInsightsView from './pages/LocationInsights/View';
import LocationInsightsEdit from './pages/LocationInsights/Edit';
import LocationInsightsAddon from './pages/LocationInsights/Addon';
import LocationInsightsDescription from './pages/LocationInsights/Description';

import Activity from './pages/Activity/Index';
import ActivityAdd from './pages/Activity/Add';
import ActivityView from './pages/Activity/View';
import ActivityEdit from './pages/Activity/Edit';
import ActivityAddon from './pages/Activity/Addon';
import ActivityDescription from './pages/Activity/Description';

import ContactFeedback from './pages/ContactFeedback/Index';
import ContactFeedbackView from './pages/ContactFeedback/View';

import Language from './pages/Language/Index';

import ActivityCategory from './pages/Activity/Category/Index';
import ActivityCategoryAdd from './pages/Activity/Category/Add';
import ActivityCategoryEdit from './pages/Activity/Category/Edit';
import ActivityCategoryView from './pages/Activity/Category/View';

import EmailTemplate from './pages/EmailTemplate/Index';
import EmailTemplateView from './pages/EmailTemplate/View';
import EmailTemplateEdit from './pages/EmailTemplate/Edit';

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {

  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {

  const { isLoggedIn } = useContext(AuthContext)

  return (
    <Routes >
      <Route path="/login" element={<SignIn />} />
      {/* <Route path="/" element={<SignIn />} /> */}

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>}
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />

        {/* User Routes */}
        <Route exact path="/user" element={<User />} />
        <Route exact path="/user/view/:id" element={<UserView />} />

        {/* Content Routes */}
        <Route exact path="/content" element={<Content />} />
        <Route exact path="/content/update/:slug?" element={<ContentEdit />} />
        <Route exact path="/content/view/:slug" element={<ContentView />} />

        {/* Country Routes */}
        <Route exact path="/country" element={<Country />} />
        <Route exact path="/country/Add" element={<CountryAdd />} />
        <Route exact path="/country/update/:id" element={<CountryEdit />} />
        
        {/* App Home Routes */}
        <Route exact path="/app-home" element={<AppHome />} />
        
        {/* Language Routes */}
        <Route exact path="/language" element={<Language />} />
        
        {/* Master Routes */}
        <Route exact path="/master/hobbies" element={<Hobbies />} />
        <Route exact path="/master/hobbies/add" element={<HobbiesAdd />} />
        <Route exact path="/master/hobbies/update/:id" element={<HobbiesEdit />} />

        <Route exact path="/master/occupation" element={<Occupation />} />
        <Route exact path="/master/occupation/add" element={<OccupationAdd />} />
        <Route exact path="/master/occupation/update/:id" element={<OccupationEdit />} />

        {/* State Routes */}
        <Route exact path="/state" element={<State />} />
        <Route exact path="/state/Add" element={<StateAdd />} />
        <Route exact path="/state/update/:id" element={<StateEdit />} />
        <Route exact path="/state/view/:id" element={<StateView />} />
        <Route exact path="/state/add-on/:id" element={<StateAddon />} />
        <Route exact path="/state/description/:id" element={<StateDescription />} />

        {/* City Routes */}
        <Route exact path="/city" element={<City />} />
        <Route exact path="/city/Add" element={<CityAdd />} />
        <Route exact path="/city/update/:id" element={<CityEdit />} />
        <Route exact path="/city/view/:id" element={<CityView />} />
        <Route exact path="/city/add-on/:id" element={<CityAddon />} />
        <Route exact path="/city/description/:id" element={<CityDescription />} />

        {/* Package Routes */}
        <Route exact path="/package" element={<Package />} />
        <Route exact path="/package/Add" element={<PackageAdd />} />
        <Route exact path="/package/update/:id" element={<PackageEdit />} />
        <Route exact path="/package/view/:id" element={<PackageView />} />

        {/* Application Form Routes */}
        <Route exact path="/application-form" element={<ApplicationForm />} />
        <Route exact path="/application-form/Add" element={<ApplicationFormAdd />} />
        <Route exact path="/application-form/update/:id" element={<ApplicationFormEdit />} />

        {/* Subscription Routes */}
        <Route exact path="/subscription" element={<Subscription />} />
        <Route exact path="/subscription/Add" element={<SubscriptionAdd />} />
        <Route exact path="/subscription/update/:id" element={<SubscriptionEdit />} />
        <Route exact path="/subscription/view/:id" element={<SubscriptionView />} />

        {/* Sub Admin Routes */}
        <Route exact path="/sub-admin" element={<SubAdmin />} />
        <Route exact path="/sub-admin/view/:id" element={<SubAdminView />} />
        <Route exact path="/sub-admin/permission/:id" element={<SubAdminPermission />} />

        {/* EmailTemplate Routes */}
        <Route exact path="/email-template" element={<EmailTemplate />} />
        <Route exact path="/email-template/update/:id?" element={<EmailTemplateEdit />} />
        <Route exact path="/email-template/view/:id" element={<EmailTemplateView />} />

        {/* Category Routes */}
        <Route exact path="/category/main" element={<Category />} />
        <Route exact path="/category/main/Add" element={<CategoryAdd />} />
        <Route exact path="/category/main/update/:id" element={<CategoryEdit />} />
        <Route exact path="/category/main/view/:id" element={<CategoryView />} />

        {/* Sub Category Routes */}
        <Route exact path="/category/sub" element={<SubCategory />} />
        <Route exact path="/category/sub/Add" element={<SubCategoryAdd />} />
        <Route exact path="/category/sub/update/:id" element={<SubCategoryEdit />} />
        <Route exact path="/category/sub/view/:id" element={<SubCategoryView />} />

        {/* HashTag Routes */}
        <Route exact path="/hashtag" element={<HashTag />} />

        {/* Location Insights Routes */}
        <Route exact path="/location-insights" element={<LocationInsights />} />
        <Route exact path="/location-insights/Add" element={<LocationInsightsAdd />} />
        <Route exact path="/location-insights/update/:id" element={<LocationInsightsEdit />} />
        <Route exact path="/location-insights/view/:id" element={<LocationInsightsView />} />
        <Route exact path="/location-insights/add-on/:id" element={<LocationInsightsAddon />} />
        <Route exact path="/location-insights/description/:id" element={<LocationInsightsDescription />} />

        {/* Activity Routes */}
        <Route exact path="/activity/:category_id" element={<Activity />} />
        <Route exact path="/activity/Add/:category_id" element={<ActivityAdd />} />
        <Route exact path="/activity/update/:category_id/:id" element={<ActivityEdit />} />
        <Route exact path="/activity/view/:category_id/:id" element={<ActivityView />} />
        <Route exact path="/activity/add-on/:category_id/:id" element={<ActivityAddon />} />
        <Route exact path="/activity/description/:id" element={<ActivityDescription />} />

        {/* Activity Category Routes */}
        <Route exact path="/activity/category" element={<ActivityCategory />} />
        <Route exact path="/activity/category/Add" element={<ActivityCategoryAdd />} />
        <Route exact path="/activity/category/update/:id" element={<ActivityCategoryEdit />} />
        <Route exact path="/activity/category/view/:id" element={<ActivityCategoryView />} />

        {/* Contact Feedback Routes */}
        <Route exact path="/contact-feedback" element={<ContactFeedback />} />
        <Route exact path="/contact-feedback/view/:id" element={<ContactFeedbackView />} />

        {/* Setting Routes */}
        <Route exact path="/setting" element={<Setting />} />

        {/* Banner Routes */}
        <Route exact path="/banner" element={<Banner />} />

        {/* Blog Routes */}
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog/add" element={<BlogAdd />} />
        <Route exact path="/blog/update/:id" element={<BlogEdit />} />
        <Route exact path="/blog/view/:id" element={<BlogView />} />

        {/* Referral Reward Routes */}
        <Route exact path="/referral-reward" element={<Referral />} />

        {/* Wallet Transaction Routes */}
        <Route exact path="/transaction/wallet" element={<WalletTransaction />} />

        <Route exact path="/master-filter" element={<MasterFilter />} />
        <Route exact path="/master-filter/add" element={<MasterFilterAdd />} />
        <Route exact path="/master-filter/update/:id" element={<MasterFilterEdit />} />

        {/* Stay Manager Categories Routes */}
        <Route exact path="/stay/category" element={<StayCategory />} />
        <Route exact path="/stay/category/add" element={<StayCategoryAdd />} />
        <Route exact path="/stay/category/update/:id" element={<StayCategoryEdit />} />
        <Route exact path="/stay/category/view/:id" element={<StayCategoryView />} />


      </Route >

      <Route path="*" element={<Error />} />
    </Routes >
  )
}

const Layout = () => {
  return (<> <Outlet />  </>
  )
}

// function App() {
//   const { isLoggedIn } = useContext(AuthContext)
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes >
//           <Route path="/" element={(isLoggedIn) ? <Home /> : <Home />} />

//           <Route
//             path="/"
//             element={
//               <Layout />
//             }
//           >

//           </Route>
//           <Route exact path="/dashboard" element={<Home />} />
//           <Route exact path="/tables" element={<Tables />} />

//           {/* <Route exact path="/profile" component={Profile} /> */}
//           {/* <Redirect from="*" to="/dashboard" /> */}

//         </Routes>
//       </BrowserRouter>
//       {/* <Switch>

//       </Switch> */}
//     </div>
//   );
// }

// const Layout = () => {
//   return (
//     <Main> <Outlet />  </Main>
//   )
// }

export default App;