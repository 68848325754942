const shortLang = {
    en: "En",
    hi: "Hi",
    ka: "Ka",
    ma: "Ma",
    ml: "Ml",
    gj: "Gj",
    ta: "Ta",
    be: "Be",
    ur: "Ur",
    pn: "Pn",
    tl: "Tl",
    as: "As",
    np: "Np",
    dz: "Dz",
    ti: "Ti",
}

const longLang = {
    en: "English",
    hi: "Hindi",
    ka: "Kannada",
    ma: "Marathi",
    ml: "Malayalam",
    gj: "Gujrati",
    ta: "Tamil",
    be: "Bengali",
    ur: "Urdu",
    pn: "Punjabi",
    tl: "Telugu",
    as: "Assamese",
    np: "Nepali",
    dz: "Dzongkha",
    ti: "Tibetan",
}

const languages = [
    { short: 'en', long: 'English' },
    { short: 'hi', long: 'Hindi' },
    { short: 'ka', long: 'Kannada' },
    { short: 'ma', long: 'Marathi' },
    { short: 'ml', long: 'Malayalam' },
    { short: 'gj', long: 'Gujrati' },
    { short: 'ta', long: 'Tamil' },
    { short: 'be', long: 'Bengali' },
    { short: 'ur', long: 'Urdu' },
    { short: 'pn', long: 'Punjabi' },
    { short: 'tl', long: 'Telugu' },
    { short: 'as', long: 'Assamese' },
    { short: 'np', long: 'Nepali' },
    { short: 'dz', long: 'Dzongkha' },
    { short: 'ti', long: 'Tibetan' },
];

export default languages;