import { Row, Col, Card, Button, Skeleton, Image, Divider } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import apiPath from "../../../constants/apiPath";
import { Badge } from 'antd';
import languages from "../../../config/language";
import moment from "moment";
import notfound from "../../../assets/images/not_found.png";

function View() {

  const sectionName           =   "Category";
  const routeName             =   "activity/category";
  
  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    request({
      url       :   apiPath.viewCategory + "/" + id,
      method    :   'GET',
      onSuccess :   (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const LanguageDetails = ({ lang, list }) => (
    
    <Col md={12} className="mt-2">
      
      <Card title={lang.long} className="language-card" bordered={false} headStyle={{ color: 'red' }}>
        
        <div className="view-inner-cls">
          <h5>Name:</h5>
          <h6 className="cap">{list && list[`${lang.short}_name`] ? list[`${lang.short}_name`] : '-'}</h6>
        </div>
        
        <div className="view-inner-cls">
          <h5>Description:</h5>
          <h6 className="cap">{list && list[`${lang.short}_description`] ? <p dangerouslySetInnerHTML={{ __html: list[`${lang.short}_description`] }}></p> : '-'}</h6>
        </div>

      </Card>

    </Col>
  );

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <>
      <Card title={sectionName + " Details"}>
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>

            {loading ? <Skeleton active /> : 
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Image:</h5>
                  <h6><Image className="imagefix" width={80} height={80} src={list && list.image ? list.image : notfound} /></h6>
                </div>
            
                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                </div>

              </div>
            }

          </Col>
        </Row>
      </Card>

      <Row gutter={[16, 1]}>
        {languages.map(lang => (
          <LanguageDetails key={lang.short} lang={lang} list={list} />
        ))}
      </Row>
    </>
  );
}

export default View;
