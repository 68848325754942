import { Row, Col, Card, Button, Skeleton, Image, Divider, Tabs } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import languages from "../../config/language";
import moment from "moment";
import notfound from "../../assets/images/not_found.png";
const { TabPane } = Tabs;

function View() {

  const sectionName   =   "Activity";
  const routeName     =   "activity";
  
  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    request({
      url       :   apiPath.viewActivity + "/" + id,
      method    :   'GET',
      onSuccess :   (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <Card title={sectionName + " Details"}>
      <Row gutter={16}>
        <Col span={12} xs={24} md={24}>

          {loading ? <Skeleton active /> : (
            <div className="view-main-list">

              <Tabs defaultActiveKey="1">

                <TabPane tab="Basic Info" key="1">
                  
                  <div className="view-inner-cls">
                    <h5>Thumbnail:</h5>
                    <h6><Image width={80} height={80} src={list && list.thumbnail ? list.thumbnail : notfound} /></h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Multiple Images:</h5>
                    <h6>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        {list?.images?.map((item, index) => (
                          <Image key={index} width={80} height={80} src={item ?? notfound} alt={`Image ${index}`} />
                        ))}
                      </div>
                    </h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Content Type:</h5>
                    <h6 className="cap">{list && list.type ? list.type : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Status:</h5>
                    <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Created On:</h5>
                    <h6>{list.created_at ? moment(list.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                  </div>
                </TabPane>
                      
                <TabPane tab="Activity Content" key="4">
                 
                  {languages.map(lang => (
                    <>
                      <Divider orientation="left" orientationMargin={0} className="devider-color">{lang.long}</Divider>

                      <div className="view-inner-cls">
                        <h5>Name:</h5>
                        <h6 className="cap">{list && list[`${lang.short}_name`] ? list[`${lang.short}_name`] : '-'}</h6>
                      </div>

                      <div className="view-inner-cls">
                        <h5>Description:</h5>
                        <h6 className="cap">{list && list[`${lang.short}_description`] ? <p dangerouslySetInnerHTML={{__html: list[`${lang.short}_description`] }}></p> : '-'}</h6>
                      </div>
                    </>
                  ))}
                </TabPane>

                <TabPane tab={<span> Content <span className="cap">{list?.type}</span></span>} key="5">
                  {
                    list?.type == 'description' ?
                    <React.Fragment>
                    <Tabs defaultActiveKey={languages[0].short}>
                        <>
                          {languages.map(lang => (
                            <TabPane tab={lang.long} key={`${lang.short}`}>
                              {list?.content?.map((item, index) => (
                                <>
                                <Divider orientation="left" orientationMargin={0} className="devider-color">
                                  {`Description ${index + 1}`}
                                </Divider>

                              <div className="view-inner-cls">
                                <h5>Name:</h5>
                                <h6 className="cap">
                                  {item && item[`${lang.short}_name`] ? item[`${lang.short}_name`] : '-'}
                                </h6>
                              </div>
              
                              <div className="view-inner-cls">
                                <h5>Description:</h5>
                                <h6 className="cap">
                                  {item && item[`${lang.short}_description`] ? (
                                    <p dangerouslySetInnerHTML={{ __html: item[`${lang.short}_description`] }}></p>
                                  ) : '-'}
                                </h6>
                              </div>
                              </>
                              ))}
                            </TabPane>
                          ))}
                        </>
                    </Tabs>
                  </React.Fragment>

                    : list?.type == 'add-on' ?

                      <React.Fragment>
                        {list?.addon_field.map((addonField, addonIndex) => (
                          
                          <div key={addonIndex} style={{ marginBottom: '30px' }}>
                            <Divider orientation="left" orientationMargin={0} className="devider-color">
                              {`Main Field ${addonIndex + 1}`}
                            </Divider>
                            
                            {/* Main Field Headings */}
                            <div className="view-inner-cls">
                                <Row gutter={24} style={{ marginLeft : '4%' }}>
                                <Tabs defaultActiveKey={languages[0].short}>
                              {languages.map(lang => (
                                <TabPane tab={lang.long} key={`${lang.short}`}>
                                
                                <div key={lang.short}>
                               
                                    <h6 className="cap">{addonField[`${lang.short}_heading`] || '-'}</h6>
                                    
                                    
                                </div>
                              
                              </TabPane>
                              ))}
                              </Tabs>
                              </Row>
                            </div>

                            {/* Inside Fields */}
                            {addonField.inside_fields && addonField.inside_fields.map((insideField, insideIndex) => (
                              <div key={insideIndex} style={{ marginLeft: '10%', marginBottom: '20px', marginTop: '10px' }}>
                                <Divider orientation="left" orientationMargin={0} className="devider-color">
                                  {`Insider Field ${insideIndex + 1}`}
                                </Divider>
                                {insideField.image && (
                                      <div className="view-inner-cls">
                                        <h5>Image:</h5>
                                        <Image
                                          width={150}
                                          src={insideField.image}
                                          fallback="https://via.placeholder.com/200"
                                        />
                                      </div>
                                    )}
                                {languages.map(lang => (
                                  <div key={lang.short}>
                                    <Divider orientation="left" orientationMargin={0} className="devider-color">
                                      {lang.long}
                                    </Divider>
                                    
                                    <div className="view-inner-cls">
                                      <h5>Title:</h5>
                                      <h6 className="cap">{insideField[`${lang.short}_title`] || '-'}</h6>
                                    </div>

                                    <div className="view-inner-cls">
                                      <h5>Sub Title:</h5>
                                      <h6 className="cap">{insideField[`${lang.short}_sub_title`] || '-'}</h6>
                                    </div>
                                    
                                    
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ))}
                      </React.Fragment>
                    : null
                  }
                </TabPane>

                <TabPane tab="Categories" key="6">
                  {list?.categories?.map((category, index) => (
                    <div className="view-inner-cls">
                        <h5>Category & Sub Category : </h5>
                        <h6>{category?.category_id?.en_name} ({category?.sub_category_id?.en_name}) </h6>
                    </div>
                  ))}
                </TabPane>
              </Tabs>

              <div className="view-inner-cls float-right">
                <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
              </div>

            </div>
          )}

        </Col>
      </Row>
    </Card>
  );
  
}

export default View;