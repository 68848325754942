import {Row, Col, Card, Button, Input, Form, Divider, Tabs, Space, Skeleton} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import DescriptionEditor from '../../components/DescriptionEditor'
import languages from "../../config/language";
import { Link, useParams } from "react-router-dom";
const { TabPane } = Tabs;

function Description() {

    const sectionName   =   "State Description";
    const routeName     =   "state";

    const api = {
        addEditAddon    :  apiPath.addEditStateAddon,
        view            :  apiPath.viewState,
    }
    
    const [form]                            =   Form.useForm();
    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const params                            =   useParams();
    const navigate                          =   useNavigate();
    
    const onAddon = (values) => {
        let updatedValues   =   JSON.parse(JSON.stringify(values));
    
        let payload = {
            ...updatedValues,
            type : 'description',
        };
        
        setLoading(true)
        request({
            url: api.addEditAddon + "/" + params.id,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    const fetchData = (id) => {
        setLoading(true);
        request({
            url: api.view + "/" + id,
            method: 'GET',
            onSuccess: (response) => {
                const { data } = response;
               
                setTimeout(() => {
                    form.setFieldsValue({...data});
                    setLoading(false);
                }, 1);

            },
            onError: (error) => {
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    useEffect(() => {
        fetchData(params.id);
    }, [])

    return (
        <Card title={sectionName}>
            {loading ? [1,2,3,4,5,6,7,8].map(item => <Skeleton active key={item} />) :
                <Form className="edit-page-wrap" form={form} onFinish={onAddon} autoComplete="off" layout="verticle" name="blog_form">
                    
                    <Row gutter={[24, 0]}>

                        <Col span={24} xs={24} sm={24} md={24}>
                            <Form.List name="content">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={field.key}>
                                                <Space align="baseline" className="gap-cls">
                                                    <Row>
                                                        {index > 0 && (
                                                            <Divider orientation="left" orientationMargin={0} className="devider-color">
                                                            {`Content Description - ${index + 1}`}
                                                            </Divider>
                                                        )}

                                                        <Tabs defaultActiveKey={languages[0]?.short}>
                                                            {languages.map((lang) => (
                                                            <TabPane tab={lang.long} key={lang.short}>
                                                                <Form.Item
                                                                    label={`Name (${lang.long})`}
                                                                    name={[field.name, `${lang.short}_name`]}
                                                                    rules={[
                                                                        ...(lang.short === 'en' ? [
                                                                            { required: true, message: `Please enter the name in ${lang.long}!` }
                                                                        ] : []),
                                                                        { max: 200, message: 'Name should not contain more than 200 characters!' },
                                                                        { min: 2, message: 'Name should contain at least 2 characters!' },
                                                                    ]}
                                                                >
                                                                    <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`} />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    label={`Description (${lang.long})`}
                                                                    name={[field.name, `${lang.short}_description`]}
                                                                >
                                                                <DescriptionEditor
                                                                    placeholder={`Enter Description in ${lang.long}`}
                                                                />
                                                                </Form.Item>
                                                            </TabPane>
                                                            ))}
                                                        </Tabs>

                                                    {index > 0 && (
                                                        <div className="minus-wrap" style={{ marginBottom: '8px', marginLeft: '1px' }}>
                                                            <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: '8px' }} />
                                                        </div>
                                                    )}
                                                    </Row>
                                                </Space>
                                            </div>
                                        ))}

                                        <Col span={6}>
                                            <Form.Item className="mt-2" style={{ marginLeft: '-10px', width: '60%' }}>
                                                <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                            
                    <Form.Item className="btn-row float-right">
                        <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                    </Form.Item>
                </Form>
            }
        </Card>
    )
}

export default Description;