import {Row, Col, Card, Button, Input, Form, Space, Select} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { FORM_INPUT_DROPDOWN_DATA, FORM_INPUT_REQUIRED, FORM_INPUT_SHOW, FORM_INPUT_TYPE } from "../../constants/conts";
const { Option } = Select;

function Add() {

    const sectionName   =   "Application Form";
    const routeName     =   "application-form";
    
    const dropDownBox   =   ['single_select', 'multiple_select'];
    const valueBox      =   ['radio', 'checkbox'];

    const api = {
        addEdit   :  apiPath.addEditApplicationForm,
    }
    
    const [form]                            =   Form.useForm();
    const { request }                       =   useRequest()
    const [loading, setLoading]             =   useState(false)
    const navigate                          =   useNavigate();
    const [fieldTypes, setFieldTypes]       =   useState({});
    
    const handleInputTypeChange = (value, index) => {
        const newFieldTypes = { ...fieldTypes, [index]: value };
        setFieldTypes(newFieldTypes);
    };

    const onCreate = (values) => {
        let payload = {
            ...values,
        };

        setLoading(true)
        request({
            url: api.addEdit,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };

    return (
        <Card title={"Add " + sectionName}>
            
            <Form className="edit-page-wrap" form={form} onFinish={onCreate} autoComplete="off" layout="verticle" name="application_form">
                
                <Row gutter={[24, 0]}>

                    <Col span={12} xs={12} sm={12} md={10}>
                        <Form.Item name="screen_name" label="Screen Name" 
                            rules={[
                                { required: true, message: 'Please enter the screen name' },
                                { max: 200, message: "Screen name should not contain more than 200 characters!" },
                                { min: 2, message: "Screen name should contain at least 2 characters!" },
                            ]}
                        >

                            <Input autoComplete="off" placeholder={`Enter Screen Name`}/>
                           
                        </Form.Item>
                    </Col>

                    <Col key="add-on" md={24}>
                        <Form.List name={`addons`} className="mt-2">
                        {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Form.Item
                                            className="qty-cls"
                                            name={[field.name, 'type']}
                                            label="Select Input Type"
                                            rules={[{ required: true, message: 'Select input type' }]}
                                        >
                                            <Select
                                                placeholder="Select Input Type"
                                                onChange={(value) => handleInputTypeChange(value, index)}
                                            >
                                                {FORM_INPUT_TYPE.map((item, idx) => (
                                                    <Option key={idx} value={item.value}>
                                                        <span className="cap">{item.name}</span>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {valueBox.includes(fieldTypes[index]) && (
                                        <Col span={6}>
                                            <Form.Item
                                                className="qty-cls"
                                                name={[field.name, 'value']}
                                                label="Value"
                                                rules={[{ required: true, message: 'Please enter value' }]}
                                            >
                                                <Input autoComplete="off" placeholder="Enter Value" />
                                            </Form.Item>
                                        </Col>
                                    )}

                                    {dropDownBox.includes(fieldTypes[index]) && (
                                        <Col span={6}>
                                            <Form.Item
                                                className="qty-cls"
                                                name={[field.name, 'dropdown_data']}
                                                label="Select Dropdown Data"
                                                rules={[{ required: true, message: 'Select dropdown data' }]}
                                            >
                                                <Select placeholder="Select DropDown Data">
                                                    {FORM_INPUT_DROPDOWN_DATA.map((item, idx) => (
                                                        <Option key={idx} value={item.value}>
                                                            <span className="cap">{item.name}</span>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    )}

                                    <Col span={4}>
                                        <Form.Item
                                            className="qty-cls"
                                            name={[field.name, 'required']}
                                            label="Select Required"
                                            rules={[{ required: true, message: 'Select required' }]}
                                        >
                                            <Select placeholder="Select Required">
                                                {FORM_INPUT_REQUIRED.map((item, idx) => (
                                                    <Option key={idx} value={item.value}>
                                                        <span>{item.name}</span>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                        <Form.Item
                                            className="qty-cls"
                                            name={[field.name, 'show']}
                                            label="Select Show"
                                            rules={[{ required: true, message: 'Select show' }]}
                                        >
                                            <Select placeholder="Select Show">
                                                {FORM_INPUT_SHOW.map((item, idx) => (
                                                    <Option key={idx} value={item.value}>
                                                        <span>{item.name}</span>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {index > 0 && (
                                        <div className="minus-wrap" style={{ marginLeft: '11px' }}>
                                            <MinusCircleOutlined onClick={() => remove(field.name)} style={{marginTop: '23px', borderRadius: '8px' }} />
                                        </div>
                                    )}
                                </Row>
                            </div>
                        ))}

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Field
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
                    </Col>
                
                </Row>
                        
                <Form.Item className="btn-row float-right">
                    <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                    <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Add;
