import { toast, Slide, Zoom, Bounce, Flip } from "react-toastify";

export const Severty = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
}

const POSITION = "top-center";
const AUTO_CLOSE = 2000;
const THEME = "dark";

export const ShowToast = (message, type) => {

    switch (type) {
        case 'success': 
            toast.success(message, {
                position        :   POSITION,
                autoClose       :   AUTO_CLOSE,
                hideProgressBar :   false,
                closeOnClick    :   false,
                pauseOnHover    :   true,
                draggable       :   true,
                progress        :   undefined,
                theme           :   THEME,
                newestOnTop     :   true,
                transition      :   Zoom,
            });
        break;

        case 'error':
            toast.error(message, {
                position        :   POSITION,
                autoClose       :   AUTO_CLOSE,
                hideProgressBar :   false,
                closeOnClick    :   false,
                pauseOnHover    :   true,
                draggable       :   true,
                progress        :   undefined,
                theme           :   THEME,
                newestOnTop     :   true,
                transition      :   Flip,
            });
        break;

        case 'warning':
            toast.warning(message, {
                position        :   POSITION,
                autoClose       :   AUTO_CLOSE,
                hideProgressBar :   false,
                closeOnClick    :   false,
                pauseOnHover    :   true,
                draggable       :   true,
                progress        :   undefined,
                theme           :   THEME,
                newestOnTop     :   true,
                transition      :   Bounce,
            });
        break;
        case 'info':
            toast.info(message, {
                position        :   POSITION,
                autoClose       :   AUTO_CLOSE,
                hideProgressBar :   false,
                closeOnClick    :   false,
                pauseOnHover    :   true,
                draggable       :   true,
                progress        :   undefined,
                theme           :   THEME,
                newestOnTop     :   true,
                transition      :   Slide,
            });
        break;
        default:
        break;
    }
}