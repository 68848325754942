import { Row, Col, Card, Button, Skeleton, Tabs, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import languages from "../../config/language";
import moment from "moment";
const { TabPane } = Tabs;

function View() {

  const sectionName   =   "Content";
  const routeName     =   "content";

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (slug) => {
    request({
      url: apiPath.viewContent + "/" + slug,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.slug)
  }, [])

  return (
    <Card title={sectionName + " Details"}>
      <Row gutter={16}>
        <Col span={12} xs={24} md={24}>

          {loading ? <Skeleton active /> : 
            <div className="view-main-list">

                {
                  list.slug != 'faq' ?
                    <>
                      {languages.map(lang => (
                        <>
                        <Divider orientation="left" orientationMargin={0} className="devider-color">{lang.long}</Divider>
    
                        <div className="view-inner-cls">
                            <h5>Title:</h5>
                            <h6 className="cap">{list && list[`${lang.short}_title`] ? list[`${lang.short}_title`] : '-'}</h6>
                        </div>
    
                        <div className="view-inner-cls">
                            <h5>Description:</h5>
                            <h6 className="cap">{list && list[`${lang.short}_description`] ? <p dangerouslySetInnerHTML={{__html: list[`${lang.short}_description`] }}></p> : '-'}</h6>
                        </div>
                        </>
                      ))}
                    </>
                  :
                  
                  <div className="view-inner-cls">
                    <Row gutter={16}>
                      {list.faq?.map((item, index) => (
                        <Col key={item._id} md={24} style={{ marginBottom: '16px' }}>
                          
                          <Divider orientation="left" orientationMargin={0} className="devider-color">
                            {`Question & Answer ${index + 1}`}
                          </Divider>

                          <Tabs defaultActiveKey={languages[0]?.short}>
                            {languages.map(lang => (
                              <TabPane tab={lang.long} key={lang.short}>
                                <div className="view-inner-cls">
                                  <h5>Question:</h5>
                                  <h6 className="cap"> {item[`${lang.short}_question`] ?? '-'} </h6>
                                </div>

                                <div className="view-inner-cls">
                                  <h5>Answer:</h5>
                                  <h6 className="cap"> {item[`${lang.short}_answer`] ?? '-'} </h6>
                                </div>
                              </TabPane>
                            ))}
                          </Tabs>
                        </Col>
                      ))}
                    </Row>
                  </div>
                }

                <div className="view-inner-cls">
                    <h5>Status:</h5>
                    <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                    <h5>Created On:</h5>
                    <h6>{list.created_at ? moment(list.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                    <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
                </div>

            </div>
          }

        </Col>
      </Row>
    </Card>
  );
  
}

export default View;