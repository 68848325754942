import {Row, Col, Card, Button, Input, Form, Tabs, Skeleton, Badge} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../../constants/apiPath";
import languages from "../../../config/language";
import { Link, useParams } from "react-router-dom";
const { TabPane } = Tabs;

function Edit() {

  const sectionName   =   "Occupation";
  const routeName     =   "master/occupation";

  const api = {
    addEdit   :  apiPath.addEditOccupation,
    view      :  apiPath.viewOccupation,
  }
  
  const [form]                            =   Form.useForm();
  const { request }                       =   useRequest()
  const [loading, setLoading]             =   useState(false)
  const params                            =   useParams();
  const navigate                          =   useNavigate();
  const [validationStatus, setValidationStatus] = useState({});

  const handleFieldChange = (data, lang, type) => {
    const name                  =   form.getFieldValue(`${lang}_name`);
    setValidationStatus(prevStatus => ({
      ...prevStatus, 
      [lang]: !!name
    }));
  }


  const onUpdate = (values) => {
    let payload = {
      ...values,
    };

    setLoading(true)
    request({
      url: api.addEdit + "/" + params.id,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          navigate(`/${routeName}`);
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  const fetchData = (id) => {
    request({
      url: api.view + "/" + id,
      method: 'GET',
      onSuccess: (response) => {
        const { data } = response;
        form.setFieldsValue({...data});
        setLoading(false);

        // Initialize validationStatus based on fetched data
        const fetchedValidationStatus = {};
              
        languages.forEach(lang => {
          const name                  =   form.getFieldValue(`${lang.short}_name`);
          fetchedValidationStatus[lang.short] = !!name;
        });

        setValidationStatus(fetchedValidationStatus);

      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    const initialValidationStatus = {};
    
    languages.forEach(lang => {
        initialValidationStatus[lang.short] = !!form.getFieldValue(`${lang.short}_name`) && !!form.getFieldValue(`${lang.short}_description`);
    });

    setValidationStatus(initialValidationStatus);
    
    fetchData(params.id);

    setLoading(true);
    
  }, [])

  return (
    <Card title={"Update " + sectionName}>
      {loading ? [1].map(item => <Skeleton active key={item} />) :

        <Form className="edit-page-wrap" form={form} onFinish={onUpdate} autoComplete="off" layout="verticle" name="occupation_form">
            
          <Row gutter={[24, 0]}>
            <Col md={24}>
              <Tabs defaultActiveKey={languages[0].short}>
                
                {languages.map(lang => (
                  
                  <TabPane tab={<span>{lang.long} {validationStatus[lang.short] === false && <Badge dot color="red" />}</span>} key={lang.short}>
                    
                    <Form.Item label={`Name (${lang.long})`} name={`${lang.short}_name`} 
                      rules={[
                        ...(lang.short === 'en' ? [
                          { required: true, message: `Please enter the name in ${lang.long}!` }
                        ] : []),
                        { max: 200, message: "Name should not contain more than 200 characters!" },
                        { min: 2, message: "Name should contain at least 2 characters!" },
                      ]}
                      onChange={(data) => handleFieldChange(data, lang.short, 'title')}
                    >
                      <Input autoComplete="off" placeholder={`Enter name in ${lang.long}`}/>
                    </Form.Item>
                
                    {/* <Form.Item label={`Description (${lang.long})`} name={`${lang.short}_description`}>
                      <DescriptionEditor placeholder={`Enter Description in ${lang.long}`} />
                    </Form.Item> */}
                  </TabPane>
                ))}
              </Tabs>
            </Col>
          </Row>
            
          <Form.Item className="btn-row float-right">
            <Link className="ant-btn ant-btn-secondary" to={`/${routeName}/`}><i class="fas fa-arrow-left mr-5"></i> Back</Link>
            <Button type="primary" loading={loading} htmlType="submit"><i class="fas fa-check mr-5"></i> Submit</Button>
          </Form.Item>
        </Form>
      }
    </Card>
  )
}

export default Edit;